import { createTheme, ThemeOptions } from "@mui/material";

// Shared theme settings
const baseThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '1.7rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '1.3rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.2rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: '1.1rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '1rem', // 16px
      fontWeight: 600,
      lineHeight: 1.2,
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true, // Disable hover and focus interactivity globally
      },
      styleOverrides: {
        tooltip: ({ theme }) => ({
          border: '3px solid',
          borderColor: theme.palette.primary.main,
          fontSize: '1rem',
          padding: '0.5rem 1rem',
          borderRadius: theme.shape.borderRadius,
        }),
      }
    },
  }
};

// Light theme settings
export const lightTheme = createTheme({
  ...baseThemeOptions,
  palette: {
    primary: {
      light: '#5FB4E3',
      main: '#4888BB',
      dark: '#365783',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#E3815F',
      main: '#BB6848',
      dark: '#834836',
      contrastText: '#ffffff',
    },
    error: {
      light: '#EF6F6F',
      main: '#D84848',
      dark: '#B83636',
      contrastText: '#ffffff',
    },
    warning: {
      light: '#FFB547',
      main: '#F19E2C',
      dark: '#D68A1C',
      contrastText: '#ffffff',
    },
    info: {
      light: '#5FE3D0',
      main: '#48BBAA',
      dark: '#368374',
      contrastText: '#ffffff',
    },
    success: {
      light: '#7BC698',
      main: '#5BA97A',
      dark: '#3E8A5B',
      contrastText: '#ffffff',
    },
    grey: {
      50: '#F8FAFC',
      100: '#EEF2F6',
      200: '#E3E8EF',
      300: '#CDD5DF',
      400: '#9AA4B2',
      500: '#697586',
      600: '#4B5565',
      700: '#364152',
      800: '#202939',
      900: '#121926',
      A100: '#F8FAFC',
      A200: '#EEF2F6',
      A400: '#697586',
      A700: '#364152',
    },
    text: {
      primary: '#202939', // grey.800
      secondary: '#4B5565', // grey.600
      disabled: '#697586', // grey.500
    },
    background: {
      default: '#F8FAFC', // grey.50
      paper: '#ffffff',
    },
    divider: '#E3E8EF', // grey.200
  }
});

// Dark theme settings
export const darkTheme = createTheme({
  ...baseThemeOptions,
  palette: {
    mode: 'dark', // Ensure that MUI understands this is a dark theme
    primary: {
      light: '#7BC2E9',
      main: '#5FA6D6',
      dark: '#4888BB',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#E9A17B',
      main: '#D68A5F',
      dark: '#BB6848',
      contrastText: '#ffffff',
    },
    error: {
      light: '#EF6F6F',
      main: '#D84848',
      dark: '#B83636',
      contrastText: '#ffffff',
    },
    warning: {
      light: '#FFB547',
      main: '#F19E2C',
      dark: '#D68A1C',
      contrastText: '#ffffff',
    },
    info: {
      light: '#5FE3D0',
      main: '#48BBAA',
      dark: '#368374',
      contrastText: '#ffffff',
    },
    success: {
      light: '#7BC698',
      main: '#5BA97A',
      dark: '#3E8A5B',
      contrastText: '#ffffff',
    },
    grey: {
      50: '#1E293B',
      100: '#293548',
      200: '#3C4859',
      300: '#4B5565',
      400: '#5A6473',
      500: '#6B7684',
      600: '#AAB3BE',
      700: '#D3DAE2',
      800: '#E8EEF2',
      900: '#F8FAFC',
    },
    text: {
      primary: '#E8EEF2', // Bright text for dark backgrounds
      secondary: '#AAB3BE', // Muted but legible
      disabled: '#6B7684', // Still visible but less prominent
    },
    background: {
      default: '#121926', // Very dark background
      paper: '#1E293B', // Slightly lighter for contrast
    },
    divider: '#4B5565', // More visible divider
    action: {
      active: '#E8EEF2', // Default color for icons, buttons, etc.
      hover: '#3C4859',
      selected: '#365783',
      disabled: '#6B7684',
      disabledBackground: '#293548',
    },
  },
  components: {
    ...baseThemeOptions.components,
    MuiTooltip: {
      ...baseThemeOptions.components?.MuiTooltip,
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          border: '3px solid',
          borderColor: theme.palette.primary.main,
          color: theme.palette.text.primary,
          fontSize: '1rem',
          padding: '0.5rem 1rem',
          borderRadius: theme.shape.borderRadius,
        }),
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Subtle shadow
        },
      },
    },
  },
});