import { useState, MouseEvent } from "react";
import { Button, Grid2 as Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { Copy, EyeOff, GripVertical, MenuIcon, Star, Trash, Users, Zap } from "lucide-react";
import { useTranslation } from "react-i18next";
import { TeamRes, TemplateRes } from "../interfaces/api";
import RunIcon from "../components/Icons/RunIcon";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import Link from "../components/Link";
import ConfirmDialog from "../components/ConfirmDialog";
import { useMutationErrorHandler } from "../hooks/useErrorHandler";
import { useMixpanel } from "../utils/mixpanel";
import { useDuplicateTemplate, useTemplate } from "../hooks/useTemplate";
import { useTeam } from "../hooks/useTeam";
import { generateTemplateID } from "../utils/ids";
import { useNavigate } from "react-router";

interface Props {
	template: TemplateRes;
	team: TeamRes;
}
export default function TemplateSortableItem({ template, team }: Props) {
	const { t } = useTranslation();
	const handleError = useMutationErrorHandler();
	const mixpanel = useMixpanel();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [deleteOpen, setDeleteOpen] = useState(false);

	const { mutations: templateMutations } = useTemplate(template.id);
	const { mutations: teamMutations } = useTeam(team.id);
	const duplicateTemplate = useDuplicateTemplate();

	const isFavorite = team.favorites.includes(template.id)
	const isShareTeam = template.visibility.indexOf(template.owner.team) > -1

	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: template.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		opacity: isDragging ? 0 : 1,
	};

	const handleFavorite = () => {
		const newFavorites = isFavorite ? team.favorites.filter(id => id !== template.id) : [...team.favorites, template.id]
		teamMutations.update.mutate({ favorites: newFavorites }, handleError('Failed to update team favorites'))
		mixpanel.track('template', {
			action: isFavorite ? 'unfavorite' : 'favorite',
			template: template.id,
			team: team.id,
		})
	}

	const handleMenuOpen = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
	const handleMenuClose = () => setAnchorEl(null);

	const handleDuplicate = () => {
		const id = generateTemplateID();
		duplicateTemplate.mutate({
			template: template.id,
			id,
			name: `${template.name} copy`,
			view: template.view,
			inclRecipes: false,
			folderId: template.folder.id !== "" ? template.folder.id : undefined,
		}, {
			onSuccess: () => {
				navigate(`/templates/${id}`);
			},
			// onError: (error) => handleError('Failed to update template.')
		});
	}

	const handleSchedule = () => {
		console.log("Schedule clicked for template: " + template.id)
	}

	const handleDeleteOpen = () => {
		setDeleteOpen(true)
		setAnchorEl(null)
	}

	const handleDeleteTemplate = () => {
		templateMutations.delete.mutate(template.path), handleError('Failed to delete template.')
		setDeleteOpen(false)
	}
	const handleDeleteClose = () => setDeleteOpen(false)

	return (
		<Grid container size={12} ref={setNodeRef} style={style} sx={{
			position: 'relative',
			zIndex: isDragging ? 1 : 'auto',
		}}>
			<Grid size={2} sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
				<IconButton size="small" sx={{ cursor: 'grab' }}
					{...attributes}
					{...listeners}
				>
					<GripVertical />
				</IconButton>
				<Tooltip title={isFavorite ? t('common.unfavorite') : t('common.favorite')}>
					<IconButton onClick={handleFavorite} sx={{
						mr: 1,
						color: isFavorite ? 'primary.main' : 'text.secondary',
						'&:hover': {
							color: 'primary.main',
						},
					}}					>
						<Star fill={isFavorite ? 'currentColor' : 'none'} />
					</IconButton>
				</Tooltip>
				{isShareTeam ? <Tooltip title={t('common.team')}><Users /></Tooltip> : <Tooltip title={t('common.private')}><EyeOff /></Tooltip>}
			</Grid>
			<Grid size={5} sx={{ display: 'flex', alignItems: 'center' }}>
				<Link to={`/templates/${template.id}`}><Typography data-cy='template-name'>{template.name}</Typography></Link>
			</Grid>
			<Grid size={2} sx={{ mt: 1 }}>{new Date(template.timestamp).toLocaleDateString()}</Grid>
			<Grid size={1}><Button href={`https://app2.checklist.com/orgs/${team.org}/teams/${team.id}/search?template=${template.id}`} target="_blank">{t('common.view')}</Button></Grid>
			<Grid size={2} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 3 }}>
				<Tooltip title={t('templates.trigger')}>
					<IconButton size="small">
						<RunIcon />
					</IconButton>
				</Tooltip>
				<IconButton edge="start"
					color="inherit"
					aria-label="menu"
					onClick={handleMenuOpen}>
					<MenuIcon size={20} />
				</IconButton>
			</Grid>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleMenuClose}
			>
				<MenuItem onClick={handleSchedule}>
					<ListItemIcon>
						<Zap size={20} />
					</ListItemIcon>
					<ListItemText primary={t('common.schedule')} />
				</MenuItem>
				<MenuItem onClick={handleDuplicate}>
					<ListItemIcon>
						<Copy size={20} />
					</ListItemIcon>
					<ListItemText primary={t('common.duplicate')} />
				</MenuItem>
				<MenuItem onClick={handleDeleteOpen}>
					<ListItemIcon>
						<Trash size={20} />
					</ListItemIcon>
					<ListItemText primary={t('common.delete')} />
				</MenuItem>
			</Menu>
			{
				deleteOpen && <ConfirmDialog
					open
					title={t('templates.deleteTemplate')}
					description={t('templates.deleteConfirmation')}
					confirmationText={t('common.delete')}
					cancellationText={t('common.cancel')}
					onCancel={handleDeleteClose}
					onConfirm={handleDeleteTemplate}
				/>
			}
		</Grid >
	)
}