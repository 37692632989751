import { Box, FormControlLabel, IconButton, Stack, Switch, Tooltip, Typography } from "@mui/material";
import { TemplateRes } from "../interfaces/api";
import { IMeta, ITask, TaskTypes } from "../interfaces/IChecklist";
import TemplateAddTask from "./TemplateAddTask";
import { useState } from "react";
import { ITemplateMutations } from "../hooks/useTemplate";
import { generateTaskID } from "../utils/ids";
import { findNewPosition, getInitialMeta, getParentPath, getPathLevel, getPositionWithinParent } from "../utils/checklist.utils";
import TemplateTasks from "./TemplateTasks";
import EditableText from "../components/Input/EditableText";
import { useMutationErrorHandler } from "../hooks/useErrorHandler";
import { useMixpanel } from "../utils/mixpanel";
import { useTranslation } from 'react-i18next';
import { stringToTaskType } from "../utils/taskTypes.utils";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router";

interface Props {
	template: TemplateRes;
	cursor: ITask | null;
	setCursor: (id: string | null) => void;
	mutations: ITemplateMutations;
}
export default function TemplateMain({ template, cursor, setCursor, mutations }: Props) {
	const handleError = useMutationErrorHandler();
	const mixpanel = useMixpanel();
	const { t } = useTranslation();
	const navigation = useNavigate();
	
	const [currentType, setCurrentType] = useState('check');
	const [showNotes, setShowNotes] = useState(false);
	const [isMulti, setIsMulti] = useState(false);

	const canHaveTypes = template.view !== '*CHECKLIST'

	const handleCursor = (task: string | null) => {
		if (cursor === task)
			setCursor(null);
		else
			setCursor(task);
	}

	const handleCurrentType = (type: string) => setCurrentType(type)

	const handleAddTasks = (names: string[]) => {
		if (!names.length) return;
		// const { absolute: position } = cursor ? calcNextPosition(cursor.id, template.path, cursor?.path, template.tasks) : { absolute: 0 };
		const parentPath = cursor ? getParentPath(cursor.path) : template.path;
		const position = cursor ? getPositionWithinParent(template.tasks, parentPath, cursor.path) + 1 : 0;
		const absolutePosition = findNewPosition(template.tasks, parentPath, position);
		const now = new Date().toISOString();
		const meta: IMeta[] = canHaveTypes ? [getInitialMeta(stringToTaskType(currentType) || TaskTypes.Check)] : []
		const tasks: ITask[] = names.map((name) => {
			const id = generateTaskID();
			return ({
				id,
				name,
				// type: currentType, // we do not use this! 
				path: `${parentPath}/${id}`,
				created: now,
				timestamp: now,
				status: 0,
				meta,
				level: cursor ? getPathLevel(cursor.path) : 0,
			})
		})
		setCursor(tasks[tasks.length - 1].id); // set the cursor to the last new task
		mutations.addTasks.mutate({
			position,
			tasks,
			absolutePosition,
		}, handleError('Failed to add task'))
		mixpanel.track('template', {
			action: 'add-task',
			template: template.id,
			type: currentType,
			size: tasks.length,
		})
	}

	const handleNotesUpdate = (value: string) => {
		mutations.updateField.mutate({
			field: 'notes',
			value,
		}, handleError('Failed to update notes'));
		mixpanel.track('template', {
			action: 'task-notes',
			template: template.id,
		})
	}

	const handleToggleShowNotes = () => {
		setShowNotes(!showNotes);
		mixpanel.track('template', {
			action: 'toggle-notes',
			template: template.id,
			show: !showNotes,
		})
	}

	const templateNameUpdate = (value: string) => {
		if (value.trim() && value !== template.name) {
			mutations.updateField.mutate({
				field: 'name',
				value: value
			}, handleError(t('templateEditor.updateNameError')))
		} else {
			// how to discard the value if empty?
		}
	}

	const handleBack = () => {
		mixpanel.track('template', {
			action: 'back',
			template: template?.id,
		})
		navigation(`/templates`)
	};

	const handleToggleMulti = () => setIsMulti(!isMulti);

	return (
		<Stack spacing={2}>
			<Stack spacing={2} direction='row'>
				<Tooltip title={t('header.backToTemplates')}>
					<IconButton sx={{ color: 'text.secondary' }} onClick={handleBack} data-cy="editor-back">
						<ChevronLeft />
					</IconButton>
				</Tooltip>
				<EditableText dataCy="template-name" variant="h1" value={template.name} onChange={templateNameUpdate} allowEmpty={false} />
				<FormControlLabel
					control={
						<Switch
							data-cy="show-notes-toggle"
							checked={showNotes}
							onChange={handleToggleShowNotes}
							color="primary"
							size="small"
						/>
					}
					label={<Typography variant="body2">{t('templateEditor.showNotes')}</Typography>}
					style={{ whiteSpace: 'nowrap' }}
				/>
			</Stack>
			<Box sx={{
				position: 'sticky',
				top: '70px',
				zIndex: 1,
				backgroundColor: 'background.default',
				paddingY: 2,
			}}>
				<TemplateAddTask selectedType={currentType} onTypeChange={handleCurrentType} isMulti={isMulti} canHaveTypes={canHaveTypes} toggleMulti={handleToggleMulti} onSuccess={handleAddTasks} />
			</Box>
			<EditableText
				allowEmpty
				multiline
				placeholder={t('templateEditor.templateDescriptionPlaceholder')}
				value={template.notes || ""}
				onChange={handleNotesUpdate}
			/>
			<TemplateTasks
				tasks={template.tasks}
				cursor={cursor}
				setCursor={handleCursor}
				showNotes={showNotes}
				disableTypes={!canHaveTypes}
				mutations={mutations} />
			{template.tasks.length > 5 && <Typography variant='body1' color='text.secondary' sx={{ pt: 2 }}>
				{t('templateEditor.totalTasks', { count: template.tasks.length })}
			</Typography>}
		</Stack >
	)
}