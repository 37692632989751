import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

interface Props {
	title: string,
	description: string,
	confirmationText: string,
	cancellationText: string,
	open: boolean,
	options?: any,
	onCancel: any,
	onConfirm: any,
	dialogProps?: any,
	confirmationButtonProps?: any,
	cancellationButtonProps?: any,
}

const ConfirmDialog = (props: Props) => {
	const {
		open,
		title,
		description,
		confirmationText,
		cancellationText,
		dialogProps,
		confirmationButtonProps,
		cancellationButtonProps,
		onCancel,
		onConfirm,
	} = props

	return (
		<Dialog fullWidth {...dialogProps} open={open} onClose={onCancel}>
			{title && (
				<DialogTitle>{title}</DialogTitle>
			)}
			{description && (
				<DialogContent>
					<DialogContentText>{description}</DialogContentText>
				</DialogContent>
			)}
			<DialogActions>
				<Button {...cancellationButtonProps} onClick={onCancel}>
					{cancellationText}
				</Button>
				<Button color="primary" {...confirmationButtonProps} onClick={onConfirm}>
					{confirmationText}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmDialog
