import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { ChevronDown } from 'lucide-react';
import { createElement, useState } from 'react';
import { getTaskTypeFromString, ITaskType, TASK_TYPES_ARRAY } from '../utils/taskTypes.utils';

interface TaskTypeSelectorProps {
	type: string
	text?: boolean; // show the text on the button
	drop?: boolean; // show the drop down on the button
	onTypeChange: (type: string) => void;
}

export const TaskTypeSelector: React.FC<TaskTypeSelectorProps> = ({
	type,
	drop,
	text,
	onTypeChange,
}) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const selected: ITaskType = getTaskTypeFromString(type)

	const handleClick = (type: ITaskType) => () => {
		onTypeChange(type.id);
		setAnchorEl(null);
	}
	return (
		<>
			<Button
				data-cy="task-type-selector"
				data-state={selected.id}
				tabIndex={-1}
				onClick={(e) => setAnchorEl(e.currentTarget)}
				variant="outlined"
				aria-label={t('templateEditor.changeTaskType')}
				sx={{
					justifyContent: 'space-between',
					padding: drop || text ? '6px 16px' : '6px',
					minWidth: text ? '175px' : 'auto',
					height: '40px',
				}}
			>
				<Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%', justifyContent: 'space-between' }}>
					<Stack direction="row" spacing={1} alignItems="center">
						{createElement(selected.icon, { size: 18, color: selected.color })}
						{text && <Typography noWrap>{selected.name}</Typography>}
					</Stack>
					{drop && <ChevronDown size={18} />}
				</Stack>
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
			>
				{TASK_TYPES_ARRAY.map(t => (
					<MenuItem
						key={t.id}
						data-cy={`task-type-${t.id}`}
						onClick={handleClick(t)}
						selected={t.id === selected.id}
						sx={{ color: t.color }}
					>
						<Stack direction="row" spacing={1.5} alignItems="center">
							{createElement(t.icon, { size: 18 })}
							<Typography>{t.name}</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
