import React from 'react';

interface LoaderProps {
  isLoading?: boolean;
  size?: number;
  color?: string;
  backgroundColor?: string;
  duration?: number;
}

const Loader: React.FC<LoaderProps> = ({
  isLoading = true,
  size = 100,
  color = '#03A9F4',
  backgroundColor = '#E3F2FD',
  duration = 2,
}) => {
  if (!isLoading) {
    return <div />;
  }

  const keyframes = `
    @keyframes progressAnimation {
      0% { transform: translateY(820px); }
      100% { transform: translateY(0px); }
    }
  `;

  const containerStyle: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  };

  const loaderStyle: React.CSSProperties = {
    display: 'inline-block',
    width: size,
    height: size,
  };

  return (
    <div style={containerStyle}>
      <div style={loaderStyle}>
        <svg
          viewBox="0 0 819.2 819.2"
          width={size}
          height={size}
          aria-label="Loading..."
          role="progressbar"
        >
          <defs>
            <clipPath id="progress-clip">
              <rect
                x="0"
                y="-1"
                width="819.2"
                height="820"
                style={{
                  animation: `progressAnimation ${duration}s linear infinite`,
                }}
              />
            </clipPath>
          </defs>

          {/* Static background shape */}
          <path
            fill={backgroundColor}
            d="M66.585 -1.183L753.257 -1.183C769.193 1.633 782.953 10.657 795.929 19.873C689.257 138.993 584.489 259.857 477.721 378.897C428.121 334.177 378.793 289.169 328.681 245.025C292.969 285.233 257.641 325.793 221.801 365.889C311.257 446.977 401.785 526.897 491.769 607.393C507.193 591.713 521.529 575.025 535.993 558.497C630.505 451.585 724.841 344.513 819.513 237.761L819.513 751.745C816.041 766.337 810.553 781.041 799.801 791.905C788.169 806.033 770.793 814.129 753.225 818.017L66.585 818.017C51.993 814.545 37.273 809.057 26.425 798.305C12.297 786.673 4.201 769.297 0.313 751.729L0.313 65.057C2.905 52.049 8.873 40.033 16.201 29.105C28.281 12.817 47.049 3.097 66.585 -1.183Z"
          />

          {/* Progress fill shape */}
          <path
            fill={color}
            clipPath="url(#progress-clip)"
            d="M66.585 -1.183L753.257 -1.183C769.193 1.633 782.953 10.657 795.929 19.873C689.257 138.993 584.489 259.857 477.721 378.897C428.121 334.177 378.793 289.169 328.681 245.025C292.969 285.233 257.641 325.793 221.801 365.889C311.257 446.977 401.785 526.897 491.769 607.393C507.193 591.713 521.529 575.025 535.993 558.497C630.505 451.585 724.841 344.513 819.513 237.761L819.513 751.745C816.041 766.337 810.553 781.041 799.801 791.905C788.169 806.033 770.793 814.129 753.225 818.017L66.585 818.017C51.993 814.545 37.273 809.057 26.425 798.305C12.297 786.673 4.201 769.297 0.313 751.729L0.313 65.057C2.905 52.049 8.873 40.033 16.201 29.105C28.281 12.817 47.049 3.097 66.585 -1.183Z"
          />
        </svg>
        <style>{keyframes}</style>
      </div>
    </div>
  );
};

export default Loader;