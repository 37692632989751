import { redirectToLogin } from '../utils/app.utils';

interface ProtectedRouteProps {
	children: React.ReactNode;
	isAuthenticated: boolean;
}
export default function ProtectedRoute({ children, isAuthenticated }: ProtectedRouteProps) {
	if (!isAuthenticated) {
		redirectToLogin();
	}

	return <>{children}</>;
}
