import { LucideIcon, ListChecks, Type, FileText, Calendar, Clock, Mail, Link, Users, Hash, Paperclip, PenTool, CalendarClock, SquareCheck } from 'lucide-react';
import { TaskTypes } from '../interfaces/IChecklist';

// Create a type that represents the lowercase version of the enum values
export type TaskTypeId = Lowercase<TaskTypes>;

// Helper function to convert enum values to TaskTypeId
function enumToTaskTypeId(enumValue: TaskTypes): TaskTypeId {
	return enumValue.toLowerCase() as TaskTypeId;
}

// Define the task type literals directly, deriving from enum values
export const TASK_TYPE_IDS = [
	enumToTaskTypeId(TaskTypes.Check),
	enumToTaskTypeId(TaskTypes.Choice),
	enumToTaskTypeId(TaskTypes.Text),
	enumToTaskTypeId(TaskTypes.LongText),
	enumToTaskTypeId(TaskTypes.Number),
	enumToTaskTypeId(TaskTypes.DateTime),
	enumToTaskTypeId(TaskTypes.Date),
	enumToTaskTypeId(TaskTypes.Time),
	enumToTaskTypeId(TaskTypes.Email),
	enumToTaskTypeId(TaskTypes.URL),
	enumToTaskTypeId(TaskTypes.File),
	enumToTaskTypeId(TaskTypes.Assign),
	enumToTaskTypeId(TaskTypes.Signature)
] as const;

export interface ITaskType {
	id: TaskTypeId;
	name: string;
	color: string;
	icon: LucideIcon;
}

// Use the type with the configuration
export const TASK_TYPES: Record<TaskTypeId, ITaskType> = {
	check: { id: 'check', name: 'Check', color: '#f0ad4e', icon: SquareCheck },
	choice: { id: 'choice', name: 'Choice', color: '#5cb85c', icon: ListChecks },
	text: { id: 'text', name: 'Text', color: '#1e88e5', icon: Type },
	longtext: { id: 'longtext', name: 'Long Text', color: '#009688', icon: FileText },
	number: { id: 'number', name: 'Number', color: '#3949ab', icon: Hash },
	datetime: { id: 'datetime', name: 'DateTime', color: '#9c27b0', icon: CalendarClock },
	date: { id: 'date', name: 'Date', color: '#9c27b0', icon: Calendar },
	time: { id: 'time', name: 'Time', color: '#9c27b0', icon: Clock },
	email: { id: 'email', name: 'Email', color: '#8d6e63', icon: Mail },
	url: { id: 'url', name: 'URL', color: '#424242', icon: Link },
	file: { id: 'file', name: 'File', color: '#90a4ae', icon: Paperclip },
	assign: { id: 'assign', name: 'Assign', color: '#ff9800', icon: Users },
	signature: { id: 'signature', name: 'Signature', color: '#00796b', icon: PenTool },
} as const;

export const getTaskType = (id: TaskTypeId): ITaskType => TASK_TYPES[id];

export const isTaskType = (value: unknown): value is TaskTypeId => {
	return typeof value === 'string' && value.toLowerCase() in TASK_TYPES;
};

export const TASK_TYPES_ARRAY = Object.values(TASK_TYPES);

/**
 * Strictly converts a string to a TaskTypeId if it's valid, otherwise returns undefined.
 * This function is useful when you need strict type checking and want to handle invalid types explicitly.
 */
export function getTypeFromString(key: string): TaskTypeId | undefined {
	// Convert the input to lowercase to match our TaskTypeId format
	const lowercaseKey = key.toLowerCase() as TaskTypeId;
	return isTaskType(lowercaseKey) ? lowercaseKey : undefined;
}

/**
 * Flexibly converts a string to a TaskTypeId, attempting to normalize the input.
 * This function handles different string formats (uppercase, spaces, etc.) and returns undefined if no match is found.
 * 
 * Example inputs that would work:
 * - "long-text" -> "longtext"
 * - "LONG_TEXT" -> "longtext"
 * - "Long Text" -> "longtext"
 */
export function parseTypeFromString(input: string): TaskTypeId | undefined {
	// First try to match with enum values
	const enumValue = Object.values(TaskTypes).find(
		value => value === input.toUpperCase()
	);

	if (enumValue) {
		return enumToTaskTypeId(enumValue);
	}

	// If no direct enum match, try normalizing the string
	const normalized = input.toLowerCase()
		.replace(/[\s-_]/g, '')
		.trim();

	return TASK_TYPE_IDS.find(typeId => typeId === normalized);
}

/**
 * Gets a task type from a string input, falling back to the 'check' type if the input
 * is invalid or can't be parsed. This ensures we always return a valid task type.
 * 
 * @param input - The string to convert to a task type
 * @returns ITaskType - Either the matched task type or the 'check' type as fallback
 */
export function getTaskTypeFromString(input: string): ITaskType {
	// First try to match with enum values
	const enumValue = Object.values(TaskTypes).find(
		value => value === input.toUpperCase()
	);

	if (enumValue) {
		return TASK_TYPES[enumToTaskTypeId(enumValue)];
	}

	// If no enum match, try parsing the string
	const parsedType = parseTypeFromString(input);

	// If we got a valid type, return its configuration
	if (parsedType) {
		return TASK_TYPES[parsedType];
	}

	// Otherwise, return the 'check' type as our fallback
	return TASK_TYPES.check;
}

/**
 * Converts a string to a TaskTypes enum value with flexible matching.
 * Handles various string formats like spaces, hyphens, and different cases.
 * 
 * @param input - The string to convert (e.g., "long-text", "LONGTEXT", "Long Text")
 * @returns The matching TaskTypes enum value or undefined if no match is found
 * 
 * @example
 * stringToTaskType("long-text") => TaskTypes.LongText
 * stringToTaskType("DATETIME") => TaskTypes.DateTime
 * stringToTaskType("invalid") => undefined
 */
export function stringToTaskType(input: string): TaskTypes | undefined {
	// Guard against null or undefined input
	if (!input) {
		return undefined;
	}

	// Normalize the input string by removing special characters and converting to uppercase
	const normalizedInput = input
		.toUpperCase()
		.replace(/[\s-_]/g, '');

	// First try direct match with enum values
	if (normalizedInput in TaskTypes) {
		return TaskTypes[normalizedInput as keyof typeof TaskTypes];
	}

	// If no direct match, try to find a matching enum value
	const enumValues = Object.values(TaskTypes);
	const matchedValue = enumValues.find(value =>
		value === normalizedInput
	);

	return matchedValue as TaskTypes | undefined;
}