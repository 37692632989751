import { useSnackbar } from 'notistack';

export function useMutationErrorHandler() {
  const { enqueueSnackbar } = useSnackbar();

  return (action: string) => ({
    onError: (
      error: unknown,
      //   variables: unknown,
      //   context: unknown
    ) => {
      const errorMessage = error instanceof Error
        ? error.message
        : 'An unknown error occurred';

      enqueueSnackbar(`${action}: ${errorMessage}`, {
        variant: 'error'
      });
      console.error(`Error ${action}:`, error);
    }
  });
}