import { useEffect, useRef } from "react";
import { getLoginCookie, ILogin } from "../utils/cookies";
import { useMixpanel } from "../utils/mixpanel";

// TODO: don't forget on logout to clear the mixpanel identity

function isAuthValid(auth: ILogin | null): boolean {
	return !!auth && auth.isAuthenticated && (!auth.expiresTime || new Date(auth.expiresTime) > new Date());
}
export default function useIsAuthenticated() {
	const auth = getLoginCookie();
	const mixpanel = useMixpanel()
	const identifiedRef = useRef(false);  // this records if we already identified

	useEffect(() => {
		if (isAuthValid(auth) && !identifiedRef.current) {
			// we are logged in
			mixpanel.identify(auth!.id!)
			mixpanel.people.set({
				"$email": auth!.email,
			})
			identifiedRef.current = true;
		}
	}, [auth?.email])

	return isAuthValid(auth);
}