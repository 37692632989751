import { Box, IconButton, Paper, Typography } from "@mui/material";
import { GripVertical } from "lucide-react";
import { TaskTypeSelector } from "./TaskTypeSelector";
import { DEFAULT_ANSWER_META, IMeta, ITask, TaskTypes } from "../interfaces/IChecklist";
import { getMeta } from "../utils/checklist.utils";

interface Props {
	task: ITask;
	disableTypes: boolean;
}
export default function DraggedTaskItem({ task, disableTypes }: Props) {
	const noAction = () => { };

	const meta: IMeta = getMeta(task, "answer") || DEFAULT_ANSWER_META
	const currentType = meta?.type || TaskTypes.Check

	return (
		<Paper
			data-cy="template-task-dragged"
			sx={{
				my: 1,
				p: 1,
				bgcolor: "action.selected",
				opacity: 1,
				position: 'relative',
				zIndex: 1000,
				pointerEvents: 'none',
				boxShadow: 3,
			}}
		>
			<Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
				<IconButton
					size="small"
					sx={{ mt: 0.5 }}
					tabIndex={-1}
					data-cy="task-dragger"
				>
					<GripVertical size={20} />
				</IconButton>
				<Box sx={{ flex: 1 }}>
					<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
						{!disableTypes && <TaskTypeSelector type={currentType} onTypeChange={noAction} />}
						<Typography variant="body1">{task.name }</Typography>
					</Box>
				</Box>
			</Box>
		</Paper>
	)
}