import { apiConfig, ApiResponse, BaseApiClient } from "./apiClient";
import { TeamRes, TeamInviteRes } from "../interfaces/api";

export class TeamsApi extends BaseApiClient {
    private readonly BASE_PATH = 'teams';

    async getTeams(): Promise<ApiResponse<TeamRes[]>> {
        return this.get(`${this.BASE_PATH}`);
    }

    async createTeam(id: string, cid: string, org: string, name: string): Promise<ApiResponse<TeamRes>> {
        return this.post(`${this.BASE_PATH}`, { id, cid, name, org });
    }

    async getTeam(id: string): Promise<ApiResponse<TeamRes>> {
        return this.get(`${this.BASE_PATH}/${id}`);
    }

    async updateTeam(id: string, cid: string, values: { name?: string, shareContacts?: boolean, tz?: string, favorites?: string[] }): Promise<ApiResponse<TeamRes>> {
        return this.put(`${this.BASE_PATH}/${id}`, values, {
            headers: { cid }
        });
    }

    async deleteTeam(id: string, cid: string): Promise<ApiResponse<void>> {
        return this.delete(`${this.BASE_PATH}/${id}`, { data: { cid } });
    }

    async acceptTeam(id: string, cid: string): Promise<ApiResponse<void>> {
        return this.post(`${this.BASE_PATH}/${id}/accept`, { cid });
    }

    async inviteMemberTeam(id: string, cid: string, email: string, message?: string, name?: string): Promise<ApiResponse<TeamInviteRes>> {
        return this.post(`${this.BASE_PATH}/${id}/invite`, { cid, email, name, message });
    }

    async rejectMyselfTeam(id: string, cid: string): Promise<ApiResponse<void>> {
        return this.delete(`${this.BASE_PATH}/${id}/reject`, { data: { cid } });
    }

    async rejectTeam(id: string, cid: string, uidOrEmail: string): Promise<ApiResponse<void>> {
        return this.delete(`${this.BASE_PATH}/${id}/reject/${uidOrEmail}`, { data: { cid } });
    }

    async folderCreateTeam(id: string, cid: string, folderId: string, folderName: string): Promise<ApiResponse<void>> {
        return this.post(`${this.BASE_PATH}/${id}/folder`, { cid, folderId, folderName });
    }

    async folderUpdateTeam(id: string, cid: string, folderId: string, folderName: string): Promise<ApiResponse<void>> {
        return this.put(`${this.BASE_PATH}/${id}/folder/${folderId}`, { cid, folderName });
    }

    async folderDeleteTeam(id: string, cid: string, folderId: string): Promise<ApiResponse<void>> {
        return this.delete(`${this.BASE_PATH}/${id}/folder/${folderId}`, { data: { cid } });
    }

    async folderMoveTeam(id: string, cid: string, folderId: string, position: number): Promise<ApiResponse<void>> {
        return this.put(`${this.BASE_PATH}/${id}/folder/${folderId}/move`, { cid, position });
    }

    async updateMemberRoleTeam(id: string, cid: string, contactId: string, roles: string): Promise<ApiResponse<void>> {
        return this.put(`${this.BASE_PATH}/${id}/roles/${contactId}`, { cid, roles });
    }

    async restoreTeam(id: string, cid: string): Promise<ApiResponse<void>> {
        return this.post(`${this.BASE_PATH}/${id}/restore`, { cid });
    }

    async trashbinTeam(): Promise<ApiResponse<TeamRes[]>> {
        return this.get(`${this.BASE_PATH}/trashbin`);
    }
}

export const apiTeam = new TeamsApi(apiConfig);