import { ChecklistsRes, } from "../interfaces/api";
import { apiConfig, ApiResponse, BaseApiClient } from "./apiClient";

export class ChecklistApi extends BaseApiClient {
	private readonly BASE_PATH = 'checklists';

	async getChecklists(team: string): Promise<ApiResponse<ChecklistsRes>> {
		return this.get(`${this.BASE_PATH}`, {
			params: {
				team,
			}
		});
	}

	async createChecklist(
		id: string,
		cid: string,
		checklist: string,
		name: string,
		team: string,
		assign?: string[],
		owners?: string[],
		type?: string,
		notes?: string,
		utm_source?: string,
		utm_campaign?: string,
		utm_medium?: string,
		utm_content?: string,
		utm_term?: string,
	): Promise<ApiResponse<any>> {
		return this.post(`templates/createChecklist`, { id, name, checklist, team, assign, owners, type, notes, utm_source, utm_campaign, utm_medium, utm_content, utm_term }, {
			headers: { cid,team }
		});
	}
}

export const apiChecklist = new ChecklistApi(apiConfig)