import { OrgRes, OrgUsageRes } from "../interfaces/api";
import { apiConfig, ApiResponse, BaseApiClient } from "./apiClient";

export class OrgsApi extends BaseApiClient {
	private readonly BASE_PATH = 'orgs';

	async getOrgs(): Promise<ApiResponse<OrgRes[]>> {
		return this.get(`${this.BASE_PATH}`, {
			params: {
				includeTeams: 'true'
			}
		});
	}

	async getOrg(id: string): Promise<ApiResponse<OrgRes>> {
		return this.get(`${this.BASE_PATH}/${id}`);
	}

	async createOrg(id: string, name: string): Promise<ApiResponse<OrgRes>> {
		return this.post(`${this.BASE_PATH}`, { id, name });
	}

	async updateOrg(id: string, values: { name?: string, shareContacts?: boolean, tz?: string, slug?: string, website?: string, facebook?: string, twitter?: string, profile?: string }): Promise<ApiResponse<void>> {
		return this.put(`${this.BASE_PATH}/${id}`, values);
	}

	async deleteOrg(id: string): Promise<ApiResponse<void>> {
		return this.delete(`${this.BASE_PATH}/${id}`);
	}

	async restoreOrg(id: string): Promise<ApiResponse<void>> {
		return this.post(`${this.BASE_PATH}/${id}/restore`);
	}

	async updateAdminsOrg(id: string, admins: string[]): Promise<ApiResponse<void>> {
		return this.put(`${this.BASE_PATH}/${id}/updateAdmins`, { admins: admins.join(",") });
	}

	async trashbinOrg(): Promise<ApiResponse<OrgRes>> {
		return this.get(`${this.BASE_PATH}/trashbin`);
	}

	async billingManageOrg(id: string): Promise<ApiResponse<string>> {
		return this.get(`${this.BASE_PATH}/${id}/subscription/manage`);
	}

	async checkoutOrg(id: string, plan: string, quantity: number): Promise<ApiResponse<string>> {
		return this.post(`${this.BASE_PATH}/${id}/subscription`, { plan, quantity });
	}

	async updateSubscriptionOrg(id: string, duration: string, users: number): Promise<ApiResponse<void>> {
		return this.put(`${this.BASE_PATH}/${id}/subscription`, { duration, users });
	}

	async cancelSubscriptionOrg(id: string): Promise<ApiResponse<void>> {
		return this.delete(`${this.BASE_PATH}/${id}/cancelSubscription`);
	}

	async reactivateSubscriptionOrg(id: string): Promise<ApiResponse<void>> {
		return this.put(`${this.BASE_PATH}/${id}/reactivateSubscription`);
	}

	async trialOrg(id: string, use?: string): Promise<ApiResponse<OrgRes>> {
		return this.put(`${this.BASE_PATH}/${id}/trial`, { use });
	}

	async usageOrg(id: string, month?: string): Promise<ApiResponse<OrgUsageRes>> {
		return this.get(`${this.BASE_PATH}/${id}/usage`, { params: { month } });
	}

	async updateVATOrg(id: string, countryCode: string, vatNumber: string): Promise<ApiResponse<OrgRes>> {
		return this.put(`${this.BASE_PATH}/${id}/vat`, { vatNumber, countryCode });
	}
}

export const apiOrg = new OrgsApi(apiConfig);