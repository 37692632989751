import { useState, MouseEvent } from "react"
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material"
import { List, FileText, MenuIcon, } from "lucide-react"
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useCurrentTeam } from "../contexts/CurrentTeamContext";

interface Props {

}
export default function MainMenu({ }: Props) {
	const { t } = useTranslation();
	const { team } = useCurrentTeam();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const navigate = useNavigate();

	const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleChecklists = () => {
		window.location.href = `https://app2.checklist.com/orgs/${team!.org}/teams/${team!.id}/checklists`;
	};

	const handleTemplates = () => {
		navigate("/templates");
		handleMenuClose();
	};

	return (
		<>
			<IconButton
				edge="start"
				color="inherit"
				aria-label="menu"
				onClick={handleMenuOpen}
			>
				<MenuIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleMenuClose}
			>
				<MenuItem onClick={handleChecklists}>
					<ListItemIcon>
						<List size={20} />
					</ListItemIcon>
					<ListItemText primary={t('header.checklists')} />
				</MenuItem>
				<MenuItem onClick={handleTemplates}>
					<ListItemIcon>
						<FileText size={20} />
					</ListItemIcon>
					<ListItemText primary={t('header.templates')} />
				</MenuItem>
			</Menu>
		</>
	)
}