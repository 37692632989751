import { Typography, Stack, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import TeamMemberSelect from "../components/TeamMemberSelect";
import { useMutationErrorHandler } from "../hooks/useErrorHandler";
import { ITemplateMutations } from "../hooks/useTemplate";
import { useTranslation } from 'react-i18next';

interface Props {
	dueDate: string;
	alarm: string;
	taskPath: string;
	mutations: ITemplateMutations;
}

export default function TemplateTaskDueDate({ dueDate, alarm, taskPath, mutations }: Props) {
	const handleError = useMutationErrorHandler();
	const { t } = useTranslation();

	const split = dueDate.split(':')
	const [sDays, sHours, sMinutes] = split.length > 1 ? split : ['0', '0', '0']
	const days = parseInt(sDays)
	const hours = parseInt(sHours)
	const minutes = parseInt(sMinutes)

	const handleAlarm = (value: string) => {
		if (value !== alarm) {
			mutations.updateField.mutate({
				path: taskPath,
				field: 'alarm',
				value,
			}, handleError(t('templateEditor.failedToUpdateAssignees')));
		}
	}

	const updateDueDate = (value: string) => {
		mutations.updateField.mutate({
			path: taskPath,
			field: 'due-date',
			value: value === '0:0:0' ? '' : value,
		}, handleError(t('templateEditor.failedToUpdateDueDate')));
	}

	const handleChangeDays = (event: SelectChangeEvent<string>) => {
		const value = event.target.value
		const newValue = `${value}:${hours}:${minutes}`
		updateDueDate(newValue)
	}

	const handleChangeHours = (event: SelectChangeEvent<string>) => {
		const value = event.target.value
		const newValue = `${days}:${value}:${minutes}`
		updateDueDate(newValue)
	}

	const handleChangeMinutes = (event: SelectChangeEvent<string>) => {
		const value = event.target.value
		const newValue = `${days}:${hours}:${value}`
		updateDueDate(newValue)
	}

	return (
		<>
			<Typography>{t('templateEditor.dueDateDescription')}</Typography>
			<Stack direction="row" spacing={2}>
				<FormControl size="small" fullWidth>
					<InputLabel>{t('templateEditor.days')}</InputLabel>
					<Select
						label={t('templateEditor.days')}
						value={days + ''}
						onChange={handleChangeDays}
					>
						{[...Array(31)].map((_, i) => (
							<MenuItem key={i} value={'' + i}>{i}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl size="small" fullWidth>
					<InputLabel>{t('templateEditor.hours')}</InputLabel>
					<Select
						label={t('templateEditor.hours')}
						value={hours + ''}
						onChange={handleChangeHours}
					>
						{[...Array(24)].map((_, i) => (
							<MenuItem key={i} value={'' + i}>{i}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl size="small" fullWidth>
					<InputLabel>{t('templateEditor.minutes')}</InputLabel>
					<Select
						label={t('templateEditor.minutes')}
						value={minutes + ''}
						onChange={handleChangeMinutes}
					>
						{[...Array(60)].map((_, i) => (
							<MenuItem key={i} value={'' + i}>{i}</MenuItem>
						))}
					</Select>
				</FormControl>
			</Stack>

			{dueDate && <TeamMemberSelect
				multiple
				label={t('templateEditor.alarmRecipients')}
				value={alarm}
				handleSuccess={handleAlarm}
			/>}
		</>
	)
}