import { useState, useEffect } from "react";
import { Box, Button, Typography, Toolbar, Paper, Grid2 as Grid, useTheme, } from "@mui/material";
import { Folder, PlusCircle, Settings, FileText, } from "lucide-react";
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, UniqueIdentifier, closestCenter, useDroppable } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import Loader from "../components/Loader";
import MainLayout from "../Layout/MainLayout";
import { buildFolders, IDisplayFolder } from "../utils/templates.utils";
import { useTranslation } from "react-i18next";
import TemplateSortableItem from "../Templates/TemplateSortableItem";
import { useNavigate } from "react-router";
import { useTemplateMoveFolder, useTemplates } from "../hooks/useTemplate";
import { useCurrentTeam } from "../contexts/CurrentTeamContext";
import { TeamRes } from "../interfaces/api";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

// TODO: handle roles

export default function TemplatesPage() {
	const { data: templates, isLoading, error } = useTemplates();
	const { team } = useCurrentTeam();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const moveMutator = useTemplateMoveFolder(team?.id);

	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

	const [folders, setFolders] = useState<IDisplayFolder[]>([])

	useEffect(() => {
		setFolders(buildFolders(team?.folders, templates || []));
	}, [team?.folders, templates]);

	const noTemplates = folders.reduce((acc, curr) => acc + curr.templates.length, 0) === 0

	if (isLoading) return <Loader />;
	if (error || !templates) return <div>Error loading templates: {error?.message}</div>;

	const handleCreate = () => {
		navigate(`/templates/create`);
	};

	const handleDragStart = (event: DragStartEvent) => {
		const { active } = event;
		setActiveId(active.id);
	};

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (!over || active.id === over.id) return // we had not change

		// since we have the template id or folder id from the ids, get the folders
		const activeFolder = folders.find(folder => folder.templates.some(template => template.id === active.id));
		const overFolder = folders.find(folder => folder.id === over.id || folder.templates.some(template => template.id === over.id));
		const orig = templates.find(template => template.id === active.id);

		if (orig && activeFolder && overFolder) {
			const updatedFolders = folders.map(folder => {
				if (folder.id === activeFolder.id) {
					const movedTemplate = folder.templates.find(template => template.id === active.id);
					const updatedTemplates = folder.templates.filter(template => template.id !== active.id);

					if (folder.id === overFolder.id && movedTemplate) {
						// Same folder, reorder
						const overIndex = folder.templates.findIndex(template => template.id === over.id);
						if (overIndex >= 0) {
							updatedTemplates.splice(overIndex, 0, movedTemplate);
						} else {
							updatedTemplates.push(movedTemplate);
						}
					}

					return { ...folder, templates: updatedTemplates };
				}

				if (folder.id === overFolder.id && folder.id !== activeFolder.id) {
					// Different folder, move template
					const movedTemplate = activeFolder.templates.find(template => template.id === active.id);
					if (movedTemplate) {
						const newTemplates = [...folder.templates];
						const overIndex = newTemplates.findIndex(template => template.id === over.id);
						if (overIndex >= 0) {
							newTemplates.splice(overIndex, 0, movedTemplate);
						} else {
							newTemplates.push(movedTemplate);
						}
						return { ...folder, templates: newTemplates };
					}
				}

				return folder;
			});
			setFolders(updatedFolders);
			const position = overFolder.templates.findIndex(template => template.id === over.id);
			moveMutator.mutate({
				id: orig.id,
				folder: overFolder.id,
				position: position < 0 ? 0 : position
			});

		}
		setActiveId(null);
	};

	const handleDragCancel = () => {
		setActiveId(null);
	};


	const activeTemplate = folders.flatMap(f => f.templates).find(t => t.id === activeId)

	return (
		<MainLayout>
			<Box sx={{ width: '100%', p: 5 }}>
				<Toolbar />
				<Typography variant="h1" gutterBottom sx={{ mb: 4, gap: 2 }}><FileText /> {t('templates.templates')}</Typography>
				{!!team ? (
					<>
						<Button variant="contained" data-cy="create-template" color="primary" onClick={handleCreate} sx={{ mb: 3 }} startIcon={<PlusCircle />}>
							{t('templates.create')}
						</Button>
						<Button variant="outlined" sx={{ ml: 2, mb: 3 }} href={`https://app2.checklist.com/orgs/${team.org}/teams/${team.id}/folders`} startIcon={<Settings size={20} />}>
							{t('templates.manageFolders')}
						</Button>
						{noTemplates ?
							<Typography variant="h2" sx={{ mt: 2 }}>{t('templates.noTemplates')}</Typography>
							:
							<DndContext
								modifiers={[restrictToVerticalAxis]}
								collisionDetection={closestCenter}
								onDragStart={handleDragStart}
								onDragEnd={handleDragEnd}
								onDragCancel={handleDragCancel}
							>
								<Paper sx={{ mt: 4 }}>
									<Grid container sx={{ p: 1, mb: 4 }} spacing={2}>
										<Grid size={2}></Grid>
										<Grid size={5}>{t('common.name')}</Grid>
										<Grid size={2}>{t('common.lastModified')}</Grid>
										<Grid size={1}>{t('common.checklists')}</Grid>
										<Grid size={2}></Grid>
									</Grid>
								</Paper>
								{folders.map((folder) => (
									<DroppableFolder key={folder.id} folder={folder} team={team} />
								))}
								<DragOverlay>
									{activeTemplate ? <TemplateSortableItem template={activeTemplate} team={team} /> : null}
								</DragOverlay>
							</DndContext>
						}
					</>
				) : <Loader />}
			</Box>
		</MainLayout>
	);
}

interface FolderProps {
	folder: IDisplayFolder;
	team: TeamRes;
}
const DroppableFolder = ({ folder, team }: FolderProps) => {
	const theme = useTheme();
	const { setNodeRef, isOver } = useDroppable({ id: folder.id });
	return (
		<SortableContext id={folder.id} items={folder.templates.map(folder => folder.id)} strategy={verticalListSortingStrategy}>
			<Box
				data-cy="folder"
				ref={setNodeRef}
				sx={{
					mb: 4,
					borderRadius: theme.shape.borderRadius,
					minHeight: 72,
					backgroundColor: isOver ? theme.palette.primary.main : 'transparent',
					transition: 'background-color 0.3s ease',
				}}>
				<Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }} data-cy="folder-name">
					<Folder size={20} style={{ marginRight: 8 }} />{folder.name}
				</Typography>
				<Paper sx={{ p: 2, minHeight: 72, borderRadius: theme.shape.borderRadius }}>
					{folder.templates.map((template) => (
						<TemplateSortableItem key={template.id} template={template} team={team} />
					))}
				</Paper>
			</Box>
		</SortableContext >
	)
};