import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Languages } from 'lucide-react';

export default function LanguageSwitcher() {
	const { t, i18n } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const changeLanguage = (lang: string) => () => {
		i18n.changeLanguage(lang);
		handleClose();
	};

	return (
		<>
			<Tooltip title={t('header.language')}>
				<IconButton onClick={handleClick}>
					<Languages size={20} />
				</IconButton>
			</Tooltip>

			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<MenuItem
					onClick={changeLanguage('en')}
					selected={i18n.language === 'en'}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<span role="img" aria-label="flag-uk">🇬🇧</span>
						English
					</Box>
				</MenuItem>
				<MenuItem
					onClick={changeLanguage('nl')}
					selected={i18n.language === 'nl'}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<span role="img" aria-label="flag-nl">🇳🇱</span>
						Nederlands
					</Box>
				</MenuItem>
			</Menu>
		</>
	);
}