import {
    TemplateTagRes,
    IPublicTemplate,
    TemplateRes,
    TemplateSearchRes,
    Direction
} from '../interfaces/api';
import { IMetaUpdates, ITask } from '../interfaces/IChecklist';
import { apiConfig, ApiResponse, BaseApiClient } from './apiClient';

export class TemplatesApi extends BaseApiClient {
    private readonly BASE_PATH = 'templates';

    async getTemplates(
        team: string | null
    ): Promise<ApiResponse<TemplateRes[]>> {
        return this.get(`${this.BASE_PATH}`, {
            headers: { team }
        });
    }

    async getTemplate(
        id: string,
        org: string,
        team: string
    ): Promise<ApiResponse<TemplateRes>> {
        return this.get(`${this.BASE_PATH}/${id}`, {
            headers: { org, team }
        });
    }

    async searchTemplates(
        lang: string,
        org: string,
        team: string,
        text?: string,
        deleted?: boolean,
        direction?: Direction,
        page?: number,
        size?: number,
        sort?: string,
        tags?: string[]
    ): Promise<ApiResponse<TemplateSearchRes>> {
        return this.get(`${this.BASE_PATH}`, {
            params: { deleted, direction, lang, page, size, sort, tags, text },
            headers: { org, team }
        });
    }

    async createTemplate(
        id: string,
        cid: string,
        lang: string,
        name: string,
        team: string
    ): Promise<ApiResponse<void>> {
        return this.post(`${this.BASE_PATH}`, {
            id,
            name,
            lang,
            team
        }, {
            headers: { cid }
        });
    }

    async createChecklist(
        id: string,
        cid: string,
        checklist: string,
        name: string,
        team: string,
        assign?: string[],
        owners?: string[],
        type?: string,
        notes?: string,
        utm_source?: string,
        utm_campaign?: string,
        utm_medium?: string,
        utm_content?: string,
        utm_term?: string
    ): Promise<ApiResponse<void>> {
        return this.post(`${this.BASE_PATH}/createChecklist`, {
            id,
            name,
            checklist,
            team,
            assign,
            owners,
            type,
            notes,
            utm_source,
            utm_campaign,
            utm_medium,
            utm_content,
            utm_term
        }, {
            headers: { cid }
        });
    }

    async getTemplatesTags(): Promise<ApiResponse<TemplateTagRes[]>> {
        return this.get(`${this.BASE_PATH}/tags`);
    }

    async deleteTemplate(
        id: string,
        cid: string,
        path: string
    ): Promise<ApiResponse<void>> {
        return this.delete(`${this.BASE_PATH}/${id}`, {}, {
            params: { path },
            headers: { cid }
        });
    }

    async duplicateTemplate(
        id: string,
        cid: string,
        template: string,
        team: string,
        name: string,
        inclRecipes: boolean,
        view?: string,
        folderId?: string
    ): Promise<ApiResponse<void>> {
        return this.post(`${this.BASE_PATH}/${template}/duplicate`, {
            template: id,
            name,
            team,
            view,
            inclRecipes,
            folderId,
        }, {
            headers: { cid }
        });
    }

    async duplicatePublicTemplate(
        id: string,
        cid: string,
        template: string,
        team: string,
        name: string,
        inclRecipes: boolean,
        view?: string,
    ): Promise<ApiResponse<void>> {
        return this.post(`${this.BASE_PATH}/public/${id}/duplicate`, {
            template,
            name,
            team,
            view,
            inclRecipes,
        }, {
            headers: { cid }
        });
    }

    async restoreTemplate(
        id: string,
        cid: string
    ): Promise<ApiResponse<void>> {
        return this.post(`${this.BASE_PATH}/${id}`, {}, {
            headers: { cid }
        });
    }

    async updateTemplate(
        id: string,
        cid: string,
        fieldName: string,
        fieldValue: string
    ): Promise<ApiResponse<void>> {
        return this.put(`${this.BASE_PATH}/${id}/${fieldName}`, {}, {
            params: { fieldValue },
            headers: { cid }
        });
    }

    async addVisibilityTemplate(
        id: string,
        cid: string,
        visibility: string
    ): Promise<ApiResponse<void>> {
        return this.post(`${this.BASE_PATH}/${id}/addVisibility`, {}, {
            params: { visibility },
            headers: { cid }
        });
    }

    async deleteVisibilityTemplate(
        id: string,
        cid: string,
        visibility: string
    ): Promise<ApiResponse<void>> {
        return this.delete(`${this.BASE_PATH}/${id}/deleteVisibility`, {}, {
            params: { visibility },
            headers: { cid }
        });
    }

    async updateFieldTemplate(
        id: string,
        cid: string,
        path: string,
        field: string,
        value?: string
    ): Promise<ApiResponse<void>> {
        return this.put(`${this.BASE_PATH}/${id}/field/${field}`, {
            value
        }, {
            params: { path },
            headers: { cid }
        });
    }

    async moveTemplate(
        id: string,
        cid: string,
        folder: string,
        position: number
    ): Promise<ApiResponse<void>> {
        return this.put(`${this.BASE_PATH}/${id}/move`, {}, {
            params: {
                folder,
                position
            },
            headers: { cid }
        });
    }

    async publishTemplate(
        id: string,
        cid: string
    ): Promise<ApiResponse<IPublicTemplate>> {
        return this.post(`${this.BASE_PATH}/${id}/publish`, {}, {
            headers: { cid }
        });
    }

    async unpublishTemplate(
        id: string,
        cid: string
    ): Promise<ApiResponse<void>> {
        return this.delete(`${this.BASE_PATH}/${id}/publish`, {}, {
            headers: { cid }
        });
    }

    async addTaskTemplate(
        id: string,
        cid: string,
        path: string,
        position: number,
        name: string,
        taskId: string,
        type: string,
    ): Promise<ApiResponse<void>> {
        return this.post(`${this.BASE_PATH}/${id}/tasks`, {
            id: taskId,
            name,
            path,
            position,
            type,
        }, {
            headers: { cid }
        });
    }

    async updateTaskMetaTemplate(
        id: string,
        cid: string,
        path: string,
        key: string,
        updates: IMetaUpdates
    ): Promise<ApiResponse<void>> {
        return this.put(`${this.BASE_PATH}/${id}/tasks/meta/${key}`, {
            calc: updates.calc,
            description: updates.description,
            hidden: updates.hidden,
            label: updates.label,
            options: updates.options?.toString(),
            permissions: updates.permissions,
            type: updates.type,
            required: updates.required
        }, {
            params: { path },
            headers: { cid }
        });
    }

    async moveTaskTemplate(
        id: string,
        cid: string,
        path: string,
        toPath: string,
        position: number
    ): Promise<ApiResponse<void>> {
        return this.put(`${this.BASE_PATH}/${id}/tasks/move`, {}, {
            params: { path, toPath, position },
            headers: { cid }
        });
    }

    async addTasksTemplate(
        id: string,
        cid: string,
        position: number,
        tasks: ITask[]
    ): Promise<ApiResponse<void>> {
        return this.post(`${this.BASE_PATH}/${id}/tasks/full`, tasks, {
            params: { position },
            headers: { cid }
        });
    }
}

export const apiTemplate = new TemplatesApi(apiConfig);