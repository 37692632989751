import { useState, useEffect, KeyboardEvent, useRef, FocusEvent, ChangeEvent } from 'react';
import { TextField, styled, TypographyVariants } from '@mui/material';

interface Props {
	dataCy?: string;
	value: string;
	placeholder?: string;
	onChange: (value: string) => void;
	onFocus?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	multiline?: boolean;
	maxRows?: number;
	allowEmpty: boolean;
	variant?: keyof TypographyVariants;
}
export default function EditableText({
	value,
	placeholder,
	onChange,
	onFocus,
	multiline = false,
	variant = 'body1',
	maxRows,
	allowEmpty,
	...props
}: Props) {
	const [editValue, setEditValue] = useState(value);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setEditValue(value);
	}, [value]);

	const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setEditValue(event.currentTarget.value);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter' && !multiline) {
			e.preventDefault();
			inputRef.current?.blur();
		}
		if (e.key === 'Escape') {
			setEditValue(value);
			inputRef.current?.blur();
		}
	};

	const handleBlur = () => {
		if (editValue !== value) {
			if (allowEmpty || editValue.trim() !== '') {
				onChange(editValue);
			} else {
				setEditValue(value);
			}
		}
	};

	const handleFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (onFocus) onFocus(event);
	};

	const { dataCy, ...otherProps } = props;

	return (
		<StyledTextField
			fullWidth
			value={editValue}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			onFocus={handleFocus}
			onBlur={handleBlur}
			variant='standard'
			placeholder={placeholder}
			multiline={multiline}
			maxRows={maxRows}
			inputRef={inputRef}
			isMultiline={multiline}
			typography={variant}
			slotProps={{
				input: {
					inputProps: {
						'data-cy': dataCy
					}
				}
			}}
			{...otherProps}
		/>
	);
}

const StyledTextField = styled(TextField, {
	shouldForwardProp: (prop) => prop !== 'isMultiline' && prop !== 'typography',
})<{ isMultiline?: boolean; typography?: keyof TypographyVariants }>(
	({ theme, isMultiline, typography = 'body1' }) => {
		// Use a type assertion to ensure TypeScript recognizes `fontSize` access.
		const typographyStyle = theme.typography[typography] as {
			fontSize?: string | number;
		};

		return {
			'& .MuiInputBase-root': {
				border: '2px solid transparent',
				paddingLeft: 4,
				paddingRight: 4,
				'&:before': {
					borderBottom: 'none !important',
				},
				'&:hover:not(.Mui-disabled)': {
					backgroundColor: theme.palette.action.hover,
					border: `2px solid ${theme.palette.action.hover}`,
					borderRadius: theme.shape.borderRadius,
				},
				'&.Mui-focused': {
					backgroundColor: theme.palette.action.hover,
					border: `2px solid ${theme.palette.primary.main} !important`,
					borderRadius: theme.shape.borderRadius,
					'&:after': {
						borderBottom: 'none',
						content: 'none',
					},
				},
				// Safely access `fontSize`
				fontSize: isMultiline ? undefined : typographyStyle?.fontSize,
			},
		};
	},
);