// ThemeSwitcherContext.tsx
import { ThemeProvider } from '@mui/material';
import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { darkTheme, lightTheme } from '../theme';

export type ThemeMode = 'light' | 'dark' | 'system';

interface ThemeSwitcherContextType {
	themeMode: ThemeMode;
	setThemeMode: (mode: ThemeMode) => void;
	isDarkMode: boolean;
}

const ThemeSwitcherContext = createContext<ThemeSwitcherContextType | undefined>(undefined);

interface ThemeSwitcherProviderProps {
	children: ReactNode;
}

export function ThemeSwitcherProvider({ children }: ThemeSwitcherProviderProps) {
	const [themeMode, setThemeMode] = useState<ThemeMode>(() => {
		const saved = localStorage.getItem('themeMode');
		return saved ? (saved as ThemeMode) : 'system';
	});

	const [systemIsDark, setSystemIsDark] = useState(
		window.matchMedia('(prefers-color-scheme: dark)').matches
	);

	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		const handleChange = (e: MediaQueryListEvent) => setSystemIsDark(e.matches);

		mediaQuery.addEventListener('change', handleChange);
		return () => mediaQuery.removeEventListener('change', handleChange);
	}, []);

	useEffect(() => {
		if (themeMode === 'system') {
			localStorage.removeItem('themeMode');
		} else {
			localStorage.setItem('themeMode', themeMode);
		}
	}, [themeMode]);

	const isDarkMode = themeMode === 'system' ? systemIsDark : themeMode === 'dark';

	return (
		<ThemeSwitcherContext.Provider value={{ themeMode, setThemeMode, isDarkMode }}>
			{children}
		</ThemeSwitcherContext.Provider>
	);
}

export function useThemeSwitcher() {
	const context = useContext(ThemeSwitcherContext);
	if (!context) {
		throw new Error('useThemeSwitcher must be used within a ThemeSwitcherProvider');
	}
	return context;
}

export function ThemeWrapper({ children }: { children: ReactNode }) {
	const { isDarkMode } = useThemeSwitcher();
	return (
		<ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
			{children}
		</ThemeProvider>
	);
}