import { TemplateRes } from "../interfaces/api"
import { ITeamFolder } from "../interfaces/ITeam"

export interface IDisplayFolder {
	id: string
	name: string
	templates: TemplateRes[]
}

export const buildFolders = (inputFolders: ITeamFolder[] | undefined, templates: TemplateRes[]): (IDisplayFolder[]) => {
	if (!inputFolders) {
		return []
	}
	const empty: IDisplayFolder = {
		id: "no_folder",
		name: "No Folder",
		templates: [],
	}
	const folders: IDisplayFolder[] = inputFolders.map(a => ({
		id: a.id,
		name: a.name,
		templates: []
	}))
	folders.push(empty)
	for (let tl of templates) {
		let added = false
		if (tl.folder && tl.folder.id) {
			const fl = folders.find(f => f.id === tl.folder.id)
			if (fl) {
				fl.templates.push(tl)
				added = true
			}
		}
		if (!added) {
			empty.templates.push(tl)
		}
	}
	for (let fl of folders) {
		fl.templates.sort((a, b) => {
			const posA = a.folder?.position ?? Infinity;
			const posB = b.folder?.position ?? Infinity;
			return posA - posB;
		});
	}
	return folders
}