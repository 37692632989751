import { Avatar } from "@mui/material";
import { LucideIcon, User, Users, Building } from "lucide-react";
import { createElement } from "react";

interface Props {
	size?: number; // default 32px
	src?: string;
	alt?: string;
	id?: string; // we use the id to show a default in case we are missing the src. Can be: uid_, org_, team_
	email?: string
}
export default function ChecklistAvatar({ size = 32, src, alt, id, email }: Props) {

	const getDefault = (): LucideIcon => {
		if (id?.startsWith('org_')) return Building; // organization icon
		if (id?.startsWith('team_')) return Users; // team icon
		// default
		return User; // person icon
	}

	return (
		<Avatar
			sx={{ width: size, height: size }}
			alt={alt}
			src={
				src ?
					(src.startsWith('/') ? `https://public.checklist.com${src}` : src)
					:
					// use gravatar if it there is an email
					email && `https://www.gravatar.com/avatar/${email.toLowerCase().replace('@', '')}?d=identicon&s=${size}`
			}
		>
			{createElement(getDefault())}
		</Avatar>
	)
}