import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { TeamRes } from '../interfaces/api';
import { produce } from 'immer';
import { generateCID } from '../utils/ids';
import { apiTeam } from '../api/teamApiClient';

export interface ITeamMutations {
    update: UseMutationResult<any, unknown, { name?: string; tz?: string, favorites?: string[] }, unknown>;
}

export function useTeam(id: string | null | undefined) {
    const queryClient = useQueryClient();
    const queryKey = ['team', id];

    const {
        data: team,
        isLoading,
        isError,
        error
    } = useQuery({
        queryKey,
        queryFn: async () => {
            const response = await apiTeam.getTeam(id!);
            if (response.error) throw response.error;
            return response.data;
        },
        enabled: !!id,
        staleTime: 5 * 60 * 1000,
    });

    const mutations: ITeamMutations = {

        update: useMutation({
            mutationFn: async ({ name, tz, favorites }) => {
                const response = await apiTeam.updateTeam(
                    id!,
                    generateCID(),
                    { name, tz, favorites }
                );
                if (response.error) throw response.error;
                return response.data;
            },
            onMutate: async ({ name, tz, favorites }) => {
                await queryClient.cancelQueries({ queryKey });
                const previousData = queryClient.getQueryData(queryKey);
                queryClient.setQueryData(queryKey, (oldData: TeamRes) => {
                    if (!oldData) throw new Error('No team data found');
                    return produce(oldData, draft => {
                        if (name !== undefined) draft.name = name;
                        // if (shareContacts !== undefined) draft.shareContacts = shareContacts;
                        if (tz !== undefined) draft.tz = tz;
                        if (favorites !== undefined) draft.favorites = favorites;
                    });
                });
                return { previousData };
            }
        }),

    };

    return {
        team,
        isLoading,
        isError,
        error,
        mutations,
    };
}
