import { v4 as uuidv4 } from 'uuid'

export /* TODO: const */ enum ObjTypes {
    USER = "uid",
    FOLDER = "fld",
    TEAM = "team",
    CHANGE = "cid",
    CHECKLIST = "cl",
    ORG = "org",
    CLIENT = "client_web",
    TASK = "task",
    FILE = "file",
    TEMPLATE = "tl",
    RECIPE = "rec",
    CODE = "code",
    FORM = 'frm',
}

export const generateId = (objType: ObjTypes) => {
    return `${objType}_${uuidv4()}`
}

export const generateCode = (length: number): string => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

export const getIdCode = (id: string): ObjTypes | undefined => {
    const upperId = id.toUpperCase() as keyof typeof ObjTypes;
    return ObjTypes[upperId];
}

export const isIdOfType = (id: string, type: ObjTypes): boolean => {
    return id.startsWith(type + '_')
}

export const generateCID = () => generateId(ObjTypes.CHANGE)
export const generateTemplateID = () => generateId(ObjTypes.TEMPLATE)
export const generateChecklistID = () => generateId(ObjTypes.CHECKLIST)
export const generateTaskID = () => generateId(ObjTypes.TASK)
