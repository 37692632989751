import { useParams } from "react-router";
import { useTemplate } from "../hooks/useTemplate";
import TemplateEditor from "../TemplateEditor/TemplateEditor";
import { useEffect } from "react";
import { useCurrentTeam } from "../contexts/CurrentTeamContext";

// TODO: check if user has editor role 

export default function TemplatePage() {
	const { id } = useParams<{ id: string }>();
	const { template, isLoading, mutations, isPending } = useTemplate(id);
	const { team, switchTeam } = useCurrentTeam();

	// ensure the current team is set to the template team
	useEffect(() => {
		if (team && template && team?.id !== template?.owner.team) {
			switchTeam(template.owner.team);
		}
	}, [team?.id, template?.owner.team])

	return (
		<TemplateEditor isLoading={isLoading} template={template} saving={isPending} mutations={mutations} />
	);
}