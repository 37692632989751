import { DndContext, DragEndEvent, useSensor, useSensors, PointerSensor, UniqueIdentifier, DragStartEvent, DragOverlay, KeyboardSensor, TouchSensor } from "@dnd-kit/core";
import { useTranslation } from 'react-i18next';
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { ITemplateMutations } from "../hooks/useTemplate";
import { ITask } from "../interfaces/IChecklist";
import { Box, } from "@mui/material";
import TemplateTask from "./TemplateTask";
import { useEffect, useMemo, useState } from "react";
import { useMutationErrorHandler } from "../hooks/useErrorHandler";
import { calculateMove, getPathLevel, makeSubtask, makeUnSubtask, moveTask } from "../utils/checklist.utils";
import { createPortal } from "react-dom";
import { useMixpanel } from "../utils/mixpanel";
import DraggedTaskItem from "./DraggedTaskItem";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

interface Props {
	tasks: ITask[];
	showNotes: boolean;
	disableTypes: boolean;
	cursor: ITask | null;
	setCursor: (string: string | null) => void;
	mutations: ITemplateMutations;
}
export default function TemplateTasks({ tasks, cursor, setCursor, disableTypes, showNotes, mutations }: Props) {
	const { t } = useTranslation();
	const handleError = useMutationErrorHandler();
	const mixpanel = useMixpanel();

	const [collapsedTasks, setCollapsedTasks] = useState<Set<string>>(new Set());
	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null); // the item being dragged

	const { filterTasks, hasSubtasks } = useMemo(() => {
		let hasSubtasks = false;
		const filteredTasks = tasks.filter(task => {
			// Check if this task is a subtask
			if (!hasSubtasks && getPathLevel(task.path) > 0) {
				hasSubtasks = true;
			}
			// Test if its path starts with any of the collapsed tasks
			for (const ct of collapsedTasks) {
				if (task.path !== ct && task.path.startsWith(ct)) return false;
			}
			return true;
		});
		return { filterTasks: filteredTasks, hasSubtasks };
	}, [tasks, collapsedTasks]);

	const [visibleTasks, setVisibleTasks] = useState<ITask[]>(filterTasks);

	// console.log("Render"),
	// 	visibleTasks.forEach(task => console.log(task.name));

	useEffect(() => {
		setVisibleTasks(filterTasks);
	}, [filterTasks]);
	const activeItem = activeId ? visibleTasks.find(task => task.id === activeId) : null;

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 5, // Adjust this value to change when dragging starts
			},
		}),
		useSensor(KeyboardSensor),
		useSensor(TouchSensor),
	);

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		// mixpanel!!!

		if (!over || !active) return;

		const activeTask = tasks.find((task) => task.id === active.id);
		const overTask = tasks.find((task) => task.id === over.id);
		if (!activeTask || !overTask) return;

		const oldIndex = tasks.findIndex((task) => task.id === active.id);
		const newIndex = tasks.findIndex((task) => task.id === over.id);
		if (oldIndex === newIndex) return;
		// toPath is the path of the parent of the task we are dropping on (if root, it's the template path)
		// position is the index of the task we are dropping on WITHIN its parent
		const { path, toPath, position } = calculateMove(tasks, oldIndex, newIndex);
		mutations.moveTask.mutate(
			{ path, toPath, position },
			handleError(t('templateEditor.tasks.moveTaskError'))
		);
		const newTasks = moveTask(tasks, path, toPath, position)
		setVisibleTasks(newTasks)
		setActiveId(null);
		mixpanel.track('template', {
			action: 'move-task',
			template: activeTask.path.split('/')[1],
			task: activeTask.id,
			toTask: overTask.id,
		})
	};

	const handleDragStart = ({ active: { id: activeId } }: DragStartEvent) => {
		// move cursor to active task
		const newTask = tasks.find((task) => task.id === activeId)
		if (newTask) setCursor(newTask.path);
		// mark as active so we can drag it and hide its children
		setActiveId(activeId);
	};

	const handleDragCancel = () => {
		setActiveId(null);
	};

	const handleSubtask = (task: ITask) => () => {
		const index = tasks.findIndex((t) => t.id === task.id);
		const move = makeSubtask(tasks, index);
		mutations.moveTask.mutate({
			path: move.path,
			toPath: move.toPath,
			position: move.position,
		}, handleError('Failed to subtask task.'));
		mixpanel.track('template', {
			action: 'subtask',
			template: task.path.split('/')[1],
			task: task.id,
		})
	}

	const handleUnsubtask = (task: ITask) => () => {
		const index = tasks.findIndex((t) => t.id === task.id);
		const move = makeUnSubtask(tasks, index);
		mutations.moveTask.mutate({
			path: move.path,
			toPath: move.toPath,
			position: move.position,
		}, handleError('Failed to unsubtask task.'));
		mixpanel.track('template', {
			action: 'unsubtask',
			template: task.path.split('/')[1],
			task: task.id,
		})
	}

	const handleToggleSubtasks = (task: ITask) => () => {
		setCollapsedTasks(prev => {
			const newCollapsed = new Set(prev);
			if (newCollapsed.has(task.path)) {
				newCollapsed.delete(task.path);
			} else {
				newCollapsed.add(task.path);
			}
			return newCollapsed;
		});
		mixpanel.track('template', {
			action: 'toggle-subtasks',
			template: task.path.split('/')[1], // Assuming the template ID is the first part of the path
		})
	};

	const hasChildren = (task: ITask) => {
		const index = tasks.findIndex(t => t.id === task.id);
		if (index >= tasks.length - 1) return false;
		const nextTask = tasks[index + 1];
		return nextTask.path.startsWith(task.path);
	}

	const canSubtask = (task: ITask) => {
		const index = tasks.findIndex(t => t.id === task.id);
		if (index < 1) return false;
		const aboveLevel = getPathLevel(tasks[index - 1].path);
		const currentLevel = getPathLevel(task.path);
		return aboveLevel >= currentLevel;
	}

	const handleClickTask = (task: ITask) => () => {
		setCursor(task.id);
	}

	const handleCursorNext = (task: ITask) => () => {
		const index = visibleTasks.findIndex(t => t.id === task.id);
		if (index + 1 < visibleTasks.length) {
			setCursor(visibleTasks[index + 1].id);
		}
	}

	return (
		<DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragCancel={handleDragCancel}>
			<Box sx={{ mt: 3 }}>
				<SortableContext items={visibleTasks.map(task => task.id)} strategy={verticalListSortingStrategy}>
					{visibleTasks.map(task => (
						(activeItem && (activeItem.id !== task.id && task.path.startsWith(activeItem.path))) ?
							null
							:
							<TemplateTask
								key={task.id}
								task={task}
								isCursor={cursor?.id === task.id}
								isExpanded={!collapsedTasks.has(task.path)}
								disableTypes={disableTypes}
								showNotes={showNotes}
								hasSubtasks={hasSubtasks}
								hasChildren={hasChildren(task)}
								canSubtask={canSubtask(task)}
								onClick={handleClickTask(task)}
								onCursorNext={handleCursorNext(task)}
								onToggleSubtasks={handleToggleSubtasks(task)}
								handleSubtask={handleSubtask(task)}
								handleUnsubtask={handleUnsubtask(task)}
								mutations={mutations}
							/>
					))}
					{createPortal(
						<DragOverlay>
							{activeItem ? (
								<DraggedTaskItem
									task={activeItem}
									disableTypes={disableTypes}
								/>
							) : null}
						</DragOverlay>,
						document.body
					)}
				</SortableContext>
			</Box>
		</DndContext>
	);
}