import { produce } from "immer"
import { DEFAULT_ANSWER_META, DEFAULT_CHOICE_OPTIONS, IField, IMeta, ITask, TaskTypes } from "../interfaces/IChecklist"
import { generateTaskID } from "./ids"

export const getPathLength = (path: string): number => {
	return path.split('/').length - 1
}

export const getPathLevel = (path: string): number => {
	return path.split('/').length - 3
}

export const getParentPath = (path: string): string => {
	const lastSlashIndex = path.lastIndexOf('/')
	return lastSlashIndex === -1 ? '' : path.substring(0, lastSlashIndex)
}

export const getGrandParentPath = (path: string): string => {
	const split = path.split('/')
	return split.slice(0, -2).join('/')
}

export const getPositionWithinParent = (tasks: ITask[], parentPath: string, childPath: string): number => {
	// console.log("getPositionWithinParent", { parentPath, childPath, tasks })
	const parentIndex = tasks.findIndex(x => x.path === parentPath)
	// if parentIndex === -1 it means parent is a checklist

	const parentPathLength = getPathLength(parentPath)
	// console.log({ parentIndex, parentPathLength , childPathLength})
	let i = 0
	let count = 0
	while (parentIndex + i + 1 < tasks.length) {
		const currentPath = tasks[parentIndex + i + 1].path
		// console.log({ currentPath, i, count })
		if (currentPath === childPath) {
			return count
		}
		const currentPathLength = getPathLength(currentPath)
		if (currentPathLength === parentPathLength + 1) {
			count++
		}
		i++
	}
	console.error("getPositionWithinParent: no position found. should never happen")
	console.log({ tasks, parentPath, childPath })
	return -1
}

/**
 * pos is relative (i.e. within the parent path)
 * returns absolute position (i.e. within the full tasks)
*/
export const findNewPosition = (tasks: ITask[], toPath: string, pos: number): number => {
	// console.log("findNewPosition", { toPath, pos, tasks })

	if (pos === -1) {
		// move to end of tasks
		return tasks.length
	}

	const toPathLength = getPathLength(toPath) // will be 1 if a toPAth is a checklist
	// console.log({ toPathLength })
	let current = toPathLength
	if (current > 1) {
		// it is NOT a checklist
		const toPathPos = tasks.findIndex(x => x.path === toPath)
		if (toPathPos === -1) {
			console.error("Didn't find toPath and is not a checklist. Should never happen", { toPath, pos, tasks })
			return -1
		}
		// since it is NOT a checklist, we is current as the task pos
		current = toPathPos + 1
	} else {
		// it is a checklist
		current = 0
	}

	// if first, then return
	if (pos === 0) {
		return current  // if checklist will be 0, otherwise the toPathPos+1
	}

	// console.log("Starting iteration:", { current })
	// iterate from next
	let count = 0;
	for (; current < tasks.length; current++) {
		const taskPath = tasks[current].path
		const taskPathLength = getPathLength(taskPath)
		// console.log("Iteration", { current, taskPath, taskPathLength, count })
		if (taskPathLength <= toPathLength) {
			// we are at same level tasks or less
			// console.log("first")
			return current
		}
		if (toPathLength + 1 === taskPathLength) {
			// this child is at the same level as where we are supposed to be
			count++
		}
		if (count > pos) {
			// we have reached the position
			// console.log("second")
			return current
		}
	}

	// console.log("Default option", { toPath, pos, tasks })
	// didn't find a place, so return the last position in the list
	return tasks.length
}

export const calculateMove = (tasks: ITask[], oldIndex: number, newIndex: number): {
	path: string
	toPath: string
	position: number
} => {
	const oldTask = tasks[oldIndex]
	const newTask = tasks[newIndex]
	const path = oldTask.path
	const toPath = getParentPath(newTask.path)
	const position = getPositionWithinParent(tasks, toPath, newTask.path)

	return {
		path,
		toPath,
		position,
	}
}

export const getMeta = (task: ITask | undefined | null, field: string): (IMeta | null) => {
	if (task && field && task.meta) {
		return task.meta.find(x => x.key === field) || null
	}
	return null
}

export const upsertTaskField = (task: ITask, key: string, value?: string): ITask => {
	const fields = task.fields || []
	if (value) {
		const index = fields.findIndex(x => x.key === key)
		if (index === -1) {
			fields.push({ key, value })
		} else {
			fields[index].value = value
		}
		return { ...task, fields }
	}
	return { ...task, fields: fields.filter(x => x.key !== key) }

}

export const getField = (key: string, fields?: IField[]): string | null => {
	const field = fields?.find(x => x.key === key)
	return field ? field.value : null
}

export const duplicateTasks = (tasks: ITask[], originalTask: ITask): ITask[] => {
	const result: ITask[] = [];
	const index = tasks.findIndex(t => t.id === originalTask.id);
	if (index === -1) return result;
	const origPath = originalTask.path;
	const mappings = new Map<string, string>();
	for (let i = index; i < tasks.length; i++) {
		const current = tasks[i];
		if (current.path.startsWith(origPath)) {
			const copy = duplicateTask(current, mappings);
			result.push(copy);
		} else {
			break;
		}
	}
	return result;
}

export const duplicateTask = (orig: ITask, mappings: Map<string, string>): ITask => {
	return produce(orig, draft => {
		const id = generateTaskID();
		draft.id = id;
		draft.path = calcNewPath(mappings, orig.path, id);
		const now = new Date().toISOString();
		draft.created = now;
		draft.timestamp = now;
	});
}

export interface IMoveInterface {
	path: string
	toPath: string
	position: number
}

/**
 * Move a task withing an array of tasks. Do this in an immutable way ensuring performance.
 * Note that paths always include at the start the id of the checklist the tasks belong to. Eg: /checklistId/taskId/childTaskId and so on
 * @param tasks - sorted array of tasks
 * @param path - the path of the task to be moved
 * @param toPath - the path of the parent to move the task to
 * @param position - the position within the parent to move the task to
 */
export const moveTask = (tasks: ITask[], path: string, toPath: string, position: number): ITask[] => {
	return produce(tasks, draft => {
		// Identify and collect tasks to move
		const taskIndex = draft.findIndex(x => x.path === path);
		if (taskIndex === -1) {
			console.error("Couldn't find task with path. Should never happen");
			return;
		}

		const task = draft[taskIndex];
		const subTasks = draft.filter(x => x.path.startsWith(`${path}/`));

		// Remove task and its subtasks from their current positions
		draft.splice(taskIndex, 1); // Remove the task
		subTasks.forEach(subTask => {
			const subTaskIndex = draft.findIndex(x => x === subTask);
			if (subTaskIndex > -1) draft.splice(subTaskIndex, 1); // Remove subtask
		});

		// Adjust paths and levels for the task and subtasks
		const newPath = `${toPath}/${task.id}`;
		task.path = newPath;
		task.level = getPathLevel(newPath);

		subTasks.forEach(subTask => {
			subTask.path = subTask.path.replace(path, newPath);
			subTask.level = getPathLevel(subTask.path);
		});

		// Insert the task at its new position
		const newPositionIndex = findNewPosition(draft, toPath, position);
		draft.splice(newPositionIndex, 0, task);

		// Insert subtasks at positions following the parent task
		subTasks.forEach((subTask, index) => {
			draft.splice(newPositionIndex + 1 + index, 0, subTask);
		});
	});
};

export const makeSubtask = (tasks: ITask[], index: number): IMoveInterface => {
	/* There are two options (first is one above has same parent as subtasked: if above.parent.path==this.parent.path):
	   Option 1:
		* Task 1
		* Task 2
		makeSubtask(2): toPath: above, position: 0
		* Task 1
		*    Task 2

		Option 2:
		* Task 1
		*    Task 2
		* Task 3
		makeSubtask(3): toPath: above.parent, position: last child of parent (or above.position - but we need to calc it as well)
		* Task 1
		*    Task 2
		*    Task 3
	   */

	const path = tasks[index].path
	const above = tasks[index - 1]
	if (above === undefined) {
		// TODO: this should not happen. Maybe do nothin or return nothing?
		console.error("No above found", { index, tasks })
		// ChecklistSentry.captureMessage(`No above found:${index}:${tasks.length}`, {
		//     extra: {
		//         tasks,
		//         index
		//     }
		// })
	}
	const aboveParentPath = getParentPath(above.path)
	const parentPath = getParentPath(path)
	if (aboveParentPath === parentPath) {
		const position = 0
		const toPath = above.path
		return ({ path, toPath, position })
	} else {
		const toPath = aboveParentPath
		const position = index - tasks.findIndex(x => x.path === toPath) - 1
		return ({ path, toPath, position })
	}
}

export const makeUnSubtask = (tasks: ITask[], index: number): IMoveInterface => {
	/*
		In all cases:
		toPath - grandparent.path
		pos - parent position within grandparent+1
	*/
	const path = tasks[index].path
	const parentPath = getParentPath(path)
	const grandParentPath = getGrandParentPath(path)
	const parentPosition = getPositionWithinParent(tasks, grandParentPath, parentPath)
	const toPath = grandParentPath
	const position = parentPosition + 1

	return ({ path, toPath, position })
}

const calcNewPath = (mappings: Map<string, string>, oldPath: string, newId: string): string => {
	const parts = oldPath.split('/');
	const newParts = parts.map(part => mappings.get(part) || part);
	newParts[newParts.length - 1] = newId;
	return newParts.join('/');
}

export const getInitialMeta = (type: TaskTypes): IMeta => {
	const meta: IMeta = {
		...DEFAULT_ANSWER_META
	}
	meta.type = type
	if (type === TaskTypes.Choice) {
		meta.options = JSON.stringify(DEFAULT_CHOICE_OPTIONS)
	}
	return meta
}