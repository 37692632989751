import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { Trash, PlusCircle, GripVertical, Save } from 'lucide-react';
import { ColorResult, Sketch } from '@uiw/react-color';
import { ChoiceOptionPalette, DEFAULT_CHOICE_OPTIONS, IMetaOption, IMetaOptions } from '../interfaces/IChecklist';

// https://github.com/mikbry/material-ui-color - old way not being used
// the used library seems to be a fork of the original react-color

// Utility function to generate a unique slug
const generateSlug = (label: string, existingOptions: IMetaOption[]): string => {
	const baseSlug = label
		.toLowerCase()
		.replace(/[^a-z0-9]+/g, '-')
		.replace(/^-|-$/g, '');
	let uniqueSlug = baseSlug;
	let counter = 1;
	while (existingOptions.some(option => option.key === uniqueSlug)) {
		uniqueSlug = `${baseSlug}-${counter}`;
		counter++;
	}
	return uniqueSlug;
};

// Get unused colors
const getUnusedColor = (usedColors: string[]): string => {
	const allColors = Object.values(ChoiceOptionPalette);
	const unusedColors = allColors.filter(color => !usedColors.includes(color));
	return unusedColors.length > 0 ? unusedColors[0] : allColors[0];
};

// Sortable Option Row Component
const SortableOptionRow = ({
	option,
	onLabelChange,
	onColorChange,
	onDelete,
	canDelete
}: {
	option: IMetaOption;
	onLabelChange: (key: string, newLabel: string) => void;
	onColorChange: (key: string, newColor: string) => void;
	onDelete: (key: string) => void;
	canDelete: boolean;
}) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({ id: option.key });

	const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
	const [labelEdit, setLabelEdit] = useState(option.label);

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	// TODO: need to close others when this is open (manage at parent component). Can also catch click outside. 
	const toggleOpenPicker = () => {
		setIsColorPickerOpen(!isColorPickerOpen);
	};

	const handleColorChange = (color: ColorResult) => {
		onColorChange(option.key, color.hex);
		setIsColorPickerOpen(false);
	};

	const handleDelete = () => onDelete(option.key);

	return (
		<Box
			ref={setNodeRef}
			style={style}
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: 2,
				mb: 1,
				position: 'relative'
			}}
		>
			{/* Drag Handle */}
			<Box {...attributes} {...listeners} sx={{ cursor: 'grab' }} tabIndex={-1}>
				<GripVertical size={20} />
			</Box>

			{/* Color Picker */}
			<Box
				onClick={toggleOpenPicker}
				sx={{
					width: 40,
					height: 25,
					backgroundColor: option.color,
					cursor: 'pointer',
					border: '1px solid rgba(0,0,0,0.1)',
					borderRadius: 1
				}}
			/>
			{isColorPickerOpen && (
				<Box sx={{ position: 'absolute', top: '100%', left: 0, zIndex: 10 }}>
					<Sketch
						color={option.color || ChoiceOptionPalette.lime}
						onChange={handleColorChange}
					/>
				</Box>
			)}

			{/* Label */}
			<TextField
				value={labelEdit}
				onChange={(e) => setLabelEdit(e.target.value)}
				onBlur={() => onLabelChange(option.key, labelEdit)}
				variant="standard"
				fullWidth
			/>

			{/* Delete Button */}
			{canDelete && (
				<IconButton
					tabIndex={-1}
					onClick={handleDelete}
					color="error"
				>
					<Trash size={20} />
				</IconButton>
			)}
		</Box>
	);
};

interface Props {
	value: string
	onSuccess: (name: string) => void
}
export default function OptionsConfigurator({ value, onSuccess }: Props) {
	const { t } = useTranslation();
	const [options, setOptions] = useState<IMetaOptions>(DEFAULT_CHOICE_OPTIONS);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	let originalValue: IMetaOptions = DEFAULT_CHOICE_OPTIONS;
	try {
		originalValue = JSON.parse(value);
	} catch (error) {
		// console.error('Failed to parse value:', error);
	}
	// compare the current options with originalValue
	const isDirty = JSON.stringify(options) !== JSON.stringify(originalValue);

	// update if anything changes from above
	useEffect(() => {
		setOptions(originalValue);
	}, [value])

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (active.id !== over?.id) {
			setOptions((oldValue) => {
				const oldIndex = oldValue.values.findIndex((item) => item.key === active.id);
				const newIndex = oldValue.values.findIndex((item) => item.key === over?.id);

				return {
					...oldValue,
					values: arrayMove(oldValue.values, oldIndex, newIndex)
				};
			});
		}
	};

	const handleAddOption = () => {
		const usedColors = options.values.map(opt => opt.color);
		const newColor = getUnusedColor(usedColors);
		const newOptionNumber = options.values.length + 1;

		const newOption: IMetaOption = {
			key: generateSlug(t('templateEditor.optionLabel', { number: newOptionNumber }), options.values),
			label: t('templateEditor.optionLabel', { number: newOptionNumber }),
			color: newColor
		};

		setOptions({
			...options,
			values: [...options.values, newOption]
		});
	};

	const handleLabelChange = (key: string, newLabel: string) => {
		setOptions({
			...options,
			values: options.values.map(opt =>
				opt.key === key
					? { ...opt, label: newLabel, key: generateSlug(newLabel, options.values) }
					: opt
			)
		});
	};

	const handleColorChange = (key: string, newColor: string) => {
		setOptions({
			...options,
			values: options.values.map(opt =>
				opt.key === key ? { ...opt, color: newColor } : opt
			)
		});
	};

	const handleDeleteOption = (key: string) => {
		// Prevent deletion if only 2 options remain
		if (options.values.length > 2) {
			setOptions({
				...options,
				values: options.values.filter(opt => opt.key !== key)
			});
		}
	};

	const handleSave = () => {
		onSuccess(JSON.stringify(options));
	};

	return (
		<Box sx={{ width: '100%', maxWidth: 500 }}>
			<DndContext
				sensors={sensors}
				modifiers={[restrictToVerticalAxis]}
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}
			>
				<SortableContext items={options.values.map(opt => opt.key)} strategy={rectSortingStrategy}>
					{options.values.map((option) => (
						<SortableOptionRow
							key={option.key}
							option={option}
							onLabelChange={handleLabelChange}
							onColorChange={handleColorChange}
							onDelete={handleDeleteOption}
							canDelete={options.values.length > 2}
						/>
					))}
				</SortableContext>
			</DndContext>

			<Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
				<Button
					sx={{ whiteSpace: 'nowrap' }}
					fullWidth
					color="inherit"
					variant="outlined"
					startIcon={<PlusCircle />}
					onClick={handleAddOption}
				>
					{t('templateEditor.addOption')}
				</Button>
				{isDirty && <Button
					fullWidth
					variant="outlined"
					color="error"
					startIcon={<Save />}
					onClick={handleSave}
				>
					{t('templateEditor.save')}
				</Button>}
			</Box>
		</Box>
	);
};