import { useQuery } from "@tanstack/react-query";
import { apiOrg } from "../api/orgApiClient";


export const useOrgs = () => {

	const result = useQuery({
		queryKey: ['orgs'],
		queryFn: async () => {
			const response = await apiOrg.getOrgs();
			if (response.error) throw response.error;
			return response.data;
		},
		staleTime: 5 * 60 * 1000,
	});

	const orgs = result.data || [];

	return {
		...result,
		teams: orgs.flatMap(org => org.teams || []),
		orgs,
	}
}