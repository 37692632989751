import { SnackbarProvider } from "notistack";
import AppRoutes from "./routes/AppRoutes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MixpanelProvider } from "./utils/mixpanel";
import { CssBaseline } from '@mui/material'
import { BrowserRouter } from 'react-router'
import { ThemeSwitcherProvider, ThemeWrapper } from "./contexts/ThemeSwitcherContext"
import { CurrentTeamProvider } from "./contexts/CurrentTeamContext";

const queryClient = new QueryClient()

function App() {
  return (
    <ThemeSwitcherProvider>
      <ThemeWrapper>
        <QueryClientProvider client={queryClient}>
          <CurrentTeamProvider>
            <BrowserRouter>
              <CssBaseline />
              <MixpanelProvider>
                <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                  <AppRoutes />
                </SnackbarProvider>
              </MixpanelProvider>
            </BrowserRouter>
          </CurrentTeamProvider>
        </QueryClientProvider>
      </ThemeWrapper>
    </ThemeSwitcherProvider>
  );
}

export default App;