import { useState } from 'react';
import { Box, Button, Divider, IconButton, Menu, MenuItem, Typography, Stack, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { User, Settings, Shield, UserPlus, Edit, ShieldPlus } from 'lucide-react';
import { OrgRes, TeamRes } from '../interfaces/api';
import { useMixpanel } from '../utils/mixpanel';
import ChecklistAvatar from '../components/ChecklistAvatar';
import { useUser } from '../hooks/useUser';
import Loader from '../components/Loader';
import { useCurrentTeam } from '../contexts/CurrentTeamContext';

interface Props { }

export default function AccountMenu({ }: Props) {
	const { data: user, isLoading: isUserLoading } = useUser();
	const { orgs, team: currentTeam, switchTeam } = useCurrentTeam();
	const mixpanel = useMixpanel();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOrgPick = (org: OrgRes) => () => {
		mixpanel.track('org', {
			action: 'switch',
			org: org.id,
		});
		// navigation(`/orgs/${org.id}`);
		window.location.href = `https://app2.checklist.com/orgs/${org.id}`;
	}

	const handleTeamPick = (team: TeamRes) => () => {
		switchTeam(team.id);
		mixpanel.track('team', {
			action: 'switch',
			team: team.id,
		});
		handleClose();
	}

	const handleTeamManage = (team: TeamRes) => () => {
		mixpanel.track('team', {
			action: 'manage',
			team: team.id,
		});
		// navigation(`/teams/${team.id}`);
		window.location.href = `https://app2.checklist.com/orgs/${team.org}/teams/${team.id}`;
	}

	const handleProfile = () => {
		mixpanel.track('account', {
			action: 'profile',
		});
		// navigation('/account/profile');
		window.location.href = 'https://app2.checklist.com/account';
	}

	const handlePreferences = () => {
		mixpanel.track('account', {
			action: 'preferences',
		});
		// navigation('/account/preferences');
		window.location.href = 'https://app2.checklist.com/account';
	}

	const handleSecurity = () => {
		mixpanel.track('account', {
			action: 'security',
		});
		// navigation('/account/security');
		window.location.href = 'https://app2.checklist.com/account';
	}

	return (
		<>
			<IconButton onClick={handleOpen}>
				{user && <ChecklistAvatar size={24} src={user.avatar} alt={user.full || "User Name"} id={user.id} email={user.email} />}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Box sx={{ display: 'flex', width: 600, maxWidth: '100%' }}>
					<Box sx={{ width: '50%', borderRight: 1, borderColor: 'divider', overflowY: 'auto' }}>
						<List disablePadding>
							{orgs.map((org) => (
								<Box key={org.id}>
									<ListItem disablePadding>
										<ListItemButton onClick={handleOrgPick(org)}>
											<ListItemIcon>
												<ChecklistAvatar size={24} src={org.avatar} alt={org.name} id={org.id} />
											</ListItemIcon>
											<ListItemText primary={org.name} primaryTypographyProps={{ noWrap: true }} />
										</ListItemButton>
									</ListItem>
									<List disablePadding>
										{org.teams?.map((team) => (
											<ListItem
												key={team.id}
												disablePadding
												secondaryAction={
													<IconButton edge="end" size="small" onClick={handleTeamManage(team)}>
														<Edit size={16} />
													</IconButton>
												}
											>
												<ListItemButton
													onClick={handleTeamPick(team)}
													selected={team === currentTeam}
													sx={{ pl: 4 }}
												>
													<ListItemIcon>
														<ChecklistAvatar size={24} src={team.avatar} alt={team.name} id={team.id} />
													</ListItemIcon>
													<ListItemText primary={team.name} primaryTypographyProps={{ noWrap: true }} />
												</ListItemButton>
											</ListItem>
										))}
									</List>
								</Box>
							))}
						</List>
					</Box>
					<Box sx={{ width: '50%', p: 2 }}>
						{!!user && !isUserLoading ?
							<Stack spacing={2} alignItems="center">
								<ChecklistAvatar size={64} src={user.avatar} alt={user.full || "User Name"} id={user.id} email={user.email} />
								<Typography variant="subtitle1">{user.full || "User Name"}</Typography>
								<Typography variant="body2" color="text.secondary">{user.email}</Typography>
								<Button startIcon={<UserPlus />} fullWidth>
									Invite to Checklist
								</Button>
								<Button variant="contained" startIcon={<ShieldPlus />} fullWidth>
									Try for free
								</Button>
								<Divider sx={{ width: '100%' }} />
								<MenuItem sx={{ width: '100%' }} onClick={handleProfile}>
									<User size={16} style={{ marginRight: 8 }} />
									Profile
								</MenuItem>
								<MenuItem sx={{ width: '100%' }} onClick={handlePreferences}>
									<Settings size={16} style={{ marginRight: 8 }} />
									Preferences
								</MenuItem>
								<MenuItem sx={{ width: '100%' }} onClick={handleSecurity}>
									<Shield size={16} style={{ marginRight: 8 }} />
									Security
								</MenuItem>
							</Stack>
							:
							<Loader />
						}
					</Box>
				</Box>
			</Menu>
		</>
	);
}