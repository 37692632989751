import { Routes, Route, Navigate } from 'react-router';
import ThemePage from '../pages/ThemePage';
import TemplatesPage from '../pages/TemplatesPage';
import TemplatePage from '../pages/TemplatePage';
import ProtectedRoute from './ProtectedRoute';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import * as Sentry from "@sentry/react";
import LayoutPage from '../pages/LayoutPage';
import { Typography } from '@mui/material';
import TemplateCreatePage from '../pages/TemplateCreatePage';

const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

function AppRoutes() {
	const isAuthenticated = useIsAuthenticated();

	return (
		<SentryRoutes>
			{/* Public Routes */}
			<Route path="/" element={<ThemePage />} />
			<Route path="/theme" element={<ThemePage />} />
			<Route path="/layout" element={<LayoutPage ><Typography variant="h1">Main Page Content</Typography></LayoutPage>} />

			{/* Protected Routes */}
			<Route path="/templates" element={
				<ProtectedRoute isAuthenticated={isAuthenticated}>
					<TemplatesPage />
				</ProtectedRoute>
			}
			/>
			<Route path="/templates/create" element={
				<ProtectedRoute isAuthenticated={isAuthenticated}>
					<TemplateCreatePage />
				</ProtectedRoute>
			}
			/>
			<Route path="/templates/:id" element={
				<ProtectedRoute isAuthenticated={isAuthenticated}>
					<TemplatePage />
				</ProtectedRoute>
			}
			/>

			{/* Catch all route */}
			<Route path="*" element={<Navigate to="/" replace />} />
		</SentryRoutes>
	);
}

export default AppRoutes;