import { Box, Container, Typography, Paper, Divider, Button, Stack, IconButton, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { Bell, Home, Settings, User, Mail, Heart, Search, Menu, ChevronDown, Plus, Trash2, Check, X, AlertCircle, } from 'lucide-react';
import Loader from '../components/Loader';
import RouterLink from '../components/Link';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import { DEV_ENV } from '../utils/const';

type ColorType = 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';

export default function ThemePage() {
	const colors: ColorType[] = ['primary', 'secondary', 'error', 'warning', 'info', 'success'];

	const isAuthenticated = useIsAuthenticated();

	const authUrl = isAuthenticated ?
		// let's logout
		(DEV_ENV ? 'http://localhost:3001/logout' : 'https://checklist.com/logout')
		:
		// let's login
		(DEV_ENV ?
			`http://localhost:3001/login?redirect=${window.location.href}`
			:
			`https://checklist.com/login?redirect=${window.location.href}`
		);

	// console.log(DEV_ENV, authUrl)
	return (
		<Container maxWidth="lg" sx={{ py: 4 }}>
			<Typography variant="h2" gutterBottom>
				Typography with Montserrat
				<Button variant="contained" color="primary" sx={{ ml: 2 }} href={authUrl}>{isAuthenticated ? 'Logout' : 'Login'}</Button>
				{isAuthenticated && <RouterLink component="button" data-cy="templates" variant="contained" color="primary" sx={{ ml: 2 }} to="/templates">Templates</RouterLink>}
			</Typography>

			<Paper elevation={3} sx={{ p: 4, mb: 4, mt: 4, }}>
				<Loader />
			</Paper>

			{/* Colors */}
			<Paper elevation={3} sx={{ p: 4, mb: 4 }}>
				<Typography variant="h4" gutterBottom>
					Color Palette
				</Typography>
				<Divider sx={{ mb: 3 }} />

				<Stack spacing={3}>
					{/* Main Colors */}
					<Box>
						<Typography variant="h6" gutterBottom>Main Colors</Typography>
						<Stack direction="row" spacing={2} flexWrap="wrap">
							{colors.map((color) => (
								<Box key={color} sx={{ textAlign: 'center' }}>
									<Box
										sx={{
											width: 100,
											height: 100,
											bgcolor: `${color}.main`,
											borderRadius: 1,
											mb: 1,
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>
										<Typography variant="caption" sx={{ color: '#fff' }}>
											{color}.main
										</Typography>
									</Box>
									<Box
										sx={{
											width: 100,
											height: 50,
											bgcolor: `${color}.light`,
											borderRadius: 1,
											mb: 1,
										}}
									/>
									<Box
										sx={{
											width: 100,
											height: 50,
											bgcolor: `${color}.dark`,
											borderRadius: 1,
										}}
									/>
								</Box>
							))}
						</Stack>
					</Box>

					{/* Grey Scale */}
					<Box>
						<Typography variant="h6" gutterBottom>Grey Scale</Typography>
						<Stack direction="row" spacing={2} flexWrap="wrap">
							{[50, 100, 200, 300, 400, 500, 600, 700, 800, 900].map((shade) => (
								<Box key={shade} sx={{ textAlign: 'center' }}>
									<Box
										sx={{
											width: 60,
											height: 60,
											bgcolor: `grey.${shade}`,
											borderRadius: 1,
											mb: 1,
										}}
									/>
									<Typography variant="caption">
										{shade}
									</Typography>
								</Box>
							))}
						</Stack>
					</Box>

					{/* Text Colors */}
					<Box>
						<Typography variant="h6" gutterBottom>Text Colors</Typography>
						<Stack spacing={1}>
							<Typography color="text.primary">Text Primary</Typography>
							<Typography color="text.secondary">Text Secondary</Typography>
							<Typography color="text.disabled">Text Disabled</Typography>
						</Stack>
					</Box>
				</Stack>
			</Paper>

			{/* Headings */}
			<Paper elevation={3} sx={{ p: 4, mb: 4 }}>
				<Typography variant="h4" gutterBottom>
					Headings
				</Typography>
				<Divider sx={{ mb: 3 }} />

				<Stack spacing={3}>
					<Typography variant="h1">Heading 1</Typography>
					<Typography variant="h2">Heading 2</Typography>
					<Typography variant="h3">Heading 3</Typography>
					<Typography variant="h4">Heading 4</Typography>
					<Typography variant="h5">Heading 5</Typography>
					<Typography variant="h6">Heading 6</Typography>
				</Stack>
			</Paper>

			{/* Body Text */}
			<Paper elevation={3} sx={{ p: 4, mb: 4 }}>
				<Typography variant="h4" gutterBottom>
					Body Text
				</Typography>
				<Divider sx={{ mb: 3 }} />

				<Stack spacing={3}>
					<Box>
						<Typography variant="subtitle1" gutterBottom>
							Subtitle 1
						</Typography>
						<Typography variant="body1" gutterBottom>
							Body 1: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
						</Typography>
					</Box>

					<Box>
						<Typography variant="subtitle2" gutterBottom>
							Subtitle 2
						</Typography>
						<Typography variant="body2" gutterBottom>
							Body 2: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
						</Typography>
					</Box>
				</Stack>
			</Paper>

			{/* Special Text Styles */}
			<Paper elevation={3} sx={{ p: 4, mb: 4 }}>
				<Typography variant="h4" gutterBottom>
					Special Text Styles
				</Typography>
				<Divider sx={{ mb: 3 }} />

				<Stack spacing={2}>
					<Typography variant="caption">
						Caption text
					</Typography>
					<Typography variant="overline">
						Overline text
					</Typography>
					<Typography variant="button">
						Button text
					</Typography>
				</Stack>
			</Paper>

			{/* Buttons */}
			<Paper elevation={3} sx={{ p: 4 }}>
				<Typography variant="h4" gutterBottom>
					Buttons
				</Typography>
				<Divider sx={{ mb: 3 }} />

				<Stack spacing={2}>
					<Stack direction="row" spacing={2} flexWrap="wrap" gap={2}>
						<Button variant="text">Text Button</Button>
						<Button variant="contained">Contained Button</Button>
						<Button variant="outlined">Outlined Button</Button>
					</Stack>

					{colors.map((color) => (
						<Stack key={color} direction="row" spacing={2} flexWrap="wrap" gap={2}>
							<Button variant="contained" color={color}>
								{color} Contained
							</Button>
							<Button variant="outlined" color={color}>
								{color} Outlined
							</Button>
							<Button variant="text" color={color}>
								{color} Text
							</Button>
						</Stack>
					))}

					<Stack direction="row" spacing={2} flexWrap="wrap" gap={2}>
						<Button size="small">Small Button</Button>
						<Button size="medium">Medium Button</Button>
						<Button size="large">Large Button</Button>
					</Stack>
				</Stack>
			</Paper>

			{/* Icons */}
			<Paper elevation={3} sx={{ p: 4, mb: 4 }}>
				<Typography variant="h4" gutterBottom>
					Icons
				</Typography>
				<Divider sx={{ mb: 3 }} />

				<Stack spacing={4}>
					{/* Basic Icons */}
					<Box>
						<Typography variant="h6" gutterBottom>Basic Icons</Typography>
						<Stack direction="row" spacing={2} alignItems="center">
							<Home />
							<Bell />
							<Settings />
							<User />
							<Mail />
						</Stack>
					</Box>

					{/* Colored Icons */}
					<Box>
						<Typography variant="h6" gutterBottom>Colored Icons</Typography>
						<Stack direction="row" spacing={2} alignItems="center">
							<Home color="#4888BB" /> {/* primary.main */}
							<Bell color="#BB6848" /> {/* secondary.main */}
							<Settings color="#D84848" /> {/* error.main */}
							<User color="#F19E2C" /> {/* warning.main */}
							<Mail color="#48BBAA" /> {/* info.main */}
						</Stack>
					</Box>

					{/* Different Sizes */}
					<Box>
						<Typography variant="h6" gutterBottom>Different Sizes</Typography>
						<Stack direction="row" spacing={2} alignItems="center">
							<Heart size={16} />
							<Heart size={24} />
							<Heart size={32} />
							<Heart size={40} />
						</Stack>
					</Box>

					{/* Stroke Width */}
					<Box>
						<Typography variant="h6" gutterBottom>Stroke Width Variations</Typography>
						<Stack direction="row" spacing={2} alignItems="center">
							<Search strokeWidth={1} />
							<Search strokeWidth={1.5} />
							<Search strokeWidth={2} />
							<Search strokeWidth={2.5} />
						</Stack>
					</Box>

					{/* Icons in Buttons */}
					<Box>
						<Typography variant="h6" gutterBottom>Icons in Buttons</Typography>
						<Stack direction="row" spacing={2} alignItems="center">
							<Button variant="contained" startIcon={<Plus size={20} />}>
								Add Item
							</Button>
							<Button variant="outlined" endIcon={<ChevronDown size={20} />}>
								More Options
							</Button>
							<IconButton color="primary">
								<Menu />
							</IconButton>
							<IconButton color="error">
								<Trash2 />
							</IconButton>
						</Stack>
					</Box>

					{/* Icons in Lists */}
					<Box>
						<Typography variant="h6" gutterBottom>Icons in Lists</Typography>
						<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
							<ListItem>
								<ListItemIcon>
									<Home color="#4888BB" />
								</ListItemIcon>
								<ListItemText primary="Home" />
							</ListItem>
							<ListItem>
								<ListItemIcon>
									<User color="#4888BB" />
								</ListItemIcon>
								<ListItemText primary="Profile" />
							</ListItem>
							<ListItem>
								<ListItemIcon>
									<Settings color="#4888BB" />
								</ListItemIcon>
								<ListItemText primary="Settings" />
							</ListItem>
						</List>
					</Box>

					{/* Status Icons */}
					<Box>
						<Typography variant="h6" gutterBottom>Status Icons</Typography>
						<Stack direction="row" spacing={2} alignItems="center">
							<Check color="#5BA97A" /> {/* success.main */}
							<X color="#D84848" /> {/* error.main */}
							<AlertCircle color="#F19E2C" /> {/* warning.main */}
						</Stack>
					</Box>

					{/* Icons with Different Properties Combined */}
					<Box>
						<Typography variant="h6" gutterBottom>Combined Properties</Typography>
						<Stack direction="row" spacing={2} alignItems="center">
							<Bell
								size={32}
								color="#4888BB"
								strokeWidth={1.5}
							/>
							<Settings
								size={32}
								color="#BB6848"
								strokeWidth={1.5}
							/>
							<Mail
								size={32}
								color="#48BBAA"
								strokeWidth={1.5}
							/>
						</Stack>
					</Box>
				</Stack>
			</Paper>
		</Container>
	);
}