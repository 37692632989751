import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { ITeamMutations, useTeam } from '../hooks/useTeam';
import { OrgRes, TeamRes } from '../interfaces/api';
import { useOrgs } from '../hooks/useOrgs';

const CURRENT_TEAM_ID_KEY = 'currentTeamId';

interface CurrentTeamContextType {
	team: TeamRes | undefined;
	orgs: OrgRes[];
	isLoading: boolean;
	error: Error | undefined;
	mutations: ITeamMutations;
	switchTeam: (teamId: string) => void;
}

const CurrentTeamContext = createContext<CurrentTeamContextType | undefined>(undefined);

export const CurrentTeamProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

	const [currentTeamId, setCurrentTeamId] = useState<string | null>(() => {
		return localStorage.getItem(CURRENT_TEAM_ID_KEY);
	});

	const { teams, orgs, isLoading: isLoadingOrgs, error: errorOrgs } = useOrgs();
	const { team, isLoading: isLoadingTeam, error: errorTeam, mutations, } = useTeam(currentTeamId);

	useEffect(() => {
		if (!currentTeamId && teams && teams.length > 0) {
			const firstNonInvitedTeam = teams.find(t => !t.invited);
			switchTeam(firstNonInvitedTeam?.id || teams[0].id);
		}
	}, [currentTeamId, teams]);

	const switchTeam = (id: string) => {
		setCurrentTeamId(id);
		localStorage.setItem(CURRENT_TEAM_ID_KEY, id);
	};

	const value: CurrentTeamContextType = {
		team,
		orgs,
		isLoading: isLoadingOrgs || isLoadingTeam,
		error: errorOrgs || errorTeam || undefined,
		switchTeam,
		mutations,
	};

	return (
		<CurrentTeamContext.Provider value={value}>
			{children}
		</CurrentTeamContext.Provider>
	);
};

export const useCurrentTeam = (): CurrentTeamContextType => {
	const context = useContext(CurrentTeamContext);
	if (context === undefined) {
		throw new Error('useCurrentTeam must be used within a CurrentTeamProvider');
	}
	return context;
};