// TeamMemberSelect.tsx
import { Box, Checkbox, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { Users } from 'lucide-react';
import ChecklistAvatar from './ChecklistAvatar';
import { useCurrentTeam } from '../contexts/CurrentTeamContext';

interface Props {
	label: string;
	value: string; // single or comma separated list of ids
	multiple?: boolean;
	handleSuccess: (value: string) => void;
}
export default function TeamMemberSelect({
	label,
	value,
	multiple = false,
	handleSuccess,
}: Props) {

	const { team } = useCurrentTeam();

	const teamMembers: ContactOption[] = (team?.contacts || []).filter(c => c.active && !c.roles?.includes('GUEST')).map(c => ({
		id: c.user!, // since we filter out guests, we can assume this is set
		name: c.name!,
		avatar: c.avatar,
	}));
	// add the team itself at the top
	if (team) teamMembers.unshift({
		id: team.id,
		name: team.name,
		avatar: team.avatar,
	});

	const handleSelect = (member: ContactOption) => () => {
		if (multiple) {
			// toggle adding or removing the value according to itself current existence
			const arr = value.split(',').filter(Boolean);
			const newValue = arr.includes(member.id) ? arr.filter(id => id !== member.id).join(',') : [...arr, member.id].join(',');
			handleSuccess(newValue)
		} else {
			// single select
			handleSuccess(member.id)
		}
	};

	return (
		<FormControl size="small">
			<InputLabel>{label}</InputLabel>
			<Select
				multiple={multiple}
				size="small"
				value={multiple ? (Array.isArray(value) ? value : value.split(',').filter(Boolean)) : value}
				input={<OutlinedInput label={label} />}
				startAdornment={<Users style={{ marginRight: 10 }} />}
				renderValue={(selected) => {
					const selectedArray = Array.isArray(selected) ? selected : [selected];
					return (
						<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
							{selectedArray.map((value) => (
								<Chip
									key={value}
									label={teamMembers.find(m => m.id === value)?.name}
									size="small"
								/>
							))}
						</Box>
					);
				}}
				MenuProps={MenuProps}
			>
				{!multiple && <MenuItem value=""></MenuItem>}
				{teamMembers.map((member) => (
					<MemberMenuItem
						multiple={multiple}
						key={member.id}
						member={member}
						checked={multiple ?
							Array.isArray(value) ? value.includes(member.id) : value.includes(member.id) :
							value === member.id
						}
						handleClick={handleSelect(member)}
					/>
				))}
			</Select>
		</FormControl>
	);
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

interface ContactOption {
	id: string;
	name: string;
	avatar?: string;
}

interface MemberMenuItemProps {
	multiple: boolean;
	member: ContactOption;
	checked?: boolean;
	handleClick: () => void;
}
const MemberMenuItem = ({ multiple, member, checked, handleClick }: MemberMenuItemProps) => (
	<MenuItem value={member.id} onClick={handleClick}>
		<Stack direction='row' spacing={2} alignItems="center" justifyContent="space-between" width="100%">
			{multiple && checked !== undefined && <Checkbox checked={checked} />}
			<Typography>{member.name}</Typography>
			<ChecklistAvatar src={member.avatar} alt={member.name} id={member.id} />
		</Stack>
	</MenuItem>
)