import { useTranslation } from 'react-i18next'; import { Box, Typography, Stack, FormControlLabel, Switch, Paper, IconButton, Tooltip } from "@mui/material";
import { DEFAULT_ANSWER_META, IMeta, ITask, TaskTypes } from "../interfaces/IChecklist";
import { ITemplateMutations } from "../hooks/useTemplate";
import { Link as LinkIcon, NotebookPen, Link, BellRing, Users2, CircleAlert, ListChecks, Copy } from 'lucide-react';
import TitleIconHelp from './TitleIconHelp';
import AutoGrowTextField from '../components/Input/AutoGrowTextField';
import { useMutationErrorHandler } from '../hooks/useErrorHandler';
import { getField, getMeta } from '../utils/checklist.utils';
import MultiTypeField from '../components/Input/MultiTypeField';
import { regexpUrl } from '../utils/validation';
import OptionsConfigurator from './OptionsConfigurator';
import TemplateTaskDueDate from './TemplateTaskDueDate';
import TeamMemberSelect from '../components/TeamMemberSelect';
import { useMixpanel } from '../utils/mixpanel';

interface Props {
	task: ITask;
	disableTypes: boolean;
	onDuplicate: () => void;
	mutations: ITemplateMutations;
}
export default function TemplateTaskDetails({ task, disableTypes, onDuplicate, mutations }: Props) {
	const { t } = useTranslation();
	const handleError = useMutationErrorHandler();
	const mixpanel = useMixpanel();

	const dueDate = getField("due-date", task.fields) || ''
	const alarm = getField("alarm", task.fields) || ''
	const assign = getField('assign', task.fields) || ''
	const url = getField('url', task.fields) || ''
	const clickableUrl = url.startsWith('http') ? url : `https://${url}` // for opening in new tab

	const meta: IMeta = (task ? getMeta(task, "answer") : undefined) || DEFAULT_ANSWER_META;

	const handleNotesChange = (value: string) => {
		if (value !== task.notes) {
			mutations.updateField.mutate({
				field: 'notes',
				value,
				path: task.path
			}, handleError('Failed to update notes')
			);
			mixpanel.track('template', {
				action: 'task-notes',
				template: task.path.split('/')[1],
				task: task.id,
			})
		}
	};

	const handleUrlChange = (value: string) => {
		if (value !== url) {
			mutations.updateField.mutate({
				field: 'url',
				value,
				path: task.path
			}, handleError('Failed to update URL')
			);
			mixpanel.track('template', {
				action: 'task-url',
				template: task.path.split('/')[1],
				task: task.id,
			})
		}
	};

	const handleUrlClick = () => {
		if (!regexpUrl.test(url)) return;
		window.open(clickableUrl, '_blank');
		mixpanel.track('template', {
			action: 'click-task-url',
			template: task.path.split('/')[1],
			task: task.id,
			url: clickableUrl,
		})
	}

	const handleRequiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const required = event.target.checked;
		if (required !== meta.required) {
			mutations.updateTaskMeta.mutate(
				{
					path: task.path,
					key: 'answer',
					meta: {
						...meta,
						required,
					},
				}, handleError('Failed to update required status')
			);
			mixpanel.track('template', {
				action: 'task-required',
				template: task.path.split('/')[1],
				task: task.id,
				required,
			})
		}
	};

	const handleAssignSelected = (value: string) => {
		if (value !== assign) {
			mutations.updateField.mutate({
				path: task.path,
				field: 'assign',
				value,
			}, handleError('Failed to update assignees'));
			mixpanel.track('template', {
				action: 'task-assign',
				template: task.path.split('/')[1],
				task: task.id,
				assign: value,
			})
		}
	}

	const handleOptions = (value: string) => {
		if (value !== meta.options) {
			mutations.updateTaskMeta.mutate({
				path: task.path,
				key: 'answer',
				meta: {
					...meta,
					options: value,
				},
			}, handleError('Failed to update options'));
			mixpanel.track('template', {
				action: 'task-options',
				template: task.path.split('/')[1],
				task: task.id,
				options: value
			})
		}
	}

	return (
		<Box>
			<Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
				<TitleIconHelp
					variant="h2"
					title={t('templateEditor.taskDetailsTitle')}
					help={t('templateEditor.taskDetailsTitleHelp')}
					url="/templates/template-task"
				/>
				<Tooltip title={t('templateEditor.duplicateTask')}>
					<IconButton data-cy="task-duplicate-button" onClick={onDuplicate}><Copy /></IconButton>
				</Tooltip>
			</Stack>

			<Stack spacing={3} mt={1}>
				{/* Notes Section */}
				<Paper elevation={0}>
					<Stack spacing={2}>
						<TitleIconHelp
							icon={NotebookPen}
							title={t('templateEditor.notesTitle')}
							help={t('templateEditor.notesHelp')}
							url="/templates/template-task#task-notes"
						/>
						<AutoGrowTextField
							dataCy="task-details-notes-field"
							minRows={2}
							placeholder={t('templateEditor.notesPlaceholder')}
							value={task.notes || ''}
							onSuccess={handleNotesChange}
						/>
					</Stack>
				</Paper>

				{/* Options Configuration */}
				{meta.type === TaskTypes.Choice &&
					<Paper elevation={0}>
						<Stack spacing={2}>
							<TitleIconHelp
								icon={ListChecks}
								title={t('templateEditor.choiceOptionsTitle')}
								help={t('templateEditor.choiceOptionsHelp')}
								url="/templates/template-task#task-type"
							/>
							<OptionsConfigurator value={meta.options || ""} onSuccess={handleOptions} />
						</Stack>
					</Paper>
				}
				{/* Due Date Section */}
				<Paper elevation={0}>
					<Stack spacing={2}>
						<TitleIconHelp
							icon={BellRing}
							title={t('templateEditor.dueDateTitle')}
							help={t('templateEditor.dueDateHelp')}
							url="/templates/template-task#due-date"
						/>
						<TemplateTaskDueDate dueDate={dueDate} alarm={alarm} taskPath={task.path} mutations={mutations} />
					</Stack>
				</Paper>

				{/* Assign Section */}
				<Paper elevation={0}>
					<Stack spacing={2}>
						<TitleIconHelp
							icon={Users2}
							title={t('templateEditor.assignTitle')}
							help={t('templateEditor.assignHelp')}
							url="/templates/template-task#assign"
						/>
						<TeamMemberSelect
							multiple
							label={t('templateEditor.assignLabel')}
							value={assign}
							handleSuccess={handleAssignSelected}
						/>
					</Stack>
				</Paper>

				{/* Required Section */}
				{!disableTypes && <Paper elevation={0}>
					<Stack spacing={2}>
						<TitleIconHelp
							icon={CircleAlert}
							title={t('templateEditor.requiredTitle')}
							help={t('templateEditor.requiredHelp')}
							url="/templates/template-task#required"
						/>
						<FormControlLabel
							control={
								<Switch
									checked={!!meta.required}
									onChange={handleRequiredChange}
								/>
							}
							label={<Typography>{t('templateEditor.requiredLabel')}</Typography>}
						/>
					</Stack>
				</Paper>}

				{/* Link Section */}
				<Paper elevation={0}>
					<Stack spacing={2}>
						<TitleIconHelp
							icon={Link}
							title={t('templateEditor.linkTitle')}
							help={t('templateEditor.linkHelp')}
							url="/templates/template-task#link"
						/>
						<MultiTypeField
							type="url"
							value={url}
							onSuccess={handleUrlChange}
							icon={LinkIcon}
							placeholder='https://...'
							iconHandler={handleUrlClick}
						/>
					</Stack>
				</Paper>

			</Stack>
		</Box>
	);
}