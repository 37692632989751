import { ChangeEvent, useState } from "react";
import { Grid2 as Grid, Box, Button, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Stack, TextField, Toolbar, Tooltip, Typography, SelectChangeEvent, InputLabel, Select, MenuItem } from "@mui/material";
import { ChevronLeft } from "lucide-react";
import { regexpTemplateName } from "../utils/validation";
import MainLayout from "../Layout/MainLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useMixpanel } from "../utils/mixpanel";
import { generateTemplateID } from "../utils/ids";
import { standardTemplates } from "../hooks/useChecklist";
import { useDuplicateTemplate } from "../hooks/useTemplate";
import { useCurrentTeam } from "../contexts/CurrentTeamContext";

interface Props {

}
export default function TemplateCreatePage({ }: Props) {
	const { t } = useTranslation();
	const { team, isLoading } = useCurrentTeam();
	const navigate = useNavigate();
	const mixpanel = useMixpanel();
	const { mutate, isPending } = useDuplicateTemplate();

	const [name, setName] = useState("");
	const [view, setView] = useState("*FORM")
	const [folder, setFolder] = useState("")

	const validName = regexpTemplateName.test(name.trim())

	const handleName = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
	const handleView = (e: ChangeEvent<HTMLInputElement>) => setView(e.target.value);

	const handleBack = () => {
		mixpanel.track('template-create', {
			action: 'back',
		})
		navigate(`/templates`)
	};

	const handleLibrary = () => {
		mixpanel.track('templates', {
			action: 'library',
		})
		window.location.href = `https://checklist.com/templates/business`;
	}

	const handleFolderChange = (event: SelectChangeEvent) => {
		setFolder(event.target.value as string);
	};

	const handleCreate = () => {
		const id = generateTemplateID();
		mutate({
			template: standardTemplates.shareableDue.id, // template to duplicate
			id,
			name,
			view,
			inclRecipes: false,
			folderId: folder !== "" ? folder : undefined,
		}, {
			onSuccess: () => {
				navigate(`/templates/${id}`);
			}
		});
	};

	return (
		<MainLayout isLoading={isLoading || isPending}>
			<Box sx={{ width: '100%', p: 5 }}>
				<Toolbar />
				<Grid container spacing={10}>
					<Grid size={6}>
						<Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
							<Tooltip title={t('header.backToTemplates')}>
								<IconButton sx={{ color: 'text.secondary' }} onClick={handleBack}>
									<ChevronLeft />
								</IconButton>
							</Tooltip>
							<Typography variant="h1" gutterBottom> {t('templates.create')}</Typography>
						</Stack>
						<TextField
							data-cy="template-name"
							sx={{ mt: 4 }}
							variant="outlined"
							autoFocus
							margin="dense"
							id="name"
							label={t('templates.templateName')}
							fullWidth
							onChange={handleName}
						/>
						<FormControl component="fieldset" style={{ marginTop: 20, marginLeft: 5, }}>
							<FormLabel component="legend">{t('templates.templateView')}:</FormLabel>
							<RadioGroup data-cy="template-view" name="view" value={view} onChange={handleView} sx={{ gap: 1 }}>
								<FormControlLabel data-cy="template-view-list" value="*CHECKLIST" control={<Radio />} label={t('templates.typeList')} />
								<FormControlLabel data-cy="template-view-form" value="*FORM" control={<Radio />} label={t('templates.typeForm')} />
								<FormControlLabel data-cy="template-view-play" value="*PLAY" control={<Radio />} label={t('templates.typePlay')} />
							</RadioGroup>
						</FormControl>
						<FormControl fullWidth sx={{ mt: 4 }}>
							<InputLabel id="folder-select-label">{t('common.folder')}</InputLabel>
							<Select
								data-cy="folder-select"
								labelId="folder-select-label"
								id="folder-select"
								value={folder}
								label={t('common.folder')}
								onChange={handleFolderChange}
							>
								<MenuItem value="" data-cy="folder-none">
									<em>{t('common.none')}</em>
								</MenuItem>
								{team?.folders?.map((folder) => (
									<MenuItem key={folder.id} value={folder.id} data-cy="folder-option">
										{folder.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Stack direction="row" spacing={2} sx={{ mt: 6, justifyContent: 'space-between' }}>
							<Button onClick={handleLibrary} variant="outlined" tabIndex={-1} data-cy="templates-library">{t('templates.library')}</Button>
							<Button onClick={handleCreate} variant="outlined" sx={{ minWidth: 220 }} disabled={!validName || isLoading} data-cy="template-create-button">{t('common.create')}</Button>
						</Stack>
					</Grid>
					<Grid size={6}>
						{view === '*CHECKLIST' &&
							<div style={{ maxWidth: 375 }}>
								{/* <Typography variant="h6">Checklist view</Typography> */}
								<img alt="checklist view" src="https://checklist.com/assets/images/docs/views/checklist-view.png" style={{ maxWidth: 375 }} data-cy="checklist-image" />
							</div>
						}
						{view === '*FORM' &&
							<div style={{ maxWidth: 375 }}>
								{/* <Typography variant="h6">Checklist view</Typography> */}
								<img alt="checklist view" src="https://checklist.com/assets/images/docs/views/form-view.png" style={{ maxWidth: 375 }} data-cy="form-image" />
							</div>
						}
						{view === '*PLAY' &&
							<div style={{ maxWidth: 375 }}>
								{/* <Typography variant="h6">Checklist view</Typography> */}
								<img alt="checklist view" src="https://checklist.com/assets/images/docs/views/play-view2.gif" style={{ maxWidth: 375 }} data-cy="play-image" />
							</div>
						}
					</Grid>
				</Grid>
			</Box>
		</MainLayout>
	)
}