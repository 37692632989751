import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useChecklist, useCreateChecklist } from "../hooks/useChecklist";
import { ITemplateMutations } from "../hooks/useTemplate";
import { ChecklistRes } from "../interfaces/api";
import { IField } from "../interfaces/IChecklist"
import { useMutationErrorHandler } from "../hooks/useErrorHandler";
import { useState } from "react";
import { generateChecklistID } from "../utils/ids";
import { useMixpanel } from "../utils/mixpanel";

// TODO: add a link to view a selected checklist

interface Props {
	team: string
	templateName: string
	field?: IField
	mutations: ITemplateMutations;
}
export default function IssuesChecklistPicker({ team, templateName, field, mutations }: Props) {
	const { t } = useTranslation();
	const handleError = useMutationErrorHandler();
	const mixpanel = useMixpanel();

	const { data: checklistsRes, isLoading } = useChecklist(team);
	const checklists: ChecklistRes[] = checklistsRes ? checklistsRes.content : [];
	const { mutate } = useCreateChecklist();

	const [open, setOpen] = useState(false)
	const [name, setName] = useState(t('templateEditor.issuesDefaultName', { templateName }))

	const issues = isLoading ? '' : field?.value || '' // we check the loading to avoid the Select having a value that is not yet in the options

	const handleIssuesChange = (event: SelectChangeEvent) => {
		const value = event.target.value;
		if (value === '+') {
			setOpen(true);
		} else {
			if (value !== issues) {
				mutations.updateField.mutate({
					field: 'issues',
					value: value
				},
					handleError(t('templateEditor.issuesUpdateError'))
				);
				mixpanel.track('template', {
					action: 'issues',
					issues: value,
					template: templateName,
				});
			}
		}
	};

	const handleClose = () => setOpen(false)

	const handleName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)

	const handleSubmit = async (event: React.FormEvent<HTMLDivElement>) => {
		event.preventDefault()
		// create the new checklist
		const id = generateChecklistID()
		mutate({
			id, name, team, type: 'issues'
		}, {
			onSuccess: () => {
				// set the new checklist only if the first mutation is successful
				mutations.updateField.mutate({
					field: 'issues',
					value: id,
				},
					handleError(t('templateEditor.issuesUpdateNewChecklistError'))
				);
				setOpen(false)
			},
			// TODO: enable the following which currently doees not compile
			// onError: handleError(t('templateEditor.issuesCreateError'))
		});
		mixpanel.track('template', {
			action: 'create-issue',
			template: templateName,
		});
	}

	return (
		<>
			<FormControl fullWidth size="small">
				<InputLabel id="issues-checklist-selector-label">{t('templateEditor.issuesLabel')}</InputLabel>
				<Select
					labelId="issues-checklist-selector-label"
					id="issues-checklist-selector"
					label={t('templateEditor.issuesLabel')}
					size="small"
					value={issues}
					onChange={handleIssuesChange}
				>
					<MenuItem value="">{t('templateEditor.issuesNoneOption')}</MenuItem>
					<MenuItem value="+">{t('templateEditor.issuesCreateNewOption')}</MenuItem>
					<MenuItem value="" disabled>{t('templateEditor.issuesSelectExistingOption')}</MenuItem>
					{checklists.map(checklist => (
						<MenuItem key={checklist.id} value={checklist.id}>{checklist.name}</MenuItem>
					))}
				</Select>
			</FormControl>
			<Dialog open={open} onClose={handleClose}
				PaperProps={{
					component: 'form',
					onSubmit: handleSubmit
				}}>
				<DialogTitle>{t('templateEditor.issuesCreateDialogTitle')}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t('templateEditor.issuesCreateDialogDescription')}</DialogContentText>
					<TextField
						autoFocus
						required
						margin="dense"
						id="name"
						name="name"
						label={t('templateEditor.issuesNameFieldLabel')}
						fullWidth
						variant="standard"
						value={name}
						onChange={handleName}
					/>
				</DialogContent>
				<DialogActions>
					<Button tabIndex={-1} onClick={handleClose}>{t('common.cancel')}</Button>
					<Button type="submit" disabled={!name.trim()}>{t('templateEditor.issuesCreateAndSetButton')}</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}