import { useState } from 'react';
import { Box, Toolbar, IconButton, Drawer, styled, Tooltip, Stack, Typography } from '@mui/material';
import { PanelLeft, PanelRight } from 'lucide-react';
import { TemplateRes } from '../interfaces/api';
import TemplateSettings from './TemplateSettings';
import TemplateTaskDetails from './TemplateTaskDetails';
import TemplateMain from './TemplateMain';
import { ITemplateMutations } from '../hooks/useTemplate';
import { getUICookie, setUICookie } from '../utils/cookies';
import TitleIconHelp from './TitleIconHelp';
import { useMixpanel } from '../utils/mixpanel';
import { useMutationErrorHandler } from '../hooks/useErrorHandler';
import { duplicateTasks, getParentPath, getPositionWithinParent } from '../utils/checklist.utils';
import { useTranslation } from 'react-i18next';
import MainLayout from '../Layout/MainLayout';

const leftDrawerWidth = 340;
const rightDrawerWidth = 400;

interface Props {
	isLoading: boolean;
	template?: TemplateRes;
	saving: boolean;
	mutations: ITemplateMutations;
}
const TemplateEditor = ({ isLoading, template, saving, mutations }: Props) => {
	const { t } = useTranslation();
	const UI_PREFERENCES = getUICookie();
	const mixpanel = useMixpanel();
	const handleError = useMutationErrorHandler();

	const [leftDrawerOpen, setLeftDrawerOpen] = useState(!(UI_PREFERENCES?.leftDrawerClosed ?? true));
	const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
	const [cursorId, setCursorId] = useState<string | null>(null); // we do not keep the cursor itself as it might change on template and then we get them not to be the same

	const cursor = template && template.tasks.find(t => t.id === cursorId) || null;

	const toggleLeftPanel = () => {
		const newValue = !leftDrawerOpen;
		setLeftDrawerOpen(newValue)
		UI_PREFERENCES.leftDrawerClosed = !newValue; // negate as we store closed 
		setUICookie(UI_PREFERENCES);
		mixpanel.track('template', {
			action: 'toggle_left_panel',
			open: newValue,
			template: template?.id,
		})
	}
	const toggleRightPanel = () => {
		const newValue = !rightDrawerOpen;
		setRightDrawerOpen(newValue)
		UI_PREFERENCES.rightDrawerClosed = !newValue; // negate as we store closed
		setUICookie(UI_PREFERENCES);
		mixpanel.track('template', {
			action: 'toggle_right_panel',
			open: newValue,
			template: template?.id,
		})
	}

	const handleCursor = (task: string | null) => {
		if (task === cursorId)
			setCursorId(null)
		else {
			// we should open the right drawer if it's not already open and the preferences allow for it
			if (!rightDrawerOpen && !UI_PREFERENCES.rightDrawerClosed) {
				setRightDrawerOpen(true)
			}
			setCursorId(task);
		}
	}

	const handleDuplicate = () => {
		if (template && cursor && cursorId) {
			// make local copies of the task (and any subtasks below it)
			const tasks = duplicateTasks(template?.tasks, cursor)
			const parentPath = getParentPath(cursor.path);
			const position = getPositionWithinParent(template.tasks, parentPath, cursor.path) + 1;
			const absolutePosition = template.tasks.map((t) => t.id).indexOf(cursorId) + tasks.length;
			mutations.addTasks.mutate({
				tasks,
				position,
				absolutePosition,
			}, handleError('Failed to duplicate tasks'))
			setCursorId(tasks[0].id)
			mixpanel.track('template', {
				action: 'duplicate-task',
				template: cursor.path.split('/')[1],
				task: cursor.id,
				size: tasks.length
			})
		}
	}
	return (
		<MainLayout
			isLoading={isLoading || saving}
			headerLeft={
				<Tooltip title={leftDrawerOpen ? t('header.hideSettings') : t('header.showSettings')}>
					<IconButton
						data-cy="leftDrawerToggle"
						onClick={toggleLeftPanel}
						sx={{
							color: leftDrawerOpen ? 'primary.main' : 'text.secondary',
							bgcolor: leftDrawerOpen ? 'primary.50' : 'transparent',
							transition: 'all 0.3s'
						}}
					>
						<PanelLeft />
					</IconButton>
				</Tooltip>
			}
			headerRight={
				(cursor || rightDrawerOpen) && <Tooltip title={rightDrawerOpen ? t('header.hideTaskDetails') : t('header.showTaskDetails')}>
					<IconButton
						data-cy="rightDrawerToggle"
						onClick={toggleRightPanel}
						sx={{
							color: rightDrawerOpen ? 'primary.main' : 'text.secondary',
							bgcolor: rightDrawerOpen ? 'primary.50' : 'transparent',
							transition: 'all 0.3s'
						}}
					>
						<PanelRight />
					</IconButton>
				</Tooltip>
			}>
			<Drawer
				variant="persistent"
				anchor="left"
				open={leftDrawerOpen}
				sx={{
					width: leftDrawerWidth,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: { width: leftDrawerWidth, boxSizing: 'border-box' },
				}}
			>
				<Toolbar />
				<Box sx={{ p: 3 }}>
					{template && <TemplateSettings template={template} mutations={mutations} />}
				</Box>
			</Drawer>

			<Main leftOpen={leftDrawerOpen} rightOpen={rightDrawerOpen}>
				<Toolbar />
				{template && <TemplateMain template={template} cursor={cursor} setCursor={handleCursor} mutations={mutations} />}
			</Main>

			<Drawer
				variant="persistent"
				anchor="right"
				open={rightDrawerOpen}
				sx={{
					width: rightDrawerWidth,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: { width: rightDrawerWidth, boxSizing: 'border-box' },
				}}
			>
				<Toolbar />
				<Box sx={{ p: 3 }}>
					{template && cursor ?
						<TemplateTaskDetails task={cursor} disableTypes={template.view === '*CHECKLIST'} onDuplicate={handleDuplicate} mutations={mutations} />
						:
						<Stack direction="column" spacing={2}>
							<TitleIconHelp
								variant="h2"
								title={t('templateEditor.taskDetailsTitle')}
								help={t('templateEditor.taskDetailsTitleHelp')}
								url="/templates/template-task"
							/>
							<Typography>{t('templateEditor.selectTask')}</Typography>
						</Stack>
					}
				</Box>
			</Drawer>
		</MainLayout>
	);
};

const Main = styled('main', {
	shouldForwardProp: (prop) => prop !== 'leftOpen' && prop !== 'rightOpen',
})<{
	leftOpen?: boolean;
	rightOpen?: boolean;
}>(({ theme, leftOpen, rightOpen }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	transition: theme.transitions.create(['margin-left', 'margin-right'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginLeft: leftOpen ? 0 : `-${leftDrawerWidth}px`,
	marginRight: rightOpen ? 0 : `-${rightDrawerWidth}px`,
}));

export default TemplateEditor;