import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Monitor, Moon, Sun } from 'lucide-react';
import { useState } from 'react';
import { ThemeMode, useThemeSwitcher } from '../contexts/ThemeSwitcherContext';
import { useMixpanel } from '../utils/mixpanel';
import { useTranslation } from 'react-i18next';

interface Props {
	iconSize?: number;
	menuIconSize?: number;
}
export default function ThemeSelector({ iconSize = 20, menuIconSize = 16 }: Props) {
	const { themeMode, setThemeMode } = useThemeSwitcher();
	const mixpanel = useMixpanel();
	const { t } = useTranslation();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		mixpanel.track('theme', {
			action: 'open-menu',
			theme: themeMode,
		});
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleThemeChange = (mode: ThemeMode) => () => {
		setThemeMode(mode);
		handleClose();
		mixpanel.track('theme', {
			action: 'change',
			theme: mode,
		});
	};

	const ThemeIcon = () => {
		switch (themeMode) {
			case 'light': return <Sun size={iconSize} />;
			case 'dark': return <Moon size={iconSize} />;
			default: return <Monitor size={iconSize} />;
		}
	};

	return (
		<>
			<Tooltip title={t('header.switchTheme')}>
				<IconButton onClick={handleClick}>
					<ThemeIcon />
				</IconButton>
			</Tooltip>

			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<MenuItem
					onClick={handleThemeChange('light')}
					selected={themeMode === 'light'}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<Sun size={menuIconSize} /> {t('header.light')}
					</Box>
				</MenuItem>
				<MenuItem
					onClick={handleThemeChange('dark')}
					selected={themeMode === 'dark'}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<Moon size={menuIconSize} /> {t('header.dark')}
					</Box>
				</MenuItem>
				<MenuItem
					onClick={handleThemeChange('system')}
					selected={themeMode === 'system'}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<Monitor size={menuIconSize} /> {t('header.system')}
					</Box>
				</MenuItem>
			</Menu>
		</>
	);
}