import { useState, useEffect, KeyboardEvent, ChangeEvent, useRef } from 'react';
import { TextField } from '@mui/material';

interface Props {
	dataCy: string;
	value: string;
	minRows: number;
	placeholder: string;
	onSuccess: (value: string) => void;
}

export default function AutoGrowTextField({ dataCy, value, minRows, placeholder, onSuccess, ...props }: Props) {
	const [editValue, setEditValue] = useState(value);
	const [focused, setFocused] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setEditValue(value);
	}, [value]);

	const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setEditValue(e.target.value);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Escape') {
			setEditValue(value);
			inputRef.current?.blur();
			return
		}
	};

	const handleFocus = () => setFocused(true);

	const handleBlur = () => {
		if (editValue !== value) {
			onSuccess(editValue);
		}
		setFocused(false);
	};

	return (
		<TextField
			fullWidth
			multiline
			placeholder={placeholder}
			minRows={minRows}
			maxRows={focused ? Infinity : minRows}
			value={editValue}
			inputRef={inputRef}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			onFocus={handleFocus}
			onBlur={handleBlur}
			variant='outlined'
			slotProps={{
				input: {
					inputProps: {
						'data-cy': dataCy
					}
				}
			}}
			{...props}
		/>
	);
};