import { TaskType } from './ITask'

export interface IChecklist {
    uid: string, // this is the uid that loaded this object and which it belongs to.

    id: string
    epoch: number
    archived?: string
    deleted?: string
    name: string
    // owner: string
    created: string
    timestamp: string
    tasks: ITask[]
    notes?: string
    folder: IChecklistFolder
    meta: IMeta[]
    fields?: IField[]
    path: string
    members: IMember[]
    permissions: string // TODO: change to enum
    status: number // TODO:change to enum
    team: string
    view: string
    type?: string
    sort?: string
    filter?: string
    moveCompleted?: string
    lang: string
    propagate: IPropagate
    totalTasks: number // TODO: need to update this on changes (and completed)
    completedTasks: number
    origin?: string
    code?: string
}

export interface ITask {
    id: string
    meta?: IMeta[]
    type?: string // I think this is never used. We use the one that is in the meta
    name: string
    origin?: string
    path: string
    level: number
    status: number // TODO: change to enum
    notes?: string
    created: string
    timestamp: string
    updatedBy?: string
    fields?: IField[]
}

export interface IPropagate {
    up: boolean
    down: boolean
}

export interface IMember {
    id: string
    avatar?: string
    email?: string
    name: string
    owner: boolean
}

export interface IField {
    key: string
    value: string
}

export interface IChecklistFolder {
    id?: string
    position: number
}

export interface IMeta {
    key: string
    label: string
    type: TaskTypes
    hidden?: boolean
    required?: boolean
    description?: string
    options?: string
    calc?: string
    permissions?: string[]
}

export interface IMetaUpdates {
    label?: string
    type?: TaskTypes
    hidden?: boolean
    required?: boolean
    description?: string
    options?: string
    calc?: string
    permissions?: string[]
}

export interface IMetaOptions {
    type: string
    values: IMetaOption[]
}

export interface IMetaOption {
    key: string
    label: string
    color: string
}

export const ChoiceOptionPalette = {
    red: '#FF0000',
    blue: '#0000ff',
    green: '#00FF00',
    yellow: 'yellow',
    cyan: 'cyan',
    lime: 'lime',
    gray: 'gray',
    orange: 'orange',
    purple: 'purple',
    black: 'black',
    white: 'white',
    pink: 'pink',
    darkblue: 'darkblue',
}

export const DEFAULT_CHOICE_OPTIONS: IMetaOptions = {
    type: "static",
    values: [
        {
            key: "ok",
            label: "OK",
            color: ChoiceOptionPalette.green,
        },
        {
            key: "nok",
            label: "Not OK",
            color: ChoiceOptionPalette.red,
        }
    ],
}

export const PRIORITY_CHOICE_OPTIONS: IMetaOptions = {
    type: "static",
    values: [
        {
            key: "1",
            label: "Low",
            color: "#F2D600",
        },
        {
            key: "5",
            label: "Medium",
            color: "#FF9F1A",
        },
        {
            key: "10",
            label: "High",
            color: "#EB5A46",
        }
    ],
}

export enum TaskTypes {
    Check = "CHECK",
    Choice = "CHOICE",
    Text = "TEXT",
    LongText = "LONGTEXT",
    Number = "NUMBER",
    DateTime = "DATETIME",
    Date = "DATE",
    Time = "TIME",
    Email = "EMAIL",
    URL = "URL",
    File = "FILE",
    Assign = "ASSIGN",
    Signature = 'SIGNATURE',
}

export const DEFAULT_ANSWER_META: IMeta = {
    key: "answer",
    label: "Answer",
    type: TaskTypes.Check,
    required: false,
}

export interface IFile {
    id: string
    url?: string
    type: string // mime
    filename: string
    size: number
    lastModified: number
}

export interface IColumn {
    key: string
    label: string
}

export interface IDisplayFolder {
    id: string
    name: string
    checklists: IChecklist[]
}

export interface IMultiTask {
    name: string
    depth: number
    status?: number
    notes?: string
    fields?: IField[] // priority, due-date
    type?: TaskType | TaskTypes // this was used for trip plan tasks (checklist not template) and not really used here. see how to clean
}

export interface ITaskOfChecklist {
    name: string
    checklist: string
    checklistId: string
    priority?: number
    dueDate?: Date
    path: string
    status: number
}

export interface IIssue {
    id: string
    name: string
    notes?: string
}