import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { HelpCircle, LucideIcon } from "lucide-react";
import { useMixpanel } from "../utils/mixpanel";

interface Props {
	variant?: React.ComponentProps<typeof Typography>['variant'];
	icon?: LucideIcon;
	title?: string;
	help?: string;
	url?: string;
}
export default function TitleIconHelp({ variant = 'h6', icon: Icon, title, help, url }: Props) {
	const mixpanel = useMixpanel();

	const handleDocsOpen = () => {
		window.open(`https://checklist.com/docs/${url}`, '_blank');
		mixpanel.track('template', {
			action: 'help',
			template: `${url}`,
		});
	}

	return (
		<Stack direction="row" spacing={1} alignItems="center">
			{Icon && <Icon size={20} />}
			{title && <Typography variant={variant}>{title}</Typography>}
			{help && <Tooltip title={help}>
				<IconButton onClick={handleDocsOpen} tabIndex={-1}>
					<HelpCircle size={20} />
				</IconButton>
			</Tooltip>}
		</Stack>
	)
}
