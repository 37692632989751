import { UserRes, TimezoneRes, CountryRes, AppRes } from "../interfaces/api";
import { apiConfig, ApiResponse, BaseApiClient } from "./apiClient";

export class UserApi extends BaseApiClient {
	private readonly BASE_PATH = 'users';

	async getUser(): Promise<ApiResponse<UserRes>> {
		return this.get(`${this.BASE_PATH}`);
	}

	async updateUser(fieldName: string, fieldValue: string): Promise<ApiResponse<UserRes>> {
		return this.put(`${this.BASE_PATH}/${fieldName}`, { fieldValue });
	}

	async updateUserProp(key: string, value: string): Promise<ApiResponse<UserRes>> {
		return this.put(`${this.BASE_PATH}/props`, { key, value });
	}

	async getTimezones(): Promise<ApiResponse<TimezoneRes[]>> {
		return this.get('common/timezones');
	}

	async getCountries(): Promise<ApiResponse<CountryRes[]>> {
		return this.get(`${this.BASE_PATH}/countries`);
	}

	// following not working because data does not exist on Delete. We need to add or find a solution
	// async closeAccount(reason: string): Promise<ApiResponse<void>> {
	//     return this.delete(`${this.BASE_PATH}`, { data: { reason } });
	// }

	async changePassword(oldPassword: string, password: string): Promise<ApiResponse<void>> {
		return this.post(`${this.BASE_PATH}/passwordChange`, { oldPassword, password });
	}

	async requestEmailChange(email: string): Promise<ApiResponse<void>> {
		return this.post(`${this.BASE_PATH}/emailChangeRequest`, { email });
	}

	async confirmEmailChange(email: string, resetCode: string): Promise<ApiResponse<void>> {
		return this.post(`${this.BASE_PATH}/emailChange`, { email, resetCode });
	}

	async getApps(): Promise<ApiResponse<AppRes[]>> {
		return this.get(`${this.BASE_PATH}/apps`);
	}

	async removeApp(id: string): Promise<ApiResponse<void>> {
		return this.delete(`${this.BASE_PATH}/apps/${id}`);
	}

	async testApp(): Promise<ApiResponse<void>> {
		return this.get(`${this.BASE_PATH}/test`);
	}
}

export const apiUser = new UserApi(apiConfig);