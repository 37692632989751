import { useQuery } from "@tanstack/react-query";
import { apiUser } from "../api/userApiClient";

export function useUser() {
    return useQuery({
        queryKey: ['user'],
        queryFn: async () => {
            const response = await apiUser.getUser();
            if (response.error) throw response.error;
            return response.data;
        },
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
}