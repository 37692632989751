import { useState, useRef, KeyboardEvent, useEffect } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import type { LucideIcon } from 'lucide-react';
import { regexpUrl } from '../../utils/validation';

interface Props {
	value: string;
	type?: 'number' | 'text' | 'url' | 'email';
	placeholder?: string;
	icon?: LucideIcon;
	iconHandler?: () => void;
	onSuccess: (value: string) => void;
}

export default function MultiTypeField({ value, type = 'text', placeholder, icon: Icon, iconHandler, onSuccess }: Props) {

	const inputRef = useRef<HTMLInputElement>(null);

	const [currentValue, setCurrentValue] = useState(value);

	useEffect(() => {
		setCurrentValue(value);
	}, [value]);

	const isValidUrl = regexpUrl.test(currentValue);
	const isError = type === 'url' && !!currentValue && !isValidUrl;

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (!isError) {
				onSuccess(currentValue);
				inputRef.current?.blur();
			}
		} else if (event.key === 'Escape') {
			event.preventDefault();
			setCurrentValue(value);
			inputRef.current?.blur();
		}
	};

	const handleBlur = () => {
		if (isError) {
			setCurrentValue(value);
		} else {
			onSuccess(currentValue);
		}
	};

	const endAdornment = Icon && (
		<InputAdornment position="end">
			<IconButton
				edge="end"
				onClick={iconHandler}
				size="small"
				sx={{ mr: -0.5 }}
				disabled={!iconHandler}
			>
				<Icon size={20} />
			</IconButton>
		</InputAdornment>
	);

	return (
		<TextField
			inputRef={inputRef}
			fullWidth
			size="small"
			placeholder={placeholder}
			type={type}
			value={currentValue}
			onChange={(e) => setCurrentValue(e.target.value)}
			onKeyDown={handleKeyDown}
			onBlur={handleBlur}
			error={Boolean(isError)}
			helperText={isError ? 'Please enter a valid URL' : undefined}
			InputProps={{
				endAdornment: endAdornment
			}}
		/>
	);
}