import { useTranslation } from 'react-i18next'; import { ITemplateMutations } from "../hooks/useTemplate";
import { TemplateRes } from "../interfaces/api";
import { Typography, FormControl, FormControlLabel, RadioGroup, Radio, Button, Switch, Stack, Paper, Divider } from '@mui/material';
import { LayoutGrid, ListChecks, FormInput, Play, AlertCircle, FileBarChart, Eye, ArrowUpDown, ClipboardList, ArrowDown, ArrowUp, } from 'lucide-react';
import TitleIconHelp from "./TitleIconHelp";
import { useMutationErrorHandler } from "../hooks/useErrorHandler";
import IssuesChecklistPicker from "./IssuesChecklistPicker";
import { useMixpanel } from "../utils/mixpanel";
import { ChangeEvent } from "react";
import { useCurrentTeam } from '../contexts/CurrentTeamContext';

interface Props {
	template: TemplateRes;
	mutations: ITemplateMutations;
}
const TemplateSettings = ({ template, mutations }: Props) => {
	const { t } = useTranslation();
	const handleError = useMutationErrorHandler();
	const { team } = useCurrentTeam();
	const mixpanel = useMixpanel();

	const handleViewChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		mutations.update.mutate({
			name: 'view',
			value: value
		},
			handleError(t('templateEditor.viewUpdateError'))
		);
		mixpanel.track('template', {
			action: 'view',
			view: value,
			template: template.id,
		})
	};

	const handleVisibilityChange = async (event: ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked
		if (checked) {
			mutations.addVisibility.mutate(
				template.owner.team,
				handleError(t('templateEditor.visibilityAddError'))
			);
		} else {
			mutations.deleteVisibility.mutate(
				template.owner.team,
				handleError(t('templateEditor.visibilityRemoveError'))
			);
		}
		mixpanel.track('template', {
			action: 'visibility',
			visibility: checked,
			template: template.id,
		})
	};

	const handlePropagationChange = (type: 'up' | 'down') => (event: ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked;
		mutations.update.mutate({
			name: `propagate.${type}`,
			value: checked.toString()
		},
			handleError(t('templateEditor.propagationUpdateError', { type }))
		);
		mixpanel.track('template', {
			action: 'propagate',
			propagate: type,
			value: checked,
			template: template.id,
		})
	};

	const handleReportsCustomize = () => {
		console.log('Reports customize clicked');
		mixpanel.track('template', {
			action: 'customize-reports',
			template: template.id,
		})
	};

	return (
		<Stack spacing={3}>

			<TitleIconHelp
				variant="h2"
				title={t('templateEditor.title')}
				help={t('templateEditor.help')}
				url="/templates/template-settings"
			/>

			{/* View Section */}
			<Paper elevation={0}>
				<Stack spacing={2}>
					<TitleIconHelp
						icon={LayoutGrid}
						title={t('templateEditor.defaultViewTitle')}
						help={t('templateEditor.defaultViewHelp')}
						url="/templates/template-settings#view-type"
					/>
					<FormControl>
						<RadioGroup
							data-cy="template-view"
							value={template.view}
							onChange={handleViewChange}
						>
							<FormControlLabel
								data-cy="template-view-list"
								value="*CHECKLIST"
								control={<Radio />}
								label={
									<Stack direction="row" spacing={1} alignItems="center">
										<ListChecks size={18} />
										<Typography>{t('templateEditor.listView')}</Typography>
									</Stack>
								}
							/>
							<FormControlLabel
								data-cy="template-view-form"
								value="*FORM"
								control={<Radio />}
								label={
									<Stack direction="row" spacing={1} alignItems="center">
										<FormInput size={18} />
										<Typography>{t('templateEditor.formView')}</Typography>
									</Stack>
								}
							/>
							<FormControlLabel
								data-cy="template-view-play"
								value="*PLAY"
								control={<Radio />}
								label={
									<Stack direction="row" spacing={1} alignItems="center">
										<Play size={18} />
										<Typography>{t('templateEditor.playView')}</Typography>
									</Stack>
								}
							/>
						</RadioGroup>
					</FormControl>
				</Stack>
			</Paper>
			<Divider />

			{/* Issues Section - Placeholder */}
			<Paper elevation={0}>
				<Stack spacing={2}>
					<TitleIconHelp
						icon={AlertCircle}
						title={t('templateEditor.issuesTitle')}
						help={t('templateEditor.issuesHelp')}
						url="/templates/template-settings#issues"
					/>
					<IssuesChecklistPicker
						team={template.owner.team}
						templateName={template.name}
						field={template.fields?.find(f => f.key === 'issues')}
						mutations={mutations}
					/>
				</Stack>
			</Paper>
			<Divider />

			{/* Reports Section - Placeholder */}
			<Paper elevation={0}>
				<Stack spacing={2}>
					<TitleIconHelp
						icon={FileBarChart}
						title={t('templateEditor.reportsTitle')}
						help={t('templateEditor.reportsHelp')}
						url="/templates/template-settings#reports"
					/>
					<Button
						variant="outlined"
						startIcon={<FileBarChart size={18} />}
						onClick={handleReportsCustomize}
					>
						{t('templateEditor.customizeReports')}
					</Button>
				</Stack>
			</Paper>
			<Divider />

			{/* Visibility Section */}
			<Paper elevation={0}>
				<Stack spacing={2}>
					<TitleIconHelp
						icon={Eye}
						title={t('templateEditor.visibilityTitle')}
						help={t('templateEditor.visibilityHelp')}
						url="/templates/template-settings#visibility"
					/>
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Typography variant="body2">{t('templateEditor.shareWithTeam')}</Typography>
						<Switch
							checked={template.visibility?.includes(template.owner.team)}
							onChange={handleVisibilityChange}
						/>
					</Stack>
				</Stack>
			</Paper>
			<Divider />

			{/* Propagation Section */}
			<Paper elevation={0}>
				<Stack spacing={2}>
					<TitleIconHelp
						icon={ArrowUpDown}
						title={t('templateEditor.propagationTitle')}
						help={t('templateEditor.propagationHelp')}
						url="/templates/template-settings"
					/>
					<Stack spacing={2}>
						<FormControlLabel
							control={
								<Switch
									checked={template.propagate?.up}
									onChange={handlePropagationChange('up')}
								/>
							}
							label={
								<Typography variant="body2">
									<b><ArrowUp size="14" /> {t('templateEditor.upwardPropagation')}</b>. {t('templateEditor.parentTasks')}
								</Typography>
							}
						/>
						<FormControlLabel
							control={
								<Switch
									checked={template.propagate?.down}
									onChange={handlePropagationChange('down')}
								/>
							}
							label={
								<Typography variant="body2">
									<b><ArrowDown size="14" /> {t('templateEditor.downwardPropagation')}</b>. {t('templateEditor.childTasks')}
								</Typography>
							}
						/>
					</Stack>
				</Stack>
			</Paper>
			<Divider />

			{/* Details Section */}
			<Paper elevation={0}>
				<Stack spacing={2}>
					<TitleIconHelp
						icon={ClipboardList}
						title={t('templateEditor.detailsTitle')}
					/>
					<Stack spacing={1}>
						{team && <Typography variant="body2" color="text.secondary">
							<strong>{t('templateEditor.teamLabel')}:</strong> {team.name}
						</Typography>}
						<Typography variant="body2" color="text.secondary">
							<strong>{t('templateEditor.createdLabel')}:</strong> {new Date(template.created).toLocaleDateString()}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							<strong>{t('templateEditor.lastUpdatedLabel')}:</strong> {new Date(template.timestamp).toLocaleDateString()}
						</Typography>
					</Stack>
				</Stack>
			</Paper>
		</Stack>
	);
};

export default TemplateSettings;