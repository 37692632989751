import axios, { AxiosInstance, AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig, RawAxiosRequestHeaders, AxiosResponse } from 'axios';
import { getLoginCookie, ILogin, setLoginCookie } from '../utils/cookies';
import { DEV_ENV, TEST_API } from '../utils/const';
import { redirectToLogin } from '../utils/app.utils';

// we specifically make it work always in PROD mode
/* 
Can implement:
Use of API Client
Automatic token handling
Request cancellation
Request retry
Request queuing 
Response caching
Offline support
Rate limiting
Batch requests
Error handling with consistent error objects
Typescript types
Automatic Json parsing
*/

export interface ApiError {
	code: string;
	message: string;
	details?: any;
	isRetryable?: boolean;
}

export interface ApiResponse<T> {
	data?: T;
	error?: ApiError;
}

interface ApiClientConfig {
	baseURL: string;
	timeout?: number;
	retryConfig?: {
		maxRetries: number;
		retryDelay: number;
	};
}

interface ExtendedAxiosRequestConfig extends InternalAxiosRequestConfig {
	retryCount?: number;
}

export interface RequestConfig extends Omit<AxiosRequestConfig, 'url' | 'method' | 'data'> {
	headers?: RawAxiosRequestHeaders;
	params?: Record<string, any>;
	signal?: AbortSignal;
}
const API_HOST = DEV_ENV ? TEST_API : 'https://api5.checklist.com'
export const apiConfig: ApiClientConfig = {
	baseURL: `${API_HOST}/api/v2`,
	retryConfig: {
		maxRetries: 3,
		retryDelay: 1000,
	}
};

export class BaseApiClient {
	protected client: AxiosInstance;
	private retryConfig;
	// private mixpanelId = MIXPANEL.get_distinct_id();

	constructor(config: ApiClientConfig) {
		this.retryConfig = config.retryConfig || { maxRetries: 3, retryDelay: 1000 };

		this.client = axios.create({
			baseURL: config.baseURL,
			timeout: config.timeout || 10000,
			headers: {
				'Content-Type': 'application/json',
				'client': 'checklist',
			},
		});

		this.setupInterceptors();
	}

	private setupInterceptors() {
		this.client.interceptors.request.use((config: ExtendedAxiosRequestConfig) => {
			const auth = getLoginCookie();
			if (auth && auth.isAuthenticated && auth.token) {
				config.headers = config.headers || {};
				config.headers.Authorization = `Bearer ${auth.token}`;
			}
			return config;
		});

		this.client.interceptors.response.use(
			(response) => response,
			async (error: AxiosError) => {
				const originalConfig = error.config as ExtendedAxiosRequestConfig | undefined;
				// is token expired?
				if (error.response?.status === 401) {
					// refresh token
					const auth = getLoginCookie();
					try {
						const tokenResponse = await axios.get(`${API_HOST}/oauth/token`, {
							headers: {
								// mixpanel: mixpanelId, TODO: add mixpanel id
							},
							params: {
								client_id: 'checklist',
								grant_type: 'refresh_token',
								refresh_token: auth?.refreshToken,
							}
						});
						if (tokenResponse) {
							const { access_token: accessToken, refresh_token: refreshToken, expires_in: expires } = tokenResponse.data;
							const newAuth: ILogin = {
								...auth!,
								token: accessToken,
								refreshToken: refreshToken,
								expires,
								expiresTime: new Date(Date.now() + expires * 1000).toISOString(),
								isAuthenticated: true,
							}
							setLoginCookie(newAuth);
							console.log('Token refreshed', newAuth);
							// retry request
							if (originalConfig) {
								console.log("retrying request");
								originalConfig.headers = originalConfig.headers || {};
								originalConfig.headers.Authorization = `Bearer ${accessToken}`;
								try {
									const retryResponse = await this.client(originalConfig);
									console.log("Retry successful", retryResponse.status);
									return retryResponse;
								} catch (retryError) {
									console.error("Retry failed", retryError);
									throw retryError;
								}
							}
						}
					} catch (e) {
						console.error('Failed to refresh token', e);
					}
					// alert('Token expired and would not refresh');
					const newAuth = { ...auth!, isAuthenticated: false };
					setLoginCookie(newAuth);
					// for now we redirect to login page. Later we can implement better error handling and redirect logic
					redirectToLogin();
					return Promise.reject({ code: 'UNAUTHORIZED', message: 'Token expired' });
				}
				const apiError: ApiError = {
					code: (error.response?.data as any)?.code || 'UNKNOWN_ERROR',
					message: (error.response?.data as any)?.message || 'An unknown error occurred',
					details: (error.response?.data as any)?.details,
					isRetryable: this.isRetryableError(error),
				};

				if (apiError.isRetryable && originalConfig &&
					(!originalConfig.retryCount || originalConfig.retryCount < this.retryConfig.maxRetries)) {
					originalConfig.retryCount = (originalConfig.retryCount || 0) + 1;
					await new Promise(resolve => setTimeout(resolve, this.retryConfig.retryDelay));
					return this.client(originalConfig);
				}

				return Promise.reject(apiError);
			}
		);
	}

	private isRetryableError(error: AxiosError): boolean {
		if (!error.response) return true;
		if (error.response.status >= 500) return true;
		if (error.response.status === 429) return true;
		return false;
	}

	protected async get<T>(
		path: string,
		config?: RequestConfig
	): Promise<ApiResponse<T>> {
		try {
			const response: AxiosResponse<T> = await this.client.get<T>(path, {
				...config,
				headers: {
					...this.client.defaults.headers.common,
					...config?.headers,
				},
			});
			return { data: response.data };
		} catch (error) {
			if (axios.isCancel(error)) {
				return { error: { code: 'CANCELLED', message: 'Request was cancelled' } };
			}
			return { error: error as ApiError };
		}
	}

	protected async post<T, D = any>(
		path: string,
		data?: D,
		config?: RequestConfig
	): Promise<ApiResponse<T>> {
		try {
			const response: AxiosResponse<T> = await this.client.post<T>(path, data, {
				...config,
				headers: {
					...this.client.defaults.headers.common,
					...config?.headers,
				},
			});
			return { data: response.data };
		} catch (error) {
			return { error: error as ApiError };
		}
	}

	protected async put<T, D = any>(
		path: string,
		data?: D,
		config?: RequestConfig
	): Promise<ApiResponse<T>> {
		try {
			const response: AxiosResponse<T> = await this.client.put<T>(path, data, {
				...config,
				headers: {
					...this.client.defaults.headers.common,
					...config?.headers,
				},
			});
			return { data: response.data };
		} catch (error) {
			return { error: error as ApiError };
		}
	}

	protected async delete<T, D = any>(
		path: string,
		data?: D,
		config?: RequestConfig
	): Promise<ApiResponse<T>> {
		try {
			const response: AxiosResponse<T> = await this.client.delete<T>(path, {
				...config,
				headers: {
					...this.client.defaults.headers.common,
					...config?.headers,
				},
				data: data, // This is how you send the body with a DELETE request
			});
			return { data: response.data };
		} catch (error) {
			return { error: error as ApiError };
		}
	}
}