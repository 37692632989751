import { useState, KeyboardEvent } from 'react';
import { Stack, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { ListPlus, Plus, X } from 'lucide-react';
import { TaskTypeSelector } from './TaskTypeSelector';
import { useMixpanel } from '../utils/mixpanel';
import { useTranslation } from 'react-i18next';

interface Props {
	selectedType: string;
	isMulti: boolean;
	canHaveTypes: boolean;
	toggleMulti: () => void;
	onTypeChange: (type: string) => void;
	onSuccess: (names: string[]) => void; // array of task names to add.
}
export default function TemplateAddTask({ selectedType, isMulti, canHaveTypes, toggleMulti, onTypeChange, onSuccess }: Props) {
	const mixpanel = useMixpanel();
	const { t } = useTranslation();

	const [name, setName] = useState("");

	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter' && !isMulti) {
			e.preventDefault();
			if (name.trim()) {
				handleSubmit();
			}
		};
	}

	const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	}

	const handleSubmit = () => {
		if (name.trim()) {
			const taskNames = name.split('\n').map(n => n.trim()).filter(n => n);
			onSuccess(taskNames);
			setName("");
			if (isMulti) toggleMulti();
			mixpanel.track('template', {
				action: isMulti ? 'add-multiple-tasks' : 'add-task',
				count: taskNames.length,
			});
		}
	}

	const handleClear = () => {
		setName("")
		mixpanel.track('template', {
			action: 'clear-task-name',
		})
	}

	const toggleMultiAdd = () => {
		toggleMulti();
		mixpanel.track('template', {
			action: 'toggle-multi-add',
			state: !isMulti,
		});
	}

	return (
		<Stack direction="row" spacing={2} width="100%">
			{canHaveTypes && <TaskTypeSelector
				text drop
				type={selectedType}
				onTypeChange={onTypeChange}
			/>}
			<TextField
				data-cy="add-task-name-input"
				data-mode={isMulti ? 'multi' : 'single'}
				fullWidth
				multiline={isMulti}
				minRows={isMulti ? 4 : 1}
				maxRows={10}
				variant="outlined"
				size="small"
				placeholder={isMulti
					? t('templateEditor.addMultipleTasksPlaceholder') + ' ' + t('templateEditor.shiftEnterToSubmit')
					: t('templateEditor.addTaskPlaceholder')
				}
				value={name}
				onChange={onNameChange}
				onKeyDown={handleKeyDown}
				slotProps={{
					input: {
						endAdornment: (
							<InputAdornment position="end" style={isMulti ? { alignItems: 'flex-start', paddingTop: '8px', alignSelf: 'flex-start' } : undefined}>
								{!!name &&
									<Tooltip title={t('templateEditor.clearTaskName')}>
										<IconButton data-cy="add-task-clear" onClick={handleClear} aria-label={t('templateEditor.clearTaskName')}>
											<X />
										</IconButton>
									</Tooltip>
								}
								{isMulti && <Tooltip title={t('templateEditor.addTasks')}>
									<span>
										<IconButton data-cy="add-task-submit" onClick={handleSubmit} aria-label={t('templateEditor.addTask')} disabled={!name.trim()}>
											<Plus />
										</IconButton>
									</span>
								</Tooltip>}
								<Tooltip title={isMulti ? t('templateEditor.singleAddMode') : t('templateEditor.multiAddMode')}>
									<IconButton
										data-cy="multi-add-button"
										onClick={toggleMultiAdd}
										aria-label={t('templateEditor.toggleMultiAdd')}
										color={isMulti ? "primary" : "default"}
									>
										<ListPlus />
									</IconButton>
								</Tooltip>

							</InputAdornment>
						)
					}
				}}
			/>
		</Stack >
	);
};