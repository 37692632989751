import Cookies from 'js-cookie';
import { DEV_ENV } from './const';

// Cookie configuration
export const COOKIE_OPTIONS = {
	// For localhost, omit domain completely
	...(DEV_ENV ? {} : { domain: ".checklist.com" }),
	expires: 365, // days
	secure: !DEV_ENV, // Set secure to false in development
	sameSite: "strict" as const,
} as const;

const COOKIE_NAME = "CHECKLIST_LOGIN";
const REDIRECT_COOKIE_NAME = "CHECKLIST_REDIRECT";
const UI_COOKIE_NAME = "CHECKLIST_UI";

export interface ILogin {
	id: string | null // uid
	email: string
	token: string
	refreshToken: string
	expires: number // how long still
	expiresTime: string // when ISO date
	isAuthenticated: boolean
	epoch?: number
	clientId?: string
}

/**
 * Sets the login information in cookies
 * @param loginData Login data to be stored
 * @returns void
 */
export const setLoginCookie = (loginData: ILogin): void => {
	try {
		// Add epoch timestamp when storing
		const dataToStore = {
			...loginData,
			epoch: Date.now()
		};
		Cookies.set(COOKIE_NAME, JSON.stringify(dataToStore), COOKIE_OPTIONS);
	} catch (error) {
		console.error('Error setting login cookie:', error);
		throw new Error('Failed to set login cookie');
	}
};

/**
 * Gets the login information from cookies
 * @returns ILogin object or null if not found
 */
export const getLoginCookie = (): ILogin | null => {
	try {
		const loginData = Cookies.get(COOKIE_NAME);
		if (!loginData) return null;
		return JSON.parse(loginData) as ILogin;
	} catch (error) {
		console.error('Error reading login cookie:', error);
		return null;
	}
};

/**
 * Removes the login cookie
 * @returns void
 */
export const removeLoginCookie = (): void => {
	try {
		Cookies.remove(COOKIE_NAME, {
			domain: COOKIE_OPTIONS.domain,
			path: '/'
		});
	} catch (error) {
		console.error('Error removing login cookie:', error);
	}
};

/**
 * Checks if the login cookie is valid and not expired
 * @returns boolean
 */
export const isLoginValid = (): boolean => {
	const loginData = getLoginCookie();
	if (!loginData) return false;
	return (
		loginData.isAuthenticated &&
		!!loginData.token &&
		loginData.expires > Date.now()
	);
};

/**
 * Updates specific fields in the login cookie
 * @param updates Partial login data to update
 * @returns void
 */
export const updateLoginCookie = (updates: Partial<ILogin>): void => {
	const currentLogin = getLoginCookie();
	if (!currentLogin) {
		throw new Error('No login cookie found to update');
	}
	const updatedLogin = {
		...currentLogin,
		...updates,
		epoch: Date.now() // Update epoch on changes
	};
	setLoginCookie(updatedLogin);
};

/**
 * Sets the redirect in cookies
 * @param redirect data to be stored
 * @returns void
 */
export const setRedirectCookie = (redirect: string): void => {
	try {
		Cookies.set(REDIRECT_COOKIE_NAME, redirect, COOKIE_OPTIONS);
	} catch (error) {
		console.error('Error setting redirect cookie:', error);
		throw new Error('Failed to set redirect cookie');
	}
};

/**
 * Gets the redirect from cookies
 * @returns string or null if not found
 */
export const getRedirectCookie = (): string | null => {
	try {
		const redirect = Cookies.get(REDIRECT_COOKIE_NAME);
		return redirect || null;
	} catch (error) {
		console.error('Error reading redirect cookie:', error);
		return null;
	}
};

/**
 * Removes the redirect cookie
 * @returns void
 */
export const removeRedirectCookie = (): void => {
	try {
		Cookies.remove(REDIRECT_COOKIE_NAME, {
			domain: COOKIE_OPTIONS.domain,
			path: '/'
		});
	} catch (error) {
		console.error('Error removing redirect cookie:', error);
	}
};

interface IUI {
	leftDrawerClosed: boolean
	rightDrawerClosed: boolean
}
const DEFAULT_UI: IUI = {
	leftDrawerClosed: false,
	rightDrawerClosed: false
};
/**
 * Sets the UI settings in cookies
 * @param ui data to be stored
 * @returns void
 */
export const setUICookie = (ui: IUI): void => {
	try {
		Cookies.set(UI_COOKIE_NAME, JSON.stringify(ui), COOKIE_OPTIONS);
	} catch (error) {
		console.error('Error setting ui cookie:', error);
		throw new Error('Failed to set ui cookie');
	}
};

/**
 * Gets the UI from cookies
 * @returns IUI object
 */
export const getUICookie = (): IUI => {
	try {
		const ui = JSON.parse(Cookies.get(UI_COOKIE_NAME) || JSON.stringify(DEFAULT_UI));
		if (ui) return ui
	} catch (error) {
		console.error('Error reading redirect cookie:', error);
	}
	return {
		leftDrawerClosed: false,
		rightDrawerClosed: false,
	};
};