import { Link as MUILink, Button, ListItem, SxProps, Theme } from '@mui/material';
import { Link as RouterLink, LinkProps } from 'react-router';

type CustomRouterLinkProps = {
  to: string;
  children: React.ReactNode;
  component?: 'link' | 'button' | 'list-item';
  variant?: 'text' | 'contained' | 'outlined';
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
  sx?: SxProps<Theme>;
} & Omit<LinkProps, 'to' | 'children'>;

function CustomRouterLink({ 
  to, 
  children, 
  component = 'link',
  variant = 'text',
  color = 'primary',
  sx,
  ...props 
}: CustomRouterLinkProps) {
  switch (component) {
    case 'button':
      return (
        <Button
          component={RouterLink}
          to={to}
          variant={variant}
          color={color}
          sx={sx}
          {...props}
        >
          {children}
        </Button>
      );
    case 'list-item':
      return (
        <ListItem
          component={RouterLink}
          to={to}
          sx={sx}
          {...props}
        >
          {children}
        </ListItem>
      );
    default:
      return (
        <MUILink
          component={RouterLink}
          to={to}
          color={color}
          underline="hover"
          sx={sx}
          {...props}
        >
          {children}
        </MUILink>
      );
  }
}

export default CustomRouterLink;

/* Usage:

import CustomRouterLink from '../components/RouterLink';

function MyComponent() {
  return (
	<div>
	  <CustomRouterLink to="/templates">
		Templates
	  </CustomRouterLink>

	  <CustomRouterLink 
		to="/theme" 
		component="button" 
		variant="contained"
	  >
		Theme
	  </CustomRouterLink>

	  <CustomRouterLink to="/home" component="list-item">
		Home
	  </CustomRouterLink>
	</div>
  );
}
*/