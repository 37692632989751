import { ReactElement, ReactNode, } from "react"
import { AppBar, Toolbar, Box, Stack } from "@mui/material"
import { t } from "i18next"
import LanguageSwitcher from "../components/LanguageSwitcher"
import ThemeSelector from "../components/ThemeSelector"
import TitleIconHelp from "../TemplateEditor/TitleIconHelp"
import MainMenu from "./MainMenu"
import AccountMenu from "./AccountMenu"
import Loader from "../components/Loader"

interface Props {
	isLoading?: boolean
	headerLeft?: ReactElement
	headerRight?: ReactNode
	help?: {
		url: string;
		title: string;
	}
	children: ReactNode
}
export default function MainLayout({ isLoading, headerLeft, headerRight, help, children }: Props) {

	return (
		<Box sx={{ display: 'flex' }}>
			<AppBar position="fixed" color="default" elevation={1} sx={{ bgcolor: 'background.paper', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
				<Toolbar sx={{ gap: 1, display: 'flex', justifyContent: 'space-between' }}>
					{/* Header left */}
					<Stack direction="row" spacing={1}>
						<MainMenu />
						{headerLeft}
					</Stack>

					{/* Header title */}
					<Stack direction="row" spacing={1}>

					</Stack>

					{/* Header right */}
					<Stack direction="row" spacing={1} alignItems="center">
						{(isLoading) && <Box sx={{ paddingRight: 1.5 }}><Loader size={25} /></Box>}
						<ThemeSelector />
						<LanguageSwitcher />
						<TitleIconHelp
							help={help ? help.title : t('header.help')}
							url={help ? help.url : ""}
						/>
						{headerRight}
						<AccountMenu />
					</Stack>
				</Toolbar>
			</AppBar>
			{children}
		</Box>
	)
}