// https://regexr.com / https://regex101.com
// eslint-disable-next-line
export const regexpEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
// https://stackoverflow.com/questions/9809357/regex-for-validating-multiple-e-mail-addresses 
// https://stackoverflow.com/a/21456918/197127 - used this eventually

// eslint-disable-next-line
export const regexpEmails = new RegExp(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)
export const regexpName = /^[a-zA-Z ]{1,30}$/
export const regexpTeamName = /^[a-zA-Z0-9\- ]{3,30}$/
export const regexpOrgName = /^[a-zA-Z0-9 .]{3,30}$/
export const regexpChecklistName = /^.{1,500}$/
export const regexpTemplateName = /^.{1,500}$/
export const regexpFolderName = /^[a-zA-Z0-9 ]{2,30}$/
export const regexpTaskName = /^.{1,500}$/
export const regexpFieldKey = /^[a-z0-9\-_]{1,20}$/
export const regexpFieldLabel = /^[a-zA-Z0-9 ]{1,20}$/
export const regexpTextFieldValue = /^.{1,1000}$/
export const regexpLongTextFieldValue = /^(.|\s){0,500}$/
export const regexpNumber = /^[0-9-+.,]{1,20}$/
// eslint-disable-next-line
export const regexpUrl = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9._\+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)$/
export const regexpWebsite = /^https:\/\/[-a-zA-Z0-9._]{2,256}\.[a-z]{2,8}/
export const regexpFacebook = /^[a-z\d.]{5,}$/
export const regexpTwitter = /^@?(\w){1,15}$/
export const regexpHandle = /^[a-zA-Z0-9]{5,25}$/

export const regexpPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$/%@&-])[A-Za-z\d!$/%@&-]{6,64}$/
export const regexpPasswordLength = /^.{6,64}$/
export const regexpIncludesLowercase = /^(?=.*[a-z])/
export const regexpIncludesUppercase = /^(?=.*[A-Z])/
export const regexpIncludesNumber = /^(?=.*[0-9])/
export const regexpIncludesSpecial = /(?=.*[!$/%@&-])/
export const regexpPasswordChars = /^[a-zA-Z0-9!$/%@&-]+$/
export const PASSWORD_REQUIREMENT = "6-64 alphanumeric or !$/%@&- with at least 1 number, 1 lowercase,1 uppercase and 1 special"