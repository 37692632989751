import { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, CssBaseline, Collapse, Button, AppBar, Toolbar, Typography, Avatar } from '@mui/material';
import { Home, FileText, Calendar, CheckSquare, ChevronDown, ChevronUp, Menu, HelpCircle, Moon, Sun, UserPlus, Zap } from 'lucide-react';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router';

interface Props {
    children: React.ReactNode;
}

export default function LayoutPage({ children }: Props) {
    const [open, setOpen] = useState(true);
    const [templatesOpen, setTemplatesOpen] = useState(false);
    const [checklistsOpen, setChecklistsOpen] = useState(true);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const theme = useTheme();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const toggleTemplates = () => {
        setTemplatesOpen(!templatesOpen);
    };

    const toggleChecklists = () => {
        setChecklistsOpen(!checklistsOpen);
    };

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    const checklistNames = [
        "Daily Standup",
        "Weekly Team Meeting",
        "Monthly Project Review",
        "New Employee Onboarding",
        "Software Deployment Checklist",
        "Customer Feedback Survey",
        "Quarterly Financial Audit",
        "Product Launch Preparation",
        "Office Safety Inspection",
        "Annual Performance Review",
        "Website Maintenance Checklist",
        "Client Onboarding Process",
        "Marketing Campaign Rollout",
        "Equipment Maintenance Schedule",
        "Travel Expense Report",
        "Emergency Response Plan",
        "Quality Assurance Testing",
        "New Feature Development Workflow",
        "Customer Support Ticket Resolution",
        "End-of-Year Inventory Count"
    ];

    const menuItems = [
        { text: 'Dashboard', icon: <Home />, path: '/' },
        {
            text: 'Checklists',
            icon: <CheckSquare />,
            path: '/checklists',
            subItems: checklistNames,
            onToggle: toggleChecklists,
            isOpen: checklistsOpen,
        },
        {
            text: 'Templates',
            icon: <FileText />,
            path: '/templates',
            subItems: ['Onboarding Template', 'Project Kickoff Template', 'Meeting Minutes Template'],
            onToggle: toggleTemplates,
            isOpen: templatesOpen,
        },
        { text: 'Schedules', icon: <Calendar />, path: '/schedules' },
    ];

    const drawerWidth = 240;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        edge="start"
                        sx={{ marginRight: 5 }}
                    >
                        <Menu />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        Checklist App
                    </Typography>
                    <IconButton color="inherit" onClick={toggleDarkMode}>
                        {isDarkMode ? <Sun /> : <Moon />}
                    </IconButton>
                    <IconButton color="inherit">
                        <HelpCircle />
                    </IconButton>
                    <Avatar sx={{ marginLeft: 2 }}>U</Avatar>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: open ? drawerWidth : theme.spacing(7),
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: open ? drawerWidth : theme.spacing(7),
                        overflowX: 'hidden',
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    },
                }}
            >
                <Toolbar />
                <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 64px)' }}>
                    <List dense sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                        {menuItems.map((item) => (
                            <Box key={item.text}>
                                <ListItem
                                    component={Link}
                                    to={item.path}
                                    onClick={item.onToggle || undefined}
                                    sx={{ 
                                        justifyContent: open ? 'initial' : 'center', 
                                        px: 1.5, 
                                        py: 0.5
                                    }}
                                >
                                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'center' }}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={item.text} 
                                        sx={{ 
                                            opacity: open ? 1 : 0,
                                            '& .MuiTypography-root': {
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }
                                        }} 
                                    />
                                    {item.subItems && open && (
                                        <IconButton size="small" sx={{ p: 0.5 }}>
                                            {item.isOpen ? <ChevronUp /> : <ChevronDown />}
                                        </IconButton>
                                    )}
                                </ListItem>
                                {item.subItems && open && (
                                    <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding dense>
                                            {item.text === 'Checklists' && (
                                                <ListItem sx={{ pl: 4, py: 0.5 }}>
                                                    <Button
                                                        component={Link}
                                                        to="/checklists"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                    >
                                                        View All Checklists
                                                    </Button>
                                                </ListItem>
                                            )}
                                            {item.subItems.map((subItem) => (
                                                <ListItem key={subItem} sx={{ pl: 4, py: 0.25 }}>
                                                    <ListItemText 
                                                        primary={subItem} 
                                                        sx={{
                                                            '& .MuiTypography-root': {
                                                                fontSize: '0.875rem',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'
                                                            }
                                                        }}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                )}
                            </Box>
                        ))}
                    </List>
                    {open && (
                        <Box sx={{ p: 1, borderTop: 1, borderColor: 'divider' }}>
                            <Button
                                startIcon={<UserPlus />}
                                variant="outlined"
                                fullWidth
                                sx={{ mb: 1 }}
                                size="small"
                            >
                                Invite
                            </Button>
                            <Button
                                startIcon={<Zap />}
                                variant="contained"
                                fullWidth
                                size="small"
                            >
                                Upgrade
                            </Button>
                        </Box>
                    )}
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                {children}
            </Box>
        </Box>
    );
}