import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiTemplate } from '../api/templateApiClient';
import { Direction, TemplateRes } from '../interfaces/api';
import { IMeta, ITask } from '../interfaces/IChecklist';
import { generateCID } from '../utils/ids';
import { UseMutationResult } from '@tanstack/react-query';
import { moveTask, upsertTaskField } from '../utils/checklist.utils';
import { produce } from 'immer';
import { useCurrentTeam } from '../contexts/CurrentTeamContext';

export interface ITemplateMutations {
    update: UseMutationResult<any, unknown, { name: string; value: string }, unknown>;
    updateField: UseMutationResult<any, unknown, { path?: string; field: string; value?: string }, unknown>;
    addVisibility: UseMutationResult<any, unknown, string, unknown>;
    deleteVisibility: UseMutationResult<any, unknown, string, unknown>;
    moveTask: UseMutationResult<any, unknown, { path: string; toPath: string; position: number }, unknown>;
    updateTaskMeta: UseMutationResult<any, unknown, { path: string; key: string; meta: IMeta }, unknown>;
    addTasks: UseMutationResult<any, unknown, { position: number; tasks: ITask[], absolutePosition: number }, unknown>;
    delete: UseMutationResult<any, unknown, string, unknown>;
}

// TODO: once implementing the templates page, we need to also invalidate when changing template name, delete and folder. Also update onMutate for them

export function useTemplates(team?: string) {
    const { team: currentTeam } = useCurrentTeam();
    const teamId = team ?? currentTeam?.id;
    return useQuery({
        queryKey: ['templates', teamId],
        queryFn: async () => {
            const response = await apiTemplate.getTemplates(teamId ?? null);
            if (response.error) throw response.error;
            return response.data;
        },
        enabled: !!teamId,
        staleTime: 1000 * 60 * 1, // 1 minutes
    });
}

export function useTemplate(id: string | undefined) {
    const queryClient = useQueryClient();
    const { team } = useCurrentTeam();
    const queryKey = ['template', id];

    // Define basePath for template operations
    const basePath = id ? `/${id}` : '';

    const {
        data: template,
        isLoading,
        isError,
        error
    } = useQuery({
        queryKey,
        queryFn: async () => {
            const response = await apiTemplate.getTemplate(id!, team!.org, team!.id);
            if (response.error) throw response.error;
            return response.data;
        },
        enabled: !!team && !!id,
        // staleTime: 1000 * 60 * 1, // 1 minutes
    });

    const mutations: ITemplateMutations = {
        update: useMutation({
            mutationFn: async ({ name, value, }) => {
                const response = await apiTemplate.updateTemplate(
                    id!,
                    generateCID(),
                    name,
                    value
                );
                if (response.error) throw response.error;
                return response.data;
            },
            onMutate: async ({ name, value }) => {
                await queryClient.cancelQueries({ queryKey });
                const previousData = queryClient.getQueryData(queryKey);
                queryClient.setQueryData(queryKey, (oldData: TemplateRes) => {
                    if (!oldData) throw new Error('No template data found');
                    return produce(oldData, draft => {
                        switch (name) {
                            case 'propagate.up':
                                draft.propagate.up = value === "true";
                                break;
                            case 'propagate.down':
                                draft.propagate.down = value === "true";
                                break;
                            case 'view':
                                draft.view = value;
                                break;
                            default:
                                throw new Error(`Unknown name: ${name}`);
                        }
                    });
                });
                return { previousData };
            }
        }),

        updateField: useMutation({
            mutationFn: async ({ path = basePath, field, value }) => {
                const response = await apiTemplate.updateFieldTemplate(
                    id!,
                    generateCID(),
                    path,
                    field,
                    value
                );
                if (response.error) throw response.error;
                return response.data;
            },
            onMutate: async ({ path, field, value }) => {
                await queryClient.cancelQueries({ queryKey });
                const previousData = queryClient.getQueryData(queryKey);
                queryClient.setQueryData(queryKey, (oldData: TemplateRes) => {
                    if (!oldData) throw new Error('No template data found');
                    return produce(oldData, draft => {
                        if (!path || path === basePath) {
                            // we are updating the template itself
                            switch (field) {
                                case 'name':
                                    if (value) draft.name = value;
                                    break;
                                case 'notes':
                                    draft.notes = value;
                                    break;
                                case 'issues':
                                    if (value) {
                                        if (!draft.fields) draft.fields = [];
                                        const index = draft.fields.findIndex(f => f.key === field);
                                        if (index === -1) {
                                            draft.fields.push({ key: field, value });
                                        } else {
                                            draft.fields[index].value = value;
                                        }
                                    } else {
                                        draft.fields = (draft.fields || []).filter(f => f.key !== field);
                                    }
                                    break;
                                default:
                                    throw new Error(`Unknown field: ${field}`);
                            }
                        } else {
                            // we are updating a task field
                            const task = draft.tasks.find(task => task.path === path);
                            if (!task) throw new Error('No task found');
                            switch (field) {
                                case 'name':
                                    if (!value) throw new Error('Name is required');
                                    task.name = value;
                                    break;
                                case 'notes':
                                    task.notes = value === "" ? undefined : value;
                                    break;
                                default:
                                    task.fields = upsertTaskField(task, field, value).fields;
                            }
                        }
                    });
                });
                return { previousData };
            }
        }),

        addVisibility: useMutation({
            mutationFn: async (visibility: string) => { // visibility is team id
                const response = await apiTemplate.addVisibilityTemplate(
                    id!,
                    generateCID(),
                    visibility
                );
                if (response.error) throw response.error;
                return response.data;
            },
            onMutate: async (visibility: string) => {
                await queryClient.cancelQueries({ queryKey });
                const previousData = queryClient.getQueryData(queryKey);
                queryClient.setQueryData(queryKey, (oldData: TemplateRes) => {
                    if (!oldData) throw new Error('No template data found');
                    return produce(oldData, draft => {
                        draft.visibility = draft.visibility || [];
                        if (!draft.visibility.includes(visibility)) {
                            draft.visibility.push(visibility);
                        }
                    });
                });
                return { previousData };
            }
        }),

        deleteVisibility: useMutation({
            mutationFn: async (visibility: string) => { // visibility is team id
                const response = await apiTemplate.deleteVisibilityTemplate(
                    id!,
                    generateCID(),
                    visibility
                );
                if (response.error) throw response.error;
                return response.data;
            },
            onMutate: async (visibility: string) => {
                await queryClient.cancelQueries({ queryKey });
                const previousData = queryClient.getQueryData(queryKey);
                queryClient.setQueryData(queryKey, (oldData: TemplateRes) => {
                    if (!oldData) throw new Error('No template data found');
                    return produce(oldData, draft => {
                        draft.visibility = draft.visibility ? draft.visibility.filter(x => x !== visibility) : [];
                    });
                });
                return { previousData };
            }
        }),

        moveTask: useMutation({
            mutationFn: async ({ path, toPath, position }) => {
                const response = await apiTemplate.moveTaskTemplate(
                    id!,
                    generateCID(),
                    path,
                    toPath,
                    position,
                );
                if (response.error) throw response.error;
                return response.data;
            },
            onMutate: async ({ path, toPath, position }) => {
                await queryClient.cancelQueries({ queryKey });
                const previousData = queryClient.getQueryData(queryKey);
                queryClient.setQueryData(queryKey, (oldData: TemplateRes) => {
                    if (!oldData) throw new Error('No template data found');
                    return produce(oldData, draft => {
                        draft.tasks = moveTask(oldData.tasks, path, toPath, position);
                    });
                });
                return { previousData };
            }
        }),

        updateTaskMeta: useMutation({
            mutationFn: async ({ path, key, meta, }) => {
                await queryClient.cancelQueries({ queryKey });
                const response = await apiTemplate.updateTaskMetaTemplate(
                    id!,
                    generateCID(),
                    path,
                    key,
                    meta
                );
                if (response.error) throw response.error;
                return response.data;
            },
            onMutate: async ({ path, key, meta }) => {
                const previousData = queryClient.getQueryData(queryKey);
                queryClient.setQueryData(queryKey, (oldData: TemplateRes) => {
                    if (!oldData) throw new Error('No template data found');
                    return produce(oldData, draft => {
                        const task = draft.tasks.find(task => task.path === path);
                        if (!task) throw new Error('No task found');
                        if (!task.meta) task.meta = [];
                        const existingMetaIndex = task.meta.findIndex(m => m.key === key);
                        if (existingMetaIndex >= 0) {
                            task.meta[existingMetaIndex] = meta;
                        } else {
                            task.meta.push(meta);
                        }
                    });
                });
                return { previousData };
            }
        }),

        addTasks: useMutation({
            mutationFn: async ({ position, tasks /*,absolutePosition*/ }) => {
                const response = await apiTemplate.addTasksTemplate(
                    id!,
                    generateCID(),
                    position,
                    tasks,
                );
                if (response.error) throw response.error;
                return response.data;
            },
            onMutate: async ({/*position,*/ tasks, absolutePosition }) => {
                await queryClient.cancelQueries({ queryKey });
                const previousData = queryClient.getQueryData(queryKey);
                queryClient.setQueryData(queryKey, (oldData: TemplateRes) => {
                    if (!oldData) throw new Error('No template data found');
                    if (tasks.length === 0) throw new Error('No tasks provided');
                    return produce(oldData, draft => {
                        draft.tasks.splice(absolutePosition, 0, ...tasks);
                    });
                });
                return { previousData };
            }
        }),

        delete: useMutation({
            mutationFn: async (path: string) => {
                const response = await apiTemplate.deleteTemplate(id!, generateCID(), path);
                if (response.error) throw response.error;
                return response.data;
            },
            onMutate: async (path: string) => {
                await queryClient.cancelQueries({ queryKey });
                const previousData = queryClient.getQueryData(queryKey);
                queryClient.setQueryData(queryKey, (oldData: TemplateRes) => {
                    if (!oldData) throw new Error('No template data found');
                    if (path !== oldData.path) {
                        return produce(oldData, draft => {
                            draft.tasks = draft.tasks.filter(t => !t.path.startsWith(path));
                        });
                        return oldData;
                    }
                });
                return { previousData };
            },
            // TODO2: we could also just remove it from the cache
            onSettled: async () => { queryClient.invalidateQueries({ queryKey: ['templates', template!.owner.team] }); }
        }),
    };

    const isPending = Object.values(mutations).some(
        mutation => mutation.isPending
    );

    return {
        template,
        isLoading,
        isError,
        error,
        mutations,
        isPending,
        basePath, // Expose basePath in case it's needed externally
    };
}

export function useSearchTemplates(params: {
    text?: string;
    deleted?: boolean;
    direction?: Direction;
    page?: number;
    size?: number;
    sort?: string;
    tags?: string[];
}) {
    const { team } = useCurrentTeam();

    return useQuery({
        queryKey: ['templates', 'search', team?.id, params],
        queryFn: async () => {
            const response = await apiTemplate.searchTemplates(
                'en', // assuming default language
                team!.org,
                team!.id,
                params.text,
                params.deleted,
                params.direction,
                params.page,
                params.size,
                params.sort,
                params.tags
            );
            if (response.error) throw response.error;
            return response.data;
        },
        enabled: !!team,
    });
}

export function useCreateTemplate() {
    const queryClient = useQueryClient();
    const { team } = useCurrentTeam();

    return useMutation({
        mutationFn: async ({
            id,
            name,
            lang = 'en',
        }: {
            id: string;
            name: string;
            lang?: string;
        }) => {
            const response = await apiTemplate.createTemplate(
                id,
                generateCID(),
                lang,
                name,
                team!.id
            );
            if (response.error) throw response.error;
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['templates'] });
        },
    });
}

export function useDuplicateTemplate() {
    const queryClient = useQueryClient();
    const { team } = useCurrentTeam();

    return useMutation({
        mutationFn: async ({
            template,
            id,
            name,
            view,
            inclRecipes,
            folderId,
        }: {
            template: string;
            id: string;
            name: string;
            view: string;
            inclRecipes: boolean;
            folderId?: string;
        }) => {
            const response = await apiTemplate.duplicateTemplate(
                id,
                generateCID(),
                template,
                team!.id,
                name,
                inclRecipes,
                view,
                folderId,
            );
            if (response.error) throw response.error;
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['templates'] });
        },
    });
}

export function useTemplateMoveFolder(team?: string) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            id,
            folder,
            position,
        }: {
            id: string; // template id
            folder: string;
            position: number;
        }) => {
            const response = await apiTemplate.moveTemplate(
                id,
                generateCID(),
                folder,
                position
            );
            if (response.error) throw response.error;
            return response.data;
        },
        onSettled: async () => {
            queryClient.invalidateQueries({ queryKey: ['templates', team] });
        }
    });
}