import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiChecklist } from "../api/checklistApiClient";
import { generateCID } from "../utils/ids";

export function useChecklist(team: string) {
    return useQuery({
        queryKey: ['checklists', team],
        queryFn: async () => {
            const response = await apiChecklist.getChecklists(team!);
            if (response.error) throw response.error;
            return response.data;
        },
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
}

export function useCreateChecklist() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({
            id,
            name,
            team,
            assign,
            owners,
            type,
        }: {
            id: string;
            name: string;
            team: string;
            assign?: string[],
            owners?: string[],
            type?: string,
        }) => {
            const response = await apiChecklist.createChecklist(
                standardTemplates.simple.id, // template id
                generateCID(),
                id,
                name,
                team,
                assign,
                owners,
                type,
            );
            if (response.error) throw response.error;
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['checklists'] });
        },
    });
}

export const standardTemplates = {
    "simple": {
        id: "tl_6ac0b50a-5edc-3065-bcf9-7e4b1c1e3b65",
        key: "simple",
        label: "Simple Checklist",
    },
    "dueDates": {
        id: "tl_d5f3447f-ed25-37c1-916c-afa7bd7ee7ca",
        key: "dueDates",
        label: "Checklist with Due dates",
    },
    "shareable": {
        id: "tl_d37d422c-3fbb-35b2-ae19-1d29b3f6ecfb",
        key: "shareable",
        label: "Shareable Checklist",
    },
    "shareableDue": { // we use this for template creation
        id: "tl_c3a1aa3e-6877-38d8-a8cc-4a61bb59475c",
        key: "shareableDue",
        label: "Shareble with due Dates Checklist",
    },
    // the following needs to be fixed as it will not allow to create it
    "tableView": {
        id: "tl_6ac0b50a-5edc-3065-bcf9-7e4b1c1e3b66",
        key: "tableView",
        labeel: "Table View",
    }
}