import { MouseEvent, FocusEvent } from 'react'
import { useTranslation } from 'react-i18next'; import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { Box, IconButton, Paper, Tooltip } from "@mui/material";
import { GripVertical, ChevronDown, ChevronRight, Trash, ChevronLeft } from "lucide-react";
import { DEFAULT_ANSWER_META, DEFAULT_CHOICE_OPTIONS, IMeta, ITask, TaskTypes } from "../interfaces/IChecklist";
import { getMeta, getPathLength } from "../utils/checklist.utils";
import EditableText from "../components/Input/EditableText";
import { TaskTypeSelector } from "./TaskTypeSelector";
import { ITemplateMutations } from "../hooks/useTemplate";
import { useMutationErrorHandler } from "../hooks/useErrorHandler";
import { stringToTaskType } from "../utils/taskTypes.utils";
import { useMixpanel } from "../utils/mixpanel";

interface Props {
	task: ITask;
	isCursor: boolean;
	isExpanded: boolean;
	showNotes: boolean;
	hasSubtasks: boolean;
	disableTypes: boolean;
	hasChildren: boolean;
	canSubtask: boolean;
	onClick: () => void;
	onToggleSubtasks: () => void;
	onCursorNext: () => void;
	handleSubtask: () => void;
	handleUnsubtask: () => void;
	mutations: ITemplateMutations;
}

export default function TemplateTask({ task, hasChildren, isCursor, isExpanded, disableTypes, hasSubtasks, showNotes, canSubtask, onClick, onCursorNext, onToggleSubtasks, handleSubtask, handleUnsubtask, mutations }: Props) {
	const { t } = useTranslation();
	const handleError = useMutationErrorHandler();
	const mixpanel = useMixpanel();

	const { attributes, listeners, setNodeRef, isDragging, transform, transition } = useSortable({
		id: task.id,
	});

	const level = getPathLength(task.path) - 2;
	const meta: IMeta = getMeta(task, "answer") || DEFAULT_ANSWER_META
	const currentType = meta?.type || TaskTypes.Check

	const handleClick = (event: MouseEvent | FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		event.stopPropagation();
		if (!isCursor) {
			// we are not selected, so let's select us
			onClick();
		} else {
			// we are selected: only propagate it if came from the paper itself (and not sub) - i.e. has class editor-template-task
			if ((event.target as HTMLElement).classList?.contains('editor-template-task')) {
				onClick();
			}
		}
	}

	const handleToggleSubtasks = (e: React.MouseEvent) => {
		e.stopPropagation();
		onToggleSubtasks();
	}

	const handleDelete = (e: React.MouseEvent) => {
		e.stopPropagation();
		onCursorNext();
		mutations.delete.mutate(task.path, handleError('Failed to delete task.'));
		mixpanel.track('template', {
			action: 'delete-task',
			template: task.path.split('/')[1],
			task: task.id,
		})
	}

	const handleChangeType = (value: string) => {
		if (value !== currentType) {
			mixpanel.track('template', {
				action: 'change-task-type',
				template: task.path.split('/')[1],
				task: task.id,
				type: value,
				old: currentType,
			})
			let updated: IMeta = {
				...meta,
				type: stringToTaskType(value)!,
				options: value === TaskTypes.Choice ? JSON.stringify(DEFAULT_CHOICE_OPTIONS) : undefined,
			}
			mutations.updateTaskMeta.mutate({
				path: task.path,
				key: meta.key,
				meta: updated,
			},
				handleError('Failed to update task type.'))
		}
	}

	const handleNameChange = (value: string) => {
		if (value !== task.name) {
			mutations.updateField.mutate({
				path: task.path,
				field: 'name',
				value,
			},
				handleError('Failed to update task name.'))
			mixpanel.track('template', {
				action: 'update-task-name',
				template: task.path.split('/')[1],
				task: task.id,
				name: value,
			})
		}
	}

	const handleNotesChange = (value: string) => {
		if (value !== task.notes) {
			mutations.updateField.mutate({
				field: 'notes',
				value,
				path: task.path
			},
				handleError('Failed to update notes'));
			mixpanel.track('template', {
				action: 'update-task-notes',
				template: task.path.split('/')[1],
				task: task.id,
			})
		}
	}

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<Paper
			ref={setNodeRef}
			data-cy="template-task"
			data-selected={isCursor ? "true" : "false"}
			data-level={level}
			style={style}
			aria-label={t('templateTask.dragTask')}
			sx={{
				my: 1,
				p: 1,
				cursor: "pointer",
				bgcolor: isCursor ? "action.selected" : "background.paper",
				opacity: isDragging ? 0 : 1,
				"&:hover": {
					"& .action-buttons": {
						opacity: 1,
					},
				},
				position: 'relative',
				zIndex: isDragging ? 1 : 'auto',
				borderLeft: meta.required ? "5px solid red" : undefined,
				marginLeft: `${level * 50 + (meta.required ? -5 : 0)}px`,
			}}
			className="editor-template-task"
			onClick={handleClick}
		>
			<Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
				<IconButton
					{...attributes}
					{...listeners}
					size="small"
					sx={{ cursor: "grab", mt: 0.5 }}
					tabIndex={-1}
					data-cy="task-dragger"
				// id={`task-dragger-${task.id}`} // we do not use for now.
				>
					<GripVertical size={20} />
				</IconButton>
				{hasChildren ?
					<IconButton
						data-cy="task-expander"
						data-state={isExpanded ? "expanded" : "collapsed"}
						tabIndex={-1}
						size="small"
						onClick={handleToggleSubtasks}
						sx={{ mt: 0.5 }}
						aria-label={isExpanded ? t('templateTask.collapseSubtasks') : t('templateTask.expandSubtasks')}
					>{isExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}</IconButton>
					:
					<Box sx={{ width: hasSubtasks ? 30 : 0 }} />
				}
				<Box sx={{ flex: 1 }}>
					<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
						{!disableTypes && <TaskTypeSelector type={currentType} onTypeChange={handleChangeType} />}
						<EditableText
							allowEmpty={false}
							variant="body1"
							value={task.name}
							onChange={handleNameChange}
							onFocus={onClick}
							aria-label={t('templateTask.editTaskName')}
							dataCy="task-item-name"
						/>
						<Box
							className="action-buttons" // defined above
							sx={{
								ml: "auto",
								opacity: 0,
								transition: "opacity 0.2s",
								display: "flex",
								gap: 1,
							}}
						>
							{level > 0 &&
								<Tooltip title={t('templateEditor.unsubtask')}>
									<IconButton
										tabIndex={-1}
										data-cy="task-unsubtask-button"
										onClick={handleUnsubtask}
										size="small"
										aria-label="Unsubtask"
									>
										<ChevronLeft size={20} />
									</IconButton>
								</Tooltip>}
							{canSubtask && <Tooltip title={t('templateEditor.subtask')}>
								<IconButton
									tabIndex={-1}
									data-cy="task-subtask-button"
									onClick={handleSubtask}
									size="small"
									sx={{ ml: 'auto' }}
									aria-label="Make subtask"
								>
									<ChevronRight size={20} />
								</IconButton>
							</Tooltip>}
							<IconButton
								data-cy="task-delete-button"
								tabIndex={-1}
								size="small"
								onClick={handleDelete}
								aria-label={t('templateTask.deleteTask')}
							>
								<Trash size={20} />
							</IconButton>
						</Box>
					</Box>
					{showNotes && !isDragging && task.notes && (
						<EditableText
							dataCy='template-task-notes'
							multiline
							allowEmpty
							maxRows={3}
							variant="body2"
							value={task.notes}
							onChange={handleNotesChange}
							onFocus={handleClick}
							aria-label={t('templateTask.editTaskNotes')}
						/>
					)}
				</Box>
			</Box>
		</Paper >
	);
}