import { createContext, useContext } from 'react';
import mixpanel from 'mixpanel-browser';
import { DEV_ENV } from './const';

const MIXPANEL_TOKEN = "11107954b5fb22718993bf92674094ca";

// Define the Mixpanel service interface
interface IMixpanelService {
	identify: (id: string) => void;
	track: (name: string, props?: any) => void;
	people: {
		set: (props: any) => void;
	};
}

// Mock service for development
const MockMixpanelService: IMixpanelService = {
	identify: (_id: string) => { },
	track: (_name: string, _props?: any) => { },
	people: {
		set: (_props: any) => { }
	}
};

// Initialize real service only in production
const RealMixpanelService: IMixpanelService = {
	identify: (id: string) => {
		mixpanel.identify(id);
	},
	track: (name: string, props?: any) => {
		mixpanel.track(name, props);
	},
	people: {
		set: (props: any) => {
			mixpanel.people.set(props);
		},
	},
};

if (!DEV_ENV) {
	mixpanel.init(MIXPANEL_TOKEN, {
		cross_subdomain_cookie: true,
		ignore_dnt: true,
		track_pageview: "full-url",
		api_host: 'https://checklist.com/api/t'
	});

	if (!mixpanel.has_opted_in_tracking() && !mixpanel.has_opted_out_tracking()) {
		mixpanel.opt_in_tracking();
	}

	mixpanel.register({
		"app": "web-app",
		"$app_version_string": `v${2}.${0}`
	});
}

// Use mock or real service based on environment
const MixpanelService = DEV_ENV ? MockMixpanelService : RealMixpanelService;

// Create context
const MixpanelContext = createContext<IMixpanelService>(MixpanelService);

// Create provider component
export const MixpanelProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<MixpanelContext.Provider value={MixpanelService}>
			{children}
		</MixpanelContext.Provider>
	);
};

// Create hook
export const useMixpanel = () => useContext(MixpanelContext);